import default_profile from '../assets/images/explore/default-pic.jpg';
import canada from '../assets/images/flags/canada.svg';
import india from '../assets/images/flags/india.svg';
import italy from '../assets/images/flags/italy.svg';
import romania from '../assets/images/flags/romania.svg';
import uk from '../assets/images/flags/uk.svg';
import usa from '../assets/images/flags/usa.svg';
import meta_logo from '../assets/images/home/meta.png';
import access_price from '../assets/images/icons/access-price-tag.svg';
import bx_book_content from '../assets/images/icons/bx_book-content.svg';
import cross_icon from '../assets/images/icons/cross.svg';
import divider_135 from '../assets/images/icons/divider-line.svg';
import downtrend from '../assets/images/icons/down-trend.svg';
import elite_price from '../assets/images/icons/elite-price-tag.svg';
import email_icon from '../assets/images/icons/email.svg';
import fill_circle_x from '../assets/images/icons/fill-circle-x.svg';
import filter_icon from '../assets/images/icons/filter.svg';
import greater_than from '../assets/images/icons/greater-than.svg';
import green_tick_circle from '../assets/images/icons/green-fill-tick.svg';
import infinity_price from '../assets/images/icons/inifinity-price-tag.svg';
import less_than from '../assets/images/icons/less-than.svg';
import file_more_line from '../assets/images/icons/mingcute_file-more-line.svg';
import circle_plus from '../assets/images/icons/plus-with-circle.svg';
import price_tick from '../assets/images/icons/price-tick.svg';
import search_icon from '../assets/images/icons/search-menu-icon.svg';
import sorting_icon from '../assets/images/icons/sorting_icon.svg';
import star_group_icon from '../assets/images/icons/stars-group.svg';
import stats_icon from '../assets/images/icons/stats-icon.svg';
import table_icon from '../assets/images/icons/table-more.svg';
import three_dots from '../assets/images/icons/three-dots.svg';
import twitch from '../assets/images/icons/twitch.png';
import uptrend from '../assets/images/icons/up-trend.svg';
import view_circle from '../assets/images/icons/view-circle.svg';
import bell_icon from '../assets/images/notification-icon.svg';
import behance from '../assets/images/social/social-be.svg';
import facebook from '../assets/images/social/social-fb.svg';
import instagram from '../assets/images/social/social-insta.svg';
import linkedin from '../assets/images/social/social-linkedin.svg';
import pinterest from '../assets/images/social/social-pinterest.svg';
import snapchat from '../assets/images/social/social-snapchat.svg';
import thread from '../assets/images/social/social-thread.svg';
import tiktok from '../assets/images/social/social-tiktok.svg';
import x_icon from '../assets/images/social/social-x.svg';
import youtube from '../assets/images/social/social-yt.svg';

export const ICON_ENUM = {
  FACEBOOK: {
    icon: facebook,
    borderColor: 'linear-gradient(45deg, #1C36B7 8.66%, #1C98D3 90.78%)',
  },
  YOUTUBE: {
    icon: youtube,
    borderColor: 'linear-gradient(135deg, #FF0000 14.6%, #9B0000 85.41%)',
  },
  INSTAGRAM: {
    icon: instagram,
    borderColor:
      'linear-gradient(135.29deg, #5342D6 13.8%, #7739C6 18.4%, #A52DB0 25.1%, #B729A8 28.46%, #CE257E 37.01%, #E82250 47.52%, #F2203E 52.8%, #F2203E 65.79%, #F32D40 67.35%, #F86C48 75.25%, #FB994E 81.93%, #FDB652 87.06%, #FEC053 90.03%)',
  },
  X: {
    icon: x_icon,
    borderColor: '#FFFFFF',
  },
  LINKEDIN: {
    icon: linkedin,
    borderColor: 'linear-gradient(90deg, #007BB8 0.12%, #0044E9 99.98%)',
  },
  TIKTOK: {
    icon: tiktok,
    borderColor:
      'linear-gradient(135deg, #5B5760 14.55%, #020003 60.22%, #000000 85.35%)',
  },
  TWITCH: {
    icon: twitch,
    borderColor:
      'linear-gradient(161.27deg, #A436D2 -182.61%, #9146FF 280.18%)',
  },
  BEHANCE: {
    icon: behance,
    borderColor:
      'linear-gradient(135deg, #006EF8 14.6%, #212CB1 69.49%, #2B189C 85.41%)',
  },
  PINTEREST: {
    icon: pinterest,
    borderColor: '',
  },
  SNAPCHAT: {
    icon: snapchat,
    borderColor: '',
  },
  THREAD: {
    icon: thread,
    borderColor: '',
  },
  UP_TREND: {
    icon: uptrend,
  },
  DOWN_TREND: {
    icon: downtrend,
  },
  PLUS_WITH_CIRCLE: {
    icon: circle_plus,
  },
  THREE_DOTS: {
    icon: three_dots,
  },
  FILE_MORE_LINE: {
    icon: file_more_line,
  },
  BX_BOOK: {
    icon: bx_book_content,
  },
  VIEW_CIRCLE: {
    icon: view_circle,
  },
  LESS_THAN: {
    icon: less_than,
  },
  GREATER_THAN: {
    icon: greater_than,
  },
  USA: {
    icon: usa,
  },
  UK: {
    icon: uk,
  },
  CANADA: {
    icon: canada,
  },
  ROMANIA: {
    icon: romania,
  },
  ITALY: {
    icon: italy,
  },
  INDIA: {
    icon: india,
  },
  META_LOGO: {
    icon: meta_logo,
  },
  STATS: {
    icon: stats_icon,
  },
  TABLE: {
    icon: table_icon,
  },
  DIVIDER_135: {
    icon: divider_135,
  },
  GREEN_TICK_CIRCLE: {
    icon: green_tick_circle,
  },
  SORTING: {
    icon: sorting_icon,
  },
  STAR_GROUP: {
    icon: star_group_icon,
  },
  EMAIL: {
    icon: email_icon,
  },
  BLUE_TICK: {
    icon: price_tick,
  },
  ELITE_PRICE: {
    icon: elite_price,
  },
  ACCESS_PRICE: {
    icon: access_price,
  },
  INFINITY_PRICE: {
    icon: infinity_price,
  },
  PROFILE_DEFAULT: {
    icon: default_profile,
  },
  NOTIFICATION: {
    icon: bell_icon,
  },
  SEARCH: {
    icon: search_icon,
  },
  CROSS: {
    icon: cross_icon,
  },
  FILTER: {
    icon: filter_icon,
  },
  FILL_CIRCLE_X: {
    icon: fill_circle_x,
  },
};
