import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

import SocialTile from '../../../../components/common/SocialTile';
import '../SocialAnalytics.scss';

interface TilesAndGraphStatsProps {
    title?: string;
    data?: any;
    gridConfig?: any;
}

const TilesAndGraphStats = ({
    title = '',
    data = {},
    gridConfig = {},
}: TilesAndGraphStatsProps) => {
    return (
        <div className="stats-section-bg p-4 card-bg-dev rounded-2xl space-y-3">
            <span className="text-lg font-bold">{title ?? ''}</span>
            <div
                className={`grid grid-cols-${gridConfig?.sm ?? '1'} md:grid-cols-${gridConfig?.md ?? '2'
                    } lg:grid-cols-${gridConfig?.lg ?? '4'} gap-4 p-5`}
            >
                {data?.topTiles &&
                    data?.topTiles?.map((tile: any) => <SocialTile data={tile} />)}
            </div>
            {data?.lineChartDataSet && (
                <div className="followers-graph mt-6">
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data?.lineChartDataSet?.dataSet ?? []}>
                            {data?.lineChartDataSet?.configuration?.gradientStroke &&
                                Object.keys(
                                    data?.lineChartDataSet?.configuration?.gradientStroke,
                                )?.length !== 0 && (
                                    <defs>
                                        {Object.keys(
                                            data?.lineChartDataSet?.configuration?.gradientStroke,
                                        )?.map((key: string, id: number) => (
                                            <linearGradient
                                                key={id + '_' + key}
                                                id={key}
                                                x1="0"
                                                y1="0"
                                                x2="1"
                                                y2="1"
                                            >
                                                {data?.lineChartDataSet?.configuration?.gradientStroke[
                                                    key
                                                ]?.map((config: any, idx: number) => (
                                                    <stop
                                                        key={idx}
                                                        offset={config?.offset ?? '0%'}
                                                        stopColor={config?.stopColor ?? '#ffff'}
                                                    />
                                                ))}
                                            </linearGradient>
                                        ))}
                                    </defs>
                                )}
                            <CartesianGrid
                                horizontal={true}
                                vertical={false}
                                stroke="#ccc"
                                strokeDasharray="none"
                            />
                            <XAxis dataKey="date" />

                            <YAxis
                                domain={[0, 5000]}
                                ticks={[1000, 2000, 3000, 4000, 5000]}
                                axisLine={false}
                                tickLine={false}
                            />
                            {data?.lineChartDataSet?.configuration?.lines &&
                                data?.lineChartDataSet?.configuration?.lines?.map(
                                    (line: any, index: number) => (
                                        <Line
                                            key={index + '_' + line?.dataKey}
                                            type="monotone"
                                            dataKey={line?.dataKey ?? ''}
                                            stroke={line?.stroke ?? '#ffff'}
                                            strokeWidth={4}
                                            dot={false}
                                        />
                                    ),
                                )}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            )}
            <div
                className={`grid grid-cols-${gridConfig?.sm ?? '1'} md:grid-cols-${gridConfig?.md ?? '2'
                    } lg:grid-cols-${gridConfig?.lg ?? '4'} gap-4 p-5`}
            >
                {data?.bottomTiles &&
                    data?.bottomTiles?.map((tile: any) => <SocialTile data={tile} />)}
            </div>
        </div>
    );
};

export default TilesAndGraphStats;
