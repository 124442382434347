import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import threeDots from '../../assets/images/threeDots.png';
import { getCookies } from '../../utils/utils';
import '../layout/layout.scss';

interface AppData {
  fullName: string;
  email: string;
  joinedOn: string;
  role: string;
}

interface AppDataTableProps {
  data: AppData[];
}

const MembersTable = () => {
  const navigate = useNavigate();
  const [appData, setAppData] = useState<AppData[]>([]);
  console.log("🚀 ~ MembersTable ~ appData:", appData)

  const getMembersData = () => {
    const token = getCookies('authToken');
    axios
      .get('https://api.lusso.dev/api/v1/users?size=1000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setAppData(response?.data?.users);
      })
      .catch(() => {
      });
  };
  useEffect(() => {
    getMembersData();
  }, [])

  const AppDataTable: React.FC<AppDataTableProps> = ({ data }) => {
    console.log("🚀 ~ MembersTable ~ data:", data)
    const userData = [
      {
        email: "ravipati@gmail.com",
        fullName: "Yesh",
        joinedOn: "12-09-2023",
        role: "Admin",
        userId: "43fe5dac687b92846d1f548daabdd4911f52fa66201e0797f38addf83d99d57a"
      },
      {
        email: "jeff@gmail.com",
        fullName: "Jeff",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "d3d1fa8a72e322cc7e40d78deb8790c0b065e12cd5f709451e333dcfdb81b589"
      },

      {
        email: "emma@gmail.com",
        fullName: "Emma Watson",
        joinedOn: "12-09-2023",
        role: "Editor",
        userId: "d44e5eae6af8d22390e057ef648573d7e13417e4cc99fb20d6ebf048d2446ef3"
      },
      {
        email: "sarah@gmail.com",
        fullName: "Sarah Johnson",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "f8d3f892b1d7cb5bfcf2a91251b5ecf029b8a8c9aa5fe6e5ac87296342e5e447"
      },
      {
        email: "michael@gmail.com",
        fullName: "Michael Brown",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "c8eab7bcab0c5b98b6cc84b942b8f5d2c0a4543dbe2f1cb9b1ffcb14567cabb8"
      },
      {
        email: "ravipati@gmail.com",
        fullName: "Yesh",
        joinedOn: "12-09-2023",
        role: "Admin",
        userId: "43fe5dac687b92846d1f548daabdd4911f52fa66201e0797f38addf83d99d57a"
      },
      {
        email: "jeff@gmail.com",
        fullName: "Jeff",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "d3d1fa8a72e322cc7e40d78deb8790c0b065e12cd5f709451e333dcfdb81b589"
      },

      {
        email: "emma@gmail.com",
        fullName: "Emma Watson",
        joinedOn: "12-09-2023",
        role: "Editor",
        userId: "d44e5eae6af8d22390e057ef648573d7e13417e4cc99fb20d6ebf048d2446ef3"
      },
      {
        email: "sarah@gmail.com",
        fullName: "Sarah Johnson",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "f8d3f892b1d7cb5bfcf2a91251b5ecf029b8a8c9aa5fe6e5ac87296342e5e447"
      },
      {
        email: "michael@gmail.com",
        fullName: "Michael Brown",
        joinedOn: "12-09-2023",
        role: "Viewer",
        userId: "c8eab7bcab0c5b98b6cc84b942b8f5d2c0a4543dbe2f1cb9b1ffcb14567cabb8"
      }
    ];

    const navigate = useNavigate();

    return (
      <div className='data-table-wrapper custom-scrollbar'>
        <table>
          <thead className="header-row">
            <tr>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Full Name</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Email Address</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Joined</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Permissions</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Actions</th>
              {/* <th className="text-center">
                <img
                  src={Action} // Update with your actual path
                  alt='Action'
                  className='w-24 h-24 mx-auto' // Adjusted width and height
                />
              </th> */}
            </tr>
          </thead>

          <tbody className='bg-gradient-to-r from-[#2e246c] to-[#271f57]'>
            {userData.map((app, index) => (
              <tr
                onClick={() => { navigate('/dev/dashboard/productdetails') }}
                key={index}
                className="prouduct-data-row">
                <td style={{ textAlign: 'center' }}>{app.fullName}</td>
                <td style={{ textAlign: 'center' }}>{app.email}</td>
                <td style={{ textAlign: 'center' }}>{app.joinedOn}</td>
                <td className='flex flex-row items-center justify-center' style={{ textAlign: 'center' }}>
                  <div className='flex flex-row gap-2 items-center justify-center'>
                    <button
                      style={{
                        backgroundColor:
                          app.role === 'Editor' ? '#1F2124' :
                            app.role === 'Admin' ? '#E73877' :
                              app.role === 'Viewer' ? '#025E86' :
                                '',
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 12,
                        paddingRight: 12,
                        borderRadius: 8
                      }}
                    >
                      {app.role}
                    </button>
                  </div>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <img
                    src={threeDots} // Update with your actual path
                    alt='threeDots'
                    className='w-4 h-1 mx-auto' // Adjusted width and height
                  />
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };



  return (
    <div className="members-table">
      <div className='flex flex-1 flex-row items-center justify-between'>
        <div className='flex  items-center justify-start'>
          <div>
            <input
              placeholder='Search Members...'
              className='table-searchInput'
              style={{ color: "#FFF" }}
            />
          </div>
          <div>
            <select className='table-Dropdown bg-transparent text-white'>
              <option value="">Permissions All</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
              <option value="editor">Editor</option>
              {/* Add more options as needed */}
            </select>
          </div>
        </div>

        <div>
          <button
            onClick={() => { navigate('/dev/addmember') }}
            style={{ color: '#FFF', fontWeight: 600, paddingLeft: 16, paddingRight: 16 }}
            className='AddProductButton'
          >
            + New User
          </button>
        </div>
      </div>
      <div className='members-data-table'>
        <AppDataTable data={appData} />
      </div>
      <div style={{ color: '#FFF', fontWeight: 400, fontSize: '0.7rem', paddingTop: 8 }}>
        <span>Result 10 of 20 records</span>
      </div>
    </div>
  )
}

export default MembersTable;