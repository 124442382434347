import AppsIcon1 from "../../assets/images/icons/app-1.png";
import AppsIcon2 from "../../assets/images/icons/app-2.png";
import AppsIcon3 from "../../assets/images/icons/app-3.png";
import AiToolImg1 from "../../assets/images/icons/new1.png";
import AiToolImg2 from "../../assets/images/icons/new2.png";
import AiToolImg3 from "../../assets/images/icons/new3.png";
import AiToolImg4 from "../../assets/images/icons/new4.png";
import AiToolImg5 from "../../assets/images/icons/new5.png";
import AiToolImg6 from "../../assets/images/icons/new6.png";
import AiToolImg7 from "../../assets/images/icons/new7.png";
import AiToolImg8 from "../../assets/images/icons/new8.png";
import SubscriptionIcon1 from "../../assets/images/icons/subscriptions-1.png";
import SubscriptionIcon2 from "../../assets/images/icons/subscriptions-2.png";
import SubscriptionIcon3 from "../../assets/images/icons/subscriptions-3.png";
interface GridDataType {
  id: number;
  icon: any;
  title: any;
  genre: any;
}
const aiToolsData: GridDataType[] = [
  { id: 1, icon: AiToolImg1, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
  { id: 2, icon: AiToolImg2, title: 'Cod: Black ops', genre: 'Action | Adventure | Strategy' },
  { id: 3, icon: AiToolImg3, title: 'Clash of Clans', genre: 'Action | PVP | Comedy' },
  { id: 4, icon: AiToolImg4, title: 'Fortnite', genre: 'Action | Adventure | Comic' },
  { id: 5, icon: AiToolImg5, title: 'Red Dead Redemption II', genre: 'Action | Adventure | AAA' },
  { id: 6, icon: AiToolImg6, title: 'Deadpool & Wolverine', genre: 'Action | Adventure | Comedy' },
  { id: 7, icon: AiToolImg7, title: 'The Penguin', genre: 'Action | Adventure | Racing' },
  { id: 8, icon: AiToolImg8, title: 'Texas Law', genre: 'Law | Legal Services | Immigration' },
];
const appsData: GridDataType[] = [
  { id: 4, icon: AppsIcon1, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
  { id: 5, icon: AppsIcon2, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
  { id: 6, icon: AppsIcon3, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
];
const subscriptionsData: GridDataType[] = [
  { id: 7, icon: SubscriptionIcon1, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
  { id: 8, icon: SubscriptionIcon2, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
  { id: 9, icon: SubscriptionIcon3, title: 'Forza Horizon 4', genre: 'Action | Adventure | Racing' },
];

export { aiToolsData, appsData, subscriptionsData };

