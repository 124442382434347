import MembersTable from "../../components/tables/MembersTable";
import "../developer/dev.scss";
const AllMembers = () => {
    return (
        <div>
            <div>
                <div className='text-white font-bold text-[20px]'>
                    <span>Member Management</span>
                </div>
                <div>
                    <div className="horizontal-divider-light mt-4 mb-4"></div>
                </div>
            </div>
            <div className="flex-1">
                <MembersTable />
            </div>
        </div>
    )
}

export default AllMembers;