import React from 'react';
import './NewlyAddedCard.css';

type CardData = {
    imageSrc: string;
    title: string;
};

type CardProps = {
    card: CardData
}


const NewlyAddedCard: React.FC<CardProps> = ({ card }) => {
    return (
        <div className="podcast-service-container">
            <div className="image-overlay">
                <img src={card.imageSrc} alt="Texas Podcast Services" className="podcast-image" />
                <div className="overlay-text">
                    <p className='overlay-text-name'>{card.title}</p>
                </div>
                <div className="overlay-text-top">
                    <span className="bg-[#7D3CF3] px-2 py-1 text-white">NEWLY ADDED</span>
                </div>
            </div>
        </div>
    );
};

export default NewlyAddedCard;
