import { useState } from 'react';

const CreatorButton = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const firstSVG = (
        <svg
            className='border-2 border-[#4800CB] rounded-full' xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 34 34" fill="none">
            <circle cx="16.8326" cy="16.8326" r="16.817" fill="url(#paint0_linear_2684_13024)" />
            <path d="M12.3556 7.82863C12.449 7.57619 12.806 7.57619 12.8994 7.82863L14.673 12.6216C14.7024 12.701 14.7649 12.7636 14.8443 12.7929L19.6373 14.5665C19.8897 14.6599 19.8897 15.0169 19.6373 15.1104L14.8443 16.8839C14.7649 16.9133 14.7024 16.9759 14.673 17.0552L12.8994 21.8482C12.806 22.1007 12.449 22.1007 12.3556 21.8482L10.582 17.0552C10.5526 16.9759 10.49 16.9133 10.4107 16.8839L5.61769 15.1104C5.36525 15.0169 5.36525 14.6599 5.61769 14.5665L10.4107 12.7929C10.49 12.7636 10.5526 12.701 10.582 12.6216L12.3556 7.82863Z" fill="white" />
            <path d="M22.7552 16.6802C22.8486 16.4278 23.2056 16.4278 23.299 16.6802L24.1761 19.0505C24.2055 19.1299 24.2681 19.1924 24.3474 19.2218L26.7177 20.0989C26.9702 20.1923 26.9702 20.5494 26.7177 20.6428L24.3474 21.5199C24.2681 21.5492 24.2055 21.6118 24.1761 21.6912L23.299 24.0615C23.2056 24.3139 22.8486 24.3139 22.7552 24.0615L21.8781 21.6912C21.8487 21.6118 21.7861 21.5492 21.7068 21.5199L19.3364 20.6428C19.084 20.5494 19.084 20.1923 19.3364 20.0989L21.7068 19.2218C21.7861 19.1924 21.8487 19.1299 21.8781 19.0505L22.7552 16.6802Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_2684_13024" x1="16.8326" y1="0.015625" x2="16.8326" y2="33.6496" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF77B0" />
                    <stop offset="1" stopColor="#7D3CF3" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );

    const secondSVG = (
        <svg
            className='border-2 border-[#739FF0] rounded-full' xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 34 34" fill="none">
            <circle cx="16.8351" cy="17.0851" r="16.817" fill="url(#paint0_linear_1773_1766)" />
            <path d="M12.3589 8.08375C12.4523 7.83132 12.8093 7.83132 12.9027 8.08375L14.6763 12.8767C14.7056 12.9561 14.7682 13.0187 14.8476 13.0481L19.6406 14.8216C19.893 14.915 19.893 15.2721 19.6406 15.3655L14.8476 17.139C14.7682 17.1684 14.7056 17.231 14.6763 17.3104L12.9027 22.1034C12.8093 22.3558 12.4523 22.3558 12.3589 22.1034L10.5853 17.3104C10.5559 17.231 10.4933 17.1684 10.414 17.139L5.62098 15.3655C5.36855 15.2721 5.36855 14.915 5.62098 14.8216L10.414 13.0481C10.4933 13.0187 10.5559 12.9561 10.5853 12.8767L12.3589 8.08375Z" fill="white" />
            <path d="M22.7588 16.9348C22.8522 16.6824 23.2093 16.6824 23.3027 16.9348L24.1798 19.3051C24.2091 19.3845 24.2717 19.4471 24.3511 19.4765L26.7214 20.3536C26.9738 20.447 26.9738 20.804 26.7214 20.8974L24.3511 21.7745C24.2717 21.8039 24.2091 21.8664 24.1798 21.9458L23.3027 24.3161C23.2093 24.5686 22.8522 24.5686 22.7588 24.3161L21.8817 21.9458C21.8524 21.8664 21.7898 21.8039 21.7104 21.7745L19.3401 20.8974C19.0877 20.804 19.0877 20.447 19.3401 20.3536L21.7104 19.4765C21.7898 19.4471 21.8524 19.3845 21.8817 19.3051L22.7588 16.9348Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1773_1766" x1="16.8351" y1="0.268066" x2="16.8351" y2="33.9021" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFFF" />
                    <stop offset="1" stopColor="#7D3CF3" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );

    return (
        <button
            className="creatorBtn flex justify-evenly items-center ps-2 pe-4 py-1 transition-colors duration-300 text-white font-semibold shadow-lg text-xs"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className="mr-2 p-1 rounded-full">
                {isHovered ? secondSVG : firstSVG}
            </span>
            Join The Creator<br /> Community!
        </button>
    );
};

export default CreatorButton;
