import { useEffect, useRef, useState } from "react";

interface DropDownProps {
    label?: string;
    options: string[];
    onSelect?: (option: string) => void;
    width?: string;
    height?: string;
    padding?: string;
    customStyles?: string;
    dropdownStyles?: string;
    optionStyles?: string;
    isSubcategory?: boolean;
}

const DropDownx: React.FC<DropDownProps> = ({
    label,
    options,
    onSelect,
    width = "w-64",
    height = "",
    padding = "py-3 px-5",
    customStyles = "",
    dropdownStyles = "",
    optionStyles = "",
    isSubcategory = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(options[0] || "Select");
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option: string) => {
        setSelected(option);
        setIsOpen(false);
        if (onSelect) onSelect(option);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    // Reset the selected option whenever the options change (or the key prop changes)
    useEffect(() => {
        if (isSubcategory) {
            setSelected(options[0] || "Select");
        }
    }, [options]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block ${width} ${customStyles}`} ref={dropdownRef}>
            {label && <label className="block mb-3 text-white">{label}</label>}
            <div
                onClick={toggleDropdown}
                className={`flex justify-between items-center border-2 border-[#5721B9] text-white bg-[#04040433] px-5 py-3 rounded-full cursor-pointer`}
            >
                <span>{selected}</span>
                <svg
                    className={`w-4 h-4 transition-transform duration-200 ${isOpen ? "transform rotate-180" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </div>

            {isOpen && (
                <div
                    className={`absolute w-full text-white rounded-2xl shadow-lg ${dropdownStyles} flex flex-col overflow-y-auto ${height}`}
                    style={{
                        scrollbarWidth: "none", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Edge
                    }}
                >
                    {options.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => handleSelect(option)}
                            className={`py-3 px-5 border-2 border-[#5721B9] bg-[#290172] rounded-full cursor-pointer ${optionStyles}`}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropDownx;
