const ImpactSection = () => {
    return (
        <div>
            <div className="text-3xl md:text-4xl font-bold text-center">Our Impact in Numbers</div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-6">
                <div
                    className="rounded-lg overflow-hidden"
                    style={{
                        background: `linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)`,
                        border: `1px solid transparent`,
                        borderImageSource: `linear-gradient(177.73deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)`,
                        borderImageSlice: 1,
                    }}
                >
                    <div className="flex flex-col items-center justify-center py-12">
                        <h2 className="font-bold text-4xl text-white">1.73k+</h2>
                        <span style={{ color: " #C1C1C1" }}>Online User</span>
                    </div>
                </div>

                <div
                    className="rounded-lg overflow-hidden"
                    style={{
                        background: `linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)`,
                        border: `1px solid transparent`,
                        borderImageSource: `linear-gradient(177.73deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)`,
                        borderImageSlice: 1,
                    }}
                >
                    <div className="flex flex-col items-center justify-center py-12">
                        <h2 className="font-bold text-4xl text-white">1 M+</h2>
                        <span style={{ color: " #C1C1C1" }}>Projected Users</span>
                    </div>
                </div>

                <div
                    className="rounded-lg overflow-hidden"
                    style={{
                        background: `linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)`,
                        border: `1px solid transparent`,
                        borderImageSource: `linear-gradient(177.73deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)`,
                        borderImageSlice: 1,
                    }}
                >
                    <div className="flex flex-col items-center justify-center py-12">
                        <h2 className="font-bold text-4xl text-white">300M+</h2>
                        <span style={{ color: " #C1C1C1" }}>Goal Achiever</span>
                    </div>
                </div>

                <div
                    className="rounded-lg overflow-hidden  flex flex-col justify-center items-center"
                    style={{
                        background: `linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)`,
                        border: `1px solid transparent`,
                        borderImage: `linear-gradient(177.73deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)`,
                        borderImageSlice: 1,
                    }}
                >
                    <div className="flex flex-col items-center justify-center py-12">
                        <h2 className="font-bold text-4xl text-white">298k+</h2>
                        <span style={{ color: " #C1C1C1" }}>Subscriptions</span>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ImpactSection;
