
import axios from 'axios';
import { ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductListCard from '../../../components/cards/ProductListCard';
import { getCookies } from '../../../utils/utils';
import { moviesData } from '../../home/HomeExplore';
import CategorySection from '../CategorySection';
import { topTrendingData } from '../ExploreAll';
import AdditionalInfo from '../categoryDetails/Common/AdditionalInfo';
import DescriptionSection from '../categoryDetails/Common/DescriptionSection';
import MainBannerWithHeader from '../categoryDetails/Common/MainBannerWithHeader';
import RatingSection, { RatingSectionProps } from '../categoryDetails/Common/RatingSection';
import Screenshots from '../categoryDetails/Common/Screenshots';
import Storyline from '../categoryDetails/Common/Storyline';
import TeamMembersGrid from '../categoryDetails/Common/TeamMembersGrid';
import AvailableAt from './AvailableAt';
import MovieDesc from './MovieDesc';

export interface HeaderDataType {
    logo: string;
    title: string;
    rating: number;
    ratingCount: string;
    category: string;
    ageRating: string;
    desc: string;
}

interface AdditionalInfoDataType {
    trailerVideos: string[];
    publisher: string;
    genres: string;
    category: string;
    productSize: string;
    ageRating: string;
    support: {
        phoneNumber?: string;
        website?: string;
        email: string;
    };
    compatibility: {
        devices: string[];
        requirements: string[];
    };
}

export const LineDraw = () => (
    <svg className='mb-5' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
        <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
    </svg>
)

const MovieDetailsScreen = () => {
    const { id }: { id?: string } = useParams();
    const productId = id;

    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: '',
        title: '',
        rating: 0,
        ratingCount: '0',
        category: '',
        ageRating: '',
        desc: ''
    });

    const [additionalInfoData1, setAdditionalInfoData1] = useState<AdditionalInfoDataType>({
        trailerVideos: [],
        publisher: '',
        genres: '',
        category: '',
        productSize: '',
        ageRating: '',
        support: {
            phoneNumber: '',
            website: '',
            email: ''
        },
        compatibility: {
            devices: [],
            requirements: []
        }
    });

    const [mediaData, setMediaData]: any[] = useState([]);
    const [bannerImg, setBannerImg] = useState('');
    const [createdOn, setCreatedOn] = useState('');
    const [creator, setCreator] = useState('');
    const [teamInfo, setTeamInfo] = useState([]);
    const [tags, setTags] = useState([]);
    const [website, setWebsite] = useState('');

    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);


    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: "App is one of the best",
        reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: "Ben Cutting",
        reviewDate: "8/27/2024"
    };
    const GetMovieData = () => {
        const token = getCookies('authToken');

        axios
            .get(`https://api.lusso.dev/api/v1/products/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                const product = response.data;
                console.log("🚀 ~ GetMovieData ~ product:", product)

                setHeaderData({
                    logo: product?.exploreImage,
                    title: product?.name || '',
                    rating: product?.rating || 4.5,
                    ratingCount: product?.ratingCount || '330k',
                    category: product?.category || '',
                    ageRating: product?.additionalInfo?.audience?.age[0],
                    desc: product?.description || ''
                });

                setAdditionalInfoData1({
                    publisher: product?.createdBy || '',
                    trailerVideos: product?.trailerVideos,
                    genres: product?.subCategory || '',
                    category: product?.category || '',
                    productSize: '',
                    ageRating: product?.additionalInfo?.audience?.age[0],
                    support: {
                        phoneNumber: product?.additionalInfo?.supportInfo?.PHONE,
                        website: product?.additionalInfo?.supportInfo?.WEBSITE,
                        email: product?.additionalInfo?.supportInfo?.EMAIL
                    },
                    compatibility: {
                        devices: product?.additionalInfo?.productComability || [
                            "iPhone",
                            "iPad",
                            "iPod touch"
                        ],
                        requirements: [
                            "Requires iOS 12.0 or later.",
                            "Requires iPadOS 12.0 or later."
                        ]
                    }
                });

                setMediaData(product?.mediaLinks.map((media: any) => media.url));
                setBannerImg(product?.bannerImage);
                setCreatedOn(product?.createdOn);
                setTags(product?.tags || []);
                setTeamInfo(product?.teamInfo.cast || []);
                setWebsite(product?.websiteLink || '');
                setCreator(product?.createdBy || '');
            })
            .catch(error => {
                console.log('Error fetching data', error);
            });
    };

    useEffect(() => {
        GetMovieData();
    }, [productId]);

    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />
            <div className='content-new'>
                <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            headerData={headerData}
                            additionalInfoData={additionalInfoData1}
                            mediaData={mediaData}
                            trailerData={additionalInfoData1?.trailerVideos ? additionalInfoData1?.trailerVideos : []}
                            teamInfo={teamInfo.length ? teamInfo : []}
                            ratingData={ratingData}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainContent = ({ headerData, additionalInfoData, mediaData, trailerData, teamInfo, ratingData }: any) => (
    <div className="w-full md:w-2/3">
        <MovieDesc />
        <Screenshots
            screenshotData={mediaData}
            title={'Trailer & Snapshot'} />
        {/* <Trailor trailerData={trailerData} /> */}

        <TeamMembersGrid
            members={teamInfo}
            title='Instructor & Co-Instructors'
        />
        <AvailableAt />
        {/* <AvailableAt
            infoArr={availableAtInfo}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
        /> */}
        <DescriptionSection desc={headerData?.desc} />
        {/* <Details /> */}
        <Storyline desc={headerData?.storyline} />

        {/* <Cast teamInfo={teamInfo} /> */}
        <AdditionalInfo infoData={additionalInfoData} />
        {/* <RatingSection ratingData={{ rating: headerData.rating, reviewTitle: "Great Movie!" }} /> */}
        <RatingSection ratingData={ratingData} />

        <div className='md:hidden my-10 px-3'>
            <CategorySection title="PEOPLE ALSO VIEW" cards={topTrendingData} category={'Movies'} />
        </div>

        {/* Recommendation */}
        <div className='mb-5 px-0'>
            <CategorySection title="Recomendation" cards={topTrendingData} category={'Movies'} />
        </div>
    </div>
);

const Sidebar = () => (
    <div className="hidden w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10">
        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                PEOPLE ALSO VIEW
                <ChevronRight size={25} />
            </h2>
            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {moviesData.slice(0, 5).map((app, index) => (
                    <ProductListCard
                        key={index}
                        game={app}
                        bg={'#262242'}
                        isLarge={true}
                    />
                ))}
            </div>
        </div>

        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                Top Rated
                <ChevronRight size={25} />
            </h2>
            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {moviesData.slice(6, 9).map((app, index) => (
                    <ProductListCard
                        key={index}
                        game={app}
                        bg={'#262242'}
                    />
                ))}
            </div>
        </div>

    </div>
);

export default MovieDetailsScreen;

// import { useState } from 'react';
// import bannerImg from '../../../assets/images/explore/category/movies/moviebanner.png';
// import logo from '../../../assets/images/explore/category/movies/movielogo.png';
// import { moviesData } from '../../home/HomeExplore';

// import { ChevronRight } from 'lucide-react';
// import demo1 from '../../../assets/images/explore/category/movies/Frame1321315182.png';
// import demo2 from '../../../assets/images/explore/category/movies/image31.png';
// import demo3 from '../../../assets/images/explore/category/movies/image33.png';
// import ProductListCard from '../../../components/cards/ProductListCard';
// import CategorySection from '../CategorySection';
// import { topTrendingData } from '../ExploreAll';
// import AdditionalInfo from '../categoryDetails/Common/AdditionalInfo';
// import Header from '../categoryDetails/Common/Header';
// import MainBannerWithHeader from '../categoryDetails/Common/MainBannerWithHeader';
// import RatingSection, { RatingSectionProps } from '../categoryDetails/Common/RatingSection';
// import Screenshots from '../categoryDetails/Common/Screenshots';
// import Storyline from '../categoryDetails/Common/Storyline';
// import TeamMembersGrid from '../categoryDetails/Common/TeamMembersGrid';
// import { teamMembersData } from '../courses/CourseDetail';
// import MovieDesc from './MovieDesc';
// import AvailableAt from '../categoryDetails/Common/AvailableAt';


// export interface HeaderDataType {
//     logo: string;
//     title: string;
//     rating: number;
//     ratingCount: string;
//     category: string;
//     ageRating: string;
//     desc: string
//     storyline: string
// }
// export const moviesScreenshots = [
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
// ]

// export const LineDraw = () => (
//     <svg className='mb-5' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
//         <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
//     </svg>
// )

// const MovieDetailsScreen = () => {
//     const [selectedOption, setSelectedOption] = useState('Subscription');

//     const [headerData, setHeaderData] = useState<HeaderDataType>({
//         logo: logo,
//         title: 'Opeenheimer',
//         rating: 4.5,
//         ratingCount: '330k',
//         category: 'Entertainment',
//         ageRating: '12+',
//         desc: `Looking for the most talked about TV shows and movies from around the world? They're all on Netflix.
//         We've got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get
//         Netflix while you travel, commute, or just take a break.`,
//         storyline: `A dramatization of the life story of J. Robert Oppenheimer, the physicist who had a large hand in the development of the atomic bomb, thus helping end World War 2. We see his life from university days all the way to post-WW2, where his fame saw him embroiled in political machinations.        Read more`
//     });

//     const [starDistribution, setStarDistribution] = useState([
//         { stars: 5, percentage: 70 },
//         { stars: 4, percentage: 20 },
//         { stars: 3, percentage: 5 },
//         { stars: 2, percentage: 3 },
//         { stars: 1, percentage: 2 },
//     ]);


//     const ratingData: RatingSectionProps = {
//         starDistribution: starDistribution,
//         rating: 4.5,
//         reviewTitle: "App is one of the best",
//         reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
//         reviewerName: "Ben Cutting",
//         reviewDate: "8/27/2024"
//     };

//     const additionalInfoData = {
//         publisher: "Netflix, Inc.",
//         genres: "Entertainment",
//         category: "Apps/Entertainment",
//         productSize: "50 GB",
//         ageRating: "PG-18",
//         support: {
//             phoneNumber: "+18667160414",
//             email: "iosappstore@netflix.com"
//         },
//         compatibility: {
//             devices: [
//                 "iPhone",
//                 "iPad",
//                 "iPod touch"
//             ],
//             requirements: [
//                 "Requires iOS 12.0 or later.",
//                 "Requires iPadOS 12.0 or later."
//             ]
//         }
//     };


//     const MainBannerWithHeaderData = () => (
//         <div className="relative w-full md:h-[50rem] overflow-hidden rounded-lg">
//             {/* Blurred background image */}
//             <div
//                 className="hidden md:block md:absolute inset-0 bg-cover bg-center"
//                 style={{
//                     backgroundImage: `url(${bannerImg})`,
//                     filter: 'blur(2px)',
//                     transform: 'scale(1.1)'
//                 }}
//             />

//             {/* Gradient overlay */}
//             <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />

//             {/* Content */}
//             <div className="relative h-full flex justify-center items-end">
//                 <div className='content-new'>
//                     <div className="lg:max-w-[80%] mx-auto px-4 py-8">
//                         <Header headerData={headerData} />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )

//     // render
//     return (
//         <div className="alignCenter bg-blurred-new text-white min-h-screen">
//             <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />
//             {/* <MainBanner /> */}
//             <div className='content-new'>
//                 <div className="lg:max-w-[80%] mx-auto py-3 md:px-4 md:py-8">
//                     {/* <Header headerData={headerData} /> */}
//                     <div className="flex flex-col md:flex-row md:space-x-8">
//                         <MainContent
//                             selectedOption={selectedOption}
//                             setSelectedOption={setSelectedOption}
//                             headerData={headerData}
//                             screenshotData={moviesScreenshots}
//                             starDistribution={starDistribution}
//                             ratingData={ratingData}
//                             additionalInfoData={additionalInfoData}
//                         />
//                         <Sidebar />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const MainBanner = () => (
//     <div className="explore-cat-banner">
//         {/* This could be an image or video */}
//         <img src={bannerImg} alt="Movie Banner" className="explore-cat-banner" />
//     </div>
// );


// const MainContent = (props: any) => (
//     <div className="w-full md:w-2/3">
//         <MovieDesc />
//         {/* <Trailor /> */}
//         <Screenshots
//             screenshotData={props?.screenshotData}
//             title={'Trailer & Snapshot'} />
//         {/* <Cast /> */}
//         <TeamMembersGrid
//             members={teamMembersData}
//             title='Instructor & Co-Instructors'
//         />
//         <AvailableAt
//             selectedOption={props?.selectedOption}
//             setSelectedOption={props?.setSelectedOption} />

//         <Storyline desc={props.headerData?.storyline} />

//         <AdditionalInfo infoData={props?.additionalInfoData} />

//         <RatingSection ratingData={props?.ratingData} />

//         {/* <DescriptionSection desc={props.headerData?.desc} /> */}
//         {/* <Details selectedOption={props?.selectedOption} setSelectedOption={props?.setSelectedOption} /> */}
//         {/* <GameDescription desc={props.headerData?.desc} /> */}
//         {/* <InfoSection /> */}

//         <div className='md:hidden my-10 px-3'>
//             <CategorySection title="PEOPLE ALSO VIEW" cards={topTrendingData} category={'Courses'} />
//         </div>

//         {/* Recommendation */}
//         <div className='mb-5 px-0'>
//             <CategorySection title="Recomendation" cards={topTrendingData} category={'Ai'} />
//         </div>
//     </div>
// );


// const Sidebar = () => (
//     <div className="hidden w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10">
//         <div className='bg-[#161328] py-10 px-8'>
//             <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
//                 PEOPLE ALSO VIEW
//                 <ChevronRight size={25} />
//             </h2>
//             <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
//                 {moviesData.slice(0, 5).map((app, index) => (
//                     <ProductListCard
//                         key={index}
//                         game={app}
//                         bg={'#262242'}
//                         isLarge={true}
//                     />
//                 ))}
//             </div>
//         </div>

//         <div className='bg-[#161328] py-10 px-8'>
//             <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
//                 Top Rated
//                 <ChevronRight size={25} />
//             </h2>
//             <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
//                 {moviesData.slice(6, 9).map((app, index) => (
//                     <ProductListCard
//                         key={index}
//                         game={app}
//                         bg={'#262242'}
//                     />
//                 ))}
//             </div>
//         </div>

//     </div>
// );

// export default MovieDetailsScreen