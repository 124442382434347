import { useState } from "react";
import ServiceImg from "../../assets/images/aboutServiceImage.svg";
import EventImg from "../../assets/images/eventImage.png";
import EventGridCard from "../../components/cards/EventGridCard";

const EventSection = () => {
    const eventCard = {
        image: ServiceImg,
        title: "Business Program now Launched",
        subtitle: "17 August 2024",
        para:
            "Lorem ipsum quis massa id velit sagittis fermentum massa. Pellen tesque vel massa a felis. Lorem ipsum quis massa id velit sagittis fermentumitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate...",
        label: "more info",
    };
    const eventCard2 = {
        image: EventImg,
        title: "AI news this week",
        subtitle: "17 August 2024",
        para:
            "Lorem ipsum quis massa id velit sagittis fermentum massa. Pellen tesque vel massa a felis. Lorem ipsum quis massa id velit sagittis fermentumitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate...",
        label: "more info",
    };
    const eventCard3 = {
        image: EventImg,
        title: "AI news this week",
        subtitle: "17 August 2024",
        para:
            "Lorem ipsum quis massa id velit sagittis fermentum massa. Pellen tesque vel massa a felis. Lorem ipsum quis massa id velit sagittis fermentumitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate...",
        label: "more info",
    };
    const eventCard4 = {
        image: ServiceImg,
        title: "Business Program now Launched",
        subtitle: "17 August 2024",
        para:
            "Lorem ipsum quis massa id velit sagittis fermentum massa. Pellen tesque vel massa a felis. Lorem ipsum quis massa id velit sagittis fermentumitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate...",
        label: "more info",
    };

    const eventCards = [eventCard, eventCard2, eventCard3, eventCard4];

    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    return (
        <div className="mt-6">
            <h1 className="font-bold text-center text-3xl md:text-4xl mb-6">News And Events</h1>

            {/* Desktop Version */}
            <div className="hidden md:grid grid-cols-12 gap-4">
                <div className="col-span-4">
                    <EventGridCard eventData={eventCard} />
                </div>
                <div className="col-span-8">
                    <EventGridCard eventData={eventCard2} hprop={"h-40"} />
                </div>

                <div className="col-span-6">
                    <EventGridCard eventData={eventCard3} hprop={"h-36"} />
                </div>
                <div className="col-span-6">
                    <EventGridCard eventData={eventCard4} hprop={"h-36"} />
                </div>
            </div>

            {/* Mobile Version */}
            <div className="md:hidden relative">
                <div className="relative">
                    <EventGridCard eventData={eventCards[currentCardIndex]} />
                    <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2 p-2">
                        {eventCards.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentCardIndex(index)}
                                className={`w-5 h-3 rounded-lg ${currentCardIndex === index ? "bg-[#00F0FB]" : "bg-[#00FFFF61]"
                                    }`}
                            ></button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventSection;
