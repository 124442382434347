import { useEffect, useState } from 'react';
import cillian from '../../../assets/images/explore/category/courses/Cillian Morphy.png';
import emilley from '../../../assets/images/explore/category/courses/Emiley Blunt.png';
import matt from '../../../assets/images/explore/category/courses/Matt Morphy.png';
import bannerImg from '../../../assets/images/explore/category/courses/bg.png';
import ss1 from '../../../assets/images/explore/category/courses/course-ss-1.png';
import ss2 from '../../../assets/images/explore/category/courses/course-ss-2.png';
import logo from '../../../assets/images/explore/category/courses/logo.png';
import demo1 from '../../../assets/images/explore/category/games/img1.png';
import demo2 from '../../../assets/images/explore/category/games/img2.png';
import demo3 from '../../../assets/images/explore/category/games/img3.png';
import courseraIcon from '../../../assets/images/icons/coursera.svg';
import udemyIcon from '../../../assets/images/icons/udemy.svg';
import AdditionalInfo from '../categoryDetails/Common/AdditionalInfo';
import AvailableAt from '../categoryDetails/Common/AvailableAt';
import RatingSection, {
    RatingSectionProps,
} from '../categoryDetails/Common/RatingSection';
import Screenshots from '../categoryDetails/Common/Screenshots';

import { ChevronRight } from 'lucide-react';
import teamMember1 from '../../../assets/images/explore/category/team/1.png';
import teamMember2 from '../../../assets/images/explore/category/team/2.png';
import teamMember3 from '../../../assets/images/explore/category/team/3.png';
import certificateIcon from '../../../assets/images/icons/certificate.svg';
import downloadIcon from '../../../assets/images/icons/download.svg';
import keyIcon from '../../../assets/images/icons/key.svg';
import lifetimeIcon from '../../../assets/images/icons/lifetime-@.svg';
import playIcon from '../../../assets/images/icons/play-with-circle.svg';
import tvScreenIcon from '../../../assets/images/icons/tv-screen.svg';
import ProductListCard from '../../../components/cards/ProductListCard';
import { recomendationData } from '../../home/HomeExplore';
import CategorySection from '../CategorySection';
import { coursesData } from '../ExploreAll';
import MainBannerWithHeader from '../categoryDetails/Common/MainBannerWithHeader';
import Storyline from '../categoryDetails/Common/Storyline';
import TeamMembersGrid from '../categoryDetails/Common/TeamMembersGrid';
import CourseInfo from './CourseInfo';

export interface Tag {
    name?: string;
    selected?: boolean;
}

export interface HeaderDataType {
    logo?: string;
    title?: string;
    subTitle?: string;
    year?: string;
    duration?: string;
    lectures?: string;
    sections?: string;
    rating?: number;
    ratingCount?: string;
    category?: string;
    author?: string;
    desc?: string;
    tags?: Tag[];
    storyline?: string
    titleHeader?: string
}

export const LineDraw = () => (
    <svg
        className="mb-5"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="2"
        viewBox="0 0 1077 2"
        fill="none"
    >
        <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
    </svg>
);

export const screenshotData = [
    ss1,
    ss2,
    demo2,
    demo3,
    {
        video:
            'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1,
    },
    demo2,
    demo3,
    {
        video:
            'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1,
    },
    demo2,
    demo3,
    {
        video:
            'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1,
    },
    demo2,
    demo3,
];

export const characterData = [
    {
        img: cillian,
        title: 'Cillian Morphy',
        subTitle: 'Instructor',
    },
    {
        img: emilley,
        title: 'Emilley Blunt',
        subTitle: 'Co-Instructor',
    },
    {
        img: matt,
        title: 'Matt Morphy',
        subTitle: 'Co-Instructor',
    },
];

export const availableAtInfo = [
    {
        logo: udemyIcon,
        description:
            'English, Hindi, German, French, Hungarian, Korean, Turkish, Czech, Spanish...',
        purchaseTxt: '',
        price: '10.99/ month',
        subtext: 'Offers In-App Purchases',
    },
    {
        logo: courseraIcon,
        description:
            'English, Hindi, German, French, Hungarian, Korean, Turkish, Czech, Spanish...',
        purchaseTxt: '',
        price: '10.99/ month',
        subtext: 'Offers In-App Purchases',
    },
];

const courseIncludedData = {
    title: "Course Includes",
    data: [
        { icon: playIcon, text: '14 sections • 82 lectures • 4h 32m total length' },
        { icon: downloadIcon, text: '43 downloadable resources' },
        { icon: tvScreenIcon, text: 'Access on mobile and TV' },
        { icon: keyIcon, text: 'Full lifetime access' },
        { icon: lifetimeIcon, text: 'Audio description in existing audio' },
        { icon: certificateIcon, text: 'Certificate of completion' }
    ],
};

export const teamMembersData = [
    {
        name: 'Cillian',
        surname: 'Murphy',
        role: 'Manager',
        imageUrl: teamMember1,
    },
    {
        name: 'Emiley',
        surname: 'Blunt',
        role: 'Relevant field',
        imageUrl: teamMember2,
    },
    {
        name: 'Matt',
        surname: 'Murphy',
        role: 'Relevant field',
        imageUrl: teamMember3,
    },
];

const CourseDetailsScreen = () => {
    const [selectedOption, setSelectedOption] = useState('Subscription');
    const [selectedAvailableAt, setSelectedAvailableAt] = useState('Subscription');

    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: logo,
        title: 'Human Computer Interaction',
        titleHeader: 'Teaching & Academics - Science - Renewable Energy',
        subTitle: 'Learning how technology impacts us, and how we impact it',
        year: '2023',
        duration: '3h',
        lectures: '16',
        sections: '3',
        rating: 4.5,
        ratingCount: '330k',
        category: 'Course',
        author: 'Johnathan Miller',
        tags: [
            { name: 'Educational', selected: false },
            { name: 'Ai', selected: false },
            { name: 'CS', selected: false },
            { name: 'Computer', selected: false },
            { name: 'Biography', selected: false },
            { name: 'Drama', selected: false },
        ],
        desc: `Looking for the most talked about TV shows and movies from around the world? They’re all on Netflix.
                We’ve got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get Netflix while you travel, commute, or just take a break.

                Netflix membership is a month-to-month subscription that begins at sign up. You can easily cancel anytime, online, 24 hours a day. Looking for the most talked about TV shows and movies from around the world? They’re all on Netflix.
                We’ve got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get Netflix while you travel, commute, or just take a break.

                Netflix membership is a month-to-month subscription that begins at sign up. You can easily cancel anytime, online, 24 hours a day.`,
        storyline: `A dramatization of the life story of J. Robert Oppenheimer, the physicist who had a large hand in the development of the atomic bomb, thus helping end World War 2. We see his life from university days all the way to post-WW2, where his fame saw him embroiled in political machinations.`,
    });

    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);

    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: 'App is one of the best',
        reviewText:
            "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: 'Ben Cutting',
        reviewDate: '8/27/2024',
    };

    const infoSectionData = {
        title: "What you will learn",
        data: [
            'Play hundreds of addicting game levels, packed with fun challenging puzzles',
            'Become a great time-cruncher by solving Kingdom bubble shooting legend, and enjoy the classic smooth gameplay',
            'Beat your stars, complete puzzle quests, and collect awesome rewards',
            'Master the epic game time target and win',
            'Relaxing, stress relief time killer game to play whenever and wherever you want',
            'Fight alongside the bravest warriors of the kingdom and conquer all the amazing challenges',
            'Boost your experience with awesome power-ups and boosters and pop all the colorful balloons',
        ],
        linkPath: '#',
    };


    const additionalInfoData = {
        publisher: 'Netflix, Inc.',
        genres: 'Entertainment',
        category: 'Apps/Entertainment',
        productSize: '50 GB',
        ageRating: 'PG-18',
        support: {
            phoneNumber: '+18667160414',
            email: 'iosappstore@netflix.com',
        },
        compatibility: {
            devices: ['iPhone', 'iPad', 'iPod touch'],
            requirements: [
                'Requires iOS 12.0 or later.',
                'Requires iPadOS 12.0 or later.',
            ],
        },
    };

    // init
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });

    // render
    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            {/* <MainBanner /> */}
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} isCourse={true} />

            <div className="content-new">
                <div className="lg:max-w-[80%] mx-auto py-3 md:px-4 md:py-8">
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            characterData={characterData}
                            ratingData={ratingData}
                            screenshotData={screenshotData}
                            infoSectionData={infoSectionData}
                            additionalInfoData={additionalInfoData}
                            headerData={{
                                data: headerData,
                                setter: (data: any) => setHeaderData(data),
                            }}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainContent = (props: any) => {
    const onChangeTag = (index: number) => {
        const newTagData = {
            ...props?.headerData?.data,
            tags: props?.headerData?.data?.tags?.map((tag: any, id: number) => ({
                ...tag,
                selected: id === index ? !tag?.selected : false,
            })),
        };
        props?.headerData?.setter(newTagData);
    };
    return (
        <div className="w-full md:w-2/3">

            <CourseInfo />

            <Screenshots
                screenshotData={props?.screenshotData}
                title={'Preview the course'}
            />

            {/* <Characters
                characterData={characterData}
                title="Instructor & Co-Instructors"
            /> */}
            <TeamMembersGrid
                members={teamMembersData}
                title='Instructor & Co-Instructors'
            />

            <AvailableAt
                infoArr={availableAtInfo}
                selectedOption={props?.selectedOption}
                setSelectedOption={props?.setSelectedOption}
            />

            {/* <DescriptionSection desc={props.headerData?.data?.desc} lineLimit={5} /> */}
            <Storyline desc={props.headerData?.data?.storyline} />

            {/* <InfoSection
                data={props?.infoSectionData?.data}
                title={props?.infoSectionData?.title}
                linkPath={props?.infoSectionData?.linkPath}
            />
            <InfoSection
                data={courseIncludedData?.data}
                title={courseIncludedData?.title}
                showMoreLink={false}
            />
            <div className="mb-3 bg-[#161328] rounded-2xl p-5">
                <div className="flex flex-col space-y-2 mb-4 overflow-x-auto">
                    <div className="flex space-x-2 mb-4 overflow-x-auto">
                        {props?.headerData?.data?.tags?.map(
                            (option: any, index: number) => (
                                <button
                                    key={option?.name ?? index}
                                    className={`px-5 py-2 rounded-[30px] text-md font-semibold ${option?.selected
                                        ? 'bg-[#5721B9] text-white'
                                        : 'border-[#7D3CF3] border-2 text-[#F2F0FF]'
                                        }`}
                                    onClick={() => onChangeTag(index)}
                                >
                                    {option?.name ?? ''}
                                </button>
                            ),
                        )}
                    </div>
                    <p>
                        The course civer all the basics of computer interaction with human
                    </p>
                </div>
                <LineDraw />
                <div className="flex items-center gap-5 mb-2">
                    <h2 className="font-bold">Duration</h2>
                    <span className="text-[#6DDCFF]">
                        {props?.headerData?.data?.duration ?? ''}
                    </span>
                </div>
                <LineDraw />
                <div className="flex items-center gap-5 mb-2">
                    <h2 className="font-bold">Sections</h2>
                    <span className="text-[#6DDCFF]">
                        {props?.headerData?.data?.sections ?? ''}
                    </span>
                </div>
                <LineDraw />
                <div className="flex items-center gap-5 mb-2">
                    <h2 className="font-bold">Lectures</h2>
                    <span className="text-[#6DDCFF]">
                        {props?.headerData?.data?.lectures ?? ''}
                    </span>
                </div>
                <LineDraw />
            </div>

            */}
            <AdditionalInfo infoData={props?.additionalInfoData} />

            <RatingSection ratingData={props?.ratingData} />

            <div className='mb-5 px-3'>
                <CategorySection title="Top Rated" cards={coursesData} category={'Courses'} />
            </div>

            <div className='my-5 pt-5 px-3 md:hidden'>
                <CategorySection title="Recommended" cards={recomendationData} category={'Courses'} />
            </div>

        </div>
    );
};

const Sidebar = () => (
    <div className="w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10 hidden">
        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                Top Rated
                <ChevronRight size={25} />
            </h2>
            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {coursesData.slice(0, 5).map((app, index) => (
                    <ProductListCard
                        key={index}
                        game={app}
                        bg={'#262242'}
                        isLarge={true}
                    />
                ))}
            </div>
        </div>

        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                Recommended
                <ChevronRight size={25} />
            </h2>
            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {coursesData.slice(6, 9).map((app, index) => (
                    <ProductListCard
                        key={index}
                        game={app}
                        bg={'#262242'}
                    />
                ))}
            </div>
        </div>

    </div>
);

export default CourseDetailsScreen;
