import pr1 from '../assets/images/features/pr1.png';
import pr10 from '../assets/images/features/pr10.jpeg';
import pr11 from '../assets/images/features/pr11.png';
import pr12 from '../assets/images/features/pr12.png';
import pr13 from '../assets/images/features/pr13.jpeg';
import pr14 from '../assets/images/features/pr14.jpeg';
import pr15 from '../assets/images/features/pr15.jpeg';
import pr16 from '../assets/images/features/pr16.jpeg';
import pr17 from '../assets/images/features/pr17.png';
import pr18 from '../assets/images/features/pr18.jpeg';
import pr19 from '../assets/images/features/pr19.jpeg';
import pr2 from '../assets/images/features/pr2.png';
import pr20 from '../assets/images/features/pr20.jpeg';
import pr21 from '../assets/images/features/pr21.jpeg';
import pr22 from '../assets/images/features/pr22.jpeg';
import pr23 from '../assets/images/features/pr23.jpeg';
import pr24 from '../assets/images/features/pr24.png';
import pr25 from '../assets/images/features/pr25.jpeg';
import pr32 from '../assets/images/features/pr25.png';
import pr26 from '../assets/images/features/pr26.png';
import pr33 from '../assets/images/features/pr27.jpeg';
import pr27 from '../assets/images/features/pr27.png';
import pr28 from '../assets/images/features/pr28.png';
import pr29 from '../assets/images/features/pr29.png';
import pr3 from '../assets/images/features/pr3.png';
import pr30 from '../assets/images/features/pr30.jpeg';
import pr31 from '../assets/images/features/pr31.jpeg';
import pr4 from '../assets/images/features/pr4.jpeg';
import pr5 from '../assets/images/features/pr5.jpeg';
import pr6 from '../assets/images/features/pr6.png';
import pr7 from '../assets/images/features/pr7.jpeg';
import pr8 from '../assets/images/features/pr8.png';
import pr9 from '../assets/images/features/pr9.png';

import icon1 from '../assets/images/features/icon1.png';
import icon10 from '../assets/images/features/icon10.png';
import icon11 from '../assets/images/features/icon11.png';
import icon12 from '../assets/images/features/icon12.png';
import icon13 from '../assets/images/features/icon13.png';
import icon14 from '../assets/images/features/icon14.png';
import icon15 from '../assets/images/features/icon15.png';
import icon16 from '../assets/images/features/icon16.png';
import icon17 from '../assets/images/features/icon17.png';
import icon2 from '../assets/images/features/icon2.png';
import icon3 from '../assets/images/features/icon3.png';
import icon4 from '../assets/images/features/icon4.png';
import icon5 from '../assets/images/features/icon5.png';
import icon6 from '../assets/images/features/icon6.png';
import icon7 from '../assets/images/features/icon7.png';
import icon8 from '../assets/images/features/icon8.png';
import icon9 from '../assets/images/features/icon9.png';
export const cardDataAi = [
  {
    id: 1,
    title: 'Microsoft Copilot',
    downloads: '100M+ Downloads',
    type: '100M+ Downloads | Productivity',
    category: 'Entertainment',
    rating: 4.6,
    imgSrc: pr1,
  },
  {
    id: 2,
    title: 'Google Assistant',
    downloads: '50M+ Downloads',
    type: 'Virtual Assistant',
    category: 'Entertainment',
    rating: 4.8,
    imgSrc: pr2,
  },
  {
    id: 3,
    title: 'Amazon Alexa',
    downloads: '200M+ Downloads',
    type: 'Virtual Assistant',
    category: 'Entertainment',
    rating: 4.7,
    imgSrc: pr3,
  },
  {
    id: 4,
    title: 'IBM Watson Health',
    downloads: '80M+ Downloads',
    type: 'Industry | Healthcare',
    category: 'Entertainment',
    rating: 4.5,
    imgSrc: pr4,
  },
  {
    id: 5,
    title: 'ChatGPT',
    downloads: '120M+ Downloads',
    type: 'AI Chatbot | Productivity',
    category: 'Entertainment',
    rating: 4.9,
    imgSrc: pr5,
  },
  {
    id: 6,
    title: 'Duolingo',
    downloads: '300M+ Downloads',
    type: 'Language',
    category: 'Entertainment',
    rating: 4.6,
    imgSrc: pr6,
  },
];
export const cardDataAi2 = [
  {
    id: 1,
    text: 'Real-time analytics',
    icon: icon17,
  },
  {
    id: 2,
    text: 'Personalized recommendations',
    icon: icon16,
  },
  {
    id: 3,
    text: 'Seamless integrations with your daily workflows',
    icon: icon15,
  },
];
export const cardDataAi3 = [
  {
    id: 1,
    text: 'Real-time analytics',
    icon: icon1,
  },
  {
    id: 2,
    text: 'Enhanced exposure through social platforms',
    icon: icon16,
  },
  {
    id: 3,
    text: 'automated promotion features',
    icon: icon15,
  },
];

// --------------- Card Data Apps -----------
export const cardDataApps = [
  {
    id: 1,
    title: 'Tiktok',
    downloads: '100M+ Downloads',
    type: 'Video | Entertainment',
    category: 'App',
    rating: 4.6,
    imgSrc: pr24,
  },
  {
    id: 2,
    title: 'Youtube',
    downloads: '50M+ Downloads',
    type: 'Video | Entertainment',
    category: 'App',
    rating: 4.8,
    imgSrc: pr25,
  },
  {
    id: 3,
    title: 'iTunes',
    downloads: '200M+ Downloads',
    type: 'Music',
    category: 'App',
    rating: 4.7,
    imgSrc: pr26,
  },
  {
    id: 4,
    title: 'Spotify',
    downloads: '80M+ Downloads',
    type: 'Music',
    category: 'App',
    rating: 4.5,
    imgSrc: pr27,
  },
  {
    id: 5,
    title: 'TED',
    downloads: '120M+ Downloads',
    type: 'Education',
    category: 'App',
    rating: 4.9,
    imgSrc: pr28,
  },
  {
    id: 6,
    title: 'Tiktok',
    downloads: '300M+ Downloads',
    type: 'Video | Entertainment',
    category: 'App',
    rating: 4.6,
    imgSrc: pr24,
  },
];
export const cardDataApps2 = [
  {
    id: 1,
    text: 'Easy search and filtering',
    icon: icon5,
  },
  {
    id: 2,
    text: 'top-rated app lists',
    icon: icon6,
  },
  {
    id: 3,
    text: 'personalized recommendations, and user reviews',
    icon: icon16,
  },
];
export const cardDataApps3 = [
  {
    id: 1,
    text: 'User analytics',
    icon: icon2,
  },
  {
    id: 2,
    text: 'feedback integration',
    icon: icon9,
  },
  {
    id: 3,
    text: 'social promotion tools to boost app visibility',
    icon: icon12,
  },
];

// --------------- Card Data Games -----------
export const cardDataGames = [
  {
    id: 1,
    title: 'Need for Spee: Heat',
    downloads: '50M+ Downloads',
    type: 'Racing',
    category: 'App',
    rating: 4.8,
    imgSrc: pr7,
  },
  {
    id: 2,
    title: 'Cyberpunk 2077',
    downloads: '100M+ Downloads',
    type: 'Adventure',
    category: 'App',
    rating: 4.6,
    imgSrc: pr8,
  },
  {
    id: 3,
    title: 'God of War',
    downloads: '200M+ Downloads',
    type: 'Action | Adventure',
    category: 'App',
    rating: 4.7,
    imgSrc: pr9,
  },
  {
    id: 4,
    title: 'FIFA23',
    downloads: '80M+ Downloads',
    type: 'Action | Adventure',
    category: 'App',
    rating: 4.5,
    imgSrc: pr10,
  },
  {
    id: 5,
    title: 'Tekken 8',
    downloads: '120M+ Downloads',
    type: 'Action',
    category: 'App',
    rating: 4.9,
    imgSrc: pr11,
  },
  {
    id: 6,
    title: 'FIFA24',
    downloads: '300M+ Downloads',
    type: 'Adventure',
    category: 'App',
    rating: 4.6,
    imgSrc: pr12,
  },
];
export const cardDataGames2 = [
  {
    id: 1,
    text: 'Easy discovery of games by genre',
    icon: icon17,
  },
  {
    id: 2,
    text: 'Keep track of trending games',
    icon: icon2,
  },
  {
    id: 3,
    text: 'Get Recommendations based on your playstyle',
    icon: icon16,
  },
];
export const cardDataGames3 = [
  {
    id: 1,
    text: 'Integrated social promotion',
    icon: icon12,
  },
  {
    id: 2,
    text: 'user engagement tracking',
    icon: icon16,
  },
  {
    id: 3,
    text: 'game performance metrics, and feedback loops',
    icon: icon10,
  },
];

// --------------- Card Data Services -----------
export const cardDataServices = [
  {
    id: 1,
    title: 'Merry Maids',
    downloads: '50M+ Downloads',
    type: 'Deep cleaning | Plants | Machine wash',
    category: 'Services',
    rating: 4.8,
    imgSrc: pr13,
  },
  {
    id: 2,
    title: 'Two Men and a Truck',
    downloads: '100M+ Downloads',
    type: 'Moving Services',
    category: 'Services',
    rating: 4.6,
    imgSrc: pr14,
  },
  {
    id: 3,
    title: 'HomeAdvisor',
    downloads: '200M+ Downloads',
    type: 'Home Improvement',
    category: 'Services',
    rating: 4.7,
    imgSrc: pr15,
  },
  {
    id: 4,
    title: 'Servpro',
    downloads: '80M+ Downloads',
    type: 'Damage Restoration',
    category: 'Services',
    rating: 4.5,
    imgSrc: pr16,
  },
  {
    id: 5,
    title: 'McCoy Law Firm',
    downloads: '120M+ Downloads',
    type: 'Law | Legal Services | Immigration',
    category: 'Services',
    rating: 4.9,
    imgSrc: pr17,
  },
  {
    id: 6,
    title: 'Merry Maids',
    downloads: '300M+ Downloads',
    type: 'Deep cleaning | Plants | Machine wash',
    category: 'Services',
    rating: 4.6,
    imgSrc: pr13,
  },
];
export const cardDataServices2 = [
  {
    id: 1,
    text: 'Easily compare service providersEasily compare service providers',
    icon: icon11,
  },
  {
    id: 2,
    text: 'review past work',
    icon: icon15,
  },
  {
    id: 3,
    text: 'see pricing upfront, and make direct inquiries.',
    icon: icon8,
  },
];
export const cardDataServices3 = [
  {
    id: 1,
    text: 'Access to detailed client feedback',
    icon: icon9,
  },
  {
    id: 2,
    text: 'customer acquisition tools',
    icon: icon14,
  },
  {
    id: 3,
    text: 'metrics on service performance.',
    icon: icon10,
  },
];

// --------------- Card Data Movies -----------
export const cardDataMovies = [
  {
    id: 1,
    title: 'King Kong',
    downloads: '50M+ Downloads',
    type: 'Action | Adventure ',
    category: 'Entertainment',
    rating: 4.8,
    imgSrc: pr18,
  },
  {
    id: 2,
    title: 'Saving Private Ryan',
    downloads: '100M+ Downloads',
    type: 'War',
    category: 'Entertainment',
    rating: 4.6,
    imgSrc: pr19,
  },
  {
    id: 3,
    title: 'Oppenheimer',
    downloads: '200M+ Downloads',
    type: 'Action | Adventure | Comedy',
    category: 'Entertainment',
    rating: 4.7,
    imgSrc: pr20,
  },
  {
    id: 4,
    title: 'Raiders of the Lost Ark',
    downloads: '80M+ Downloads',
    type: 'Adventure',
    category: 'Entertainment',
    rating: 4.5,
    imgSrc: pr21,
  },
  {
    id: 5,
    title: '1917',
    downloads: '120M+ Downloads',
    type: 'War',
    category: 'Entertainment',
    rating: 4.9,
    imgSrc: pr22,
  },
  {
    id: 6,
    title: 'Mad Max: Fury Road',
    downloads: '300M+ Downloads',
    type: 'Adventure',
    category: 'Entertainment',
    rating: 4.6,
    imgSrc: pr23,
  },
];
export const cardDataMovies2 = [
  {
    id: 1,
    text: 'Curated movie suggestions',
    icon: icon17,
  },
  {
    id: 2,
    text: 'access to reviews and trailers',
    icon: icon7,
  },
  {
    id: 3,
    text: 'easy-to-navigate streaming links',
    icon: icon4,
  },
];
export const cardDataMovies3 = [
  {
    id: 1,
    text: 'Real-time viewer analytics',
    icon: icon2,
  },
  {
    id: 2,
    text: 'promotion across multiple social media channels',
    icon: icon12,
  },
  {
    id: 3,
    text: 'direct audience feedback',
    icon: icon9,
  },
];

// --------------- Card Data Courses -----------
export const cardDataCourses = [
  {
    id: 1,
    title: 'Mastering UI & IX Design',
    downloads: '50M+ Downloads',
    type: 'Design | Development | App',
    category: 'App',
    rating: 4.8,
    imgSrc: pr32,
  },
  {
    id: 2,
    title: 'Micro Biology',
    downloads: '100M+ Downloads',
    type: 'Cells | Science | Research',
    category: 'App',
    rating: 4.6,
    imgSrc: pr33,
  },
  {
    id: 3,
    title: 'Augmented Reality',
    downloads: '200M+ Downloads',
    type: 'AR/VR | Training | Vision Pro',
    category: 'App',
    rating: 4.7,
    imgSrc: pr29,
  },
  {
    id: 4,
    title: 'Architecture: 101',
    downloads: '80M+ Downloads',
    type: 'Structues | Out of box | Learn',
    category: 'App',
    rating: 4.5,
    imgSrc: pr30,
  },
  {
    id: 5,
    title: 'Digital Marketing',
    downloads: '120M+ Downloads',
    type: 'Design | Development | App',
    category: 'App',
    rating: 4.9,
    imgSrc: pr31,
  },
  {
    id: 6,
    title: 'Micro Biology',
    downloads: '100M+ Downloads',
    type: 'Cells | Science | Research',
    category: 'App',
    imgSrc: pr23,
  },
];
export const cardDataCourses2 = [
  {
    id: 1,
    text: 'Interactive courses',
    icon: icon17,
  },
  {
    id: 2,
    text: 'earning paths based on your goals',
    icon: icon3,
  },
  {
    id: 3,
    text: 'Certificate programs, and progress tracking',
    icon: icon13,
  },
];
export const cardDataCourses3 = [
  {
    id: 1,
    text: 'Instructor analytics',
    icon: icon2,
  },
  {
    id: 2,
    text: 'marketing tools to reach more students',
    icon: icon12,
  },
  {
    id: 3,
    text: 'student feedback for course improvement',
    icon: icon9,
  },
];
