import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { checkNullOrEmpty } from '../../utils/utils';
import SelectDropdown from '../ui/SelectDropdown';

interface OptionType {
    value: string;
    label: string;
}

interface SocialPageModalProps {
    options: OptionType[];
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onConfirm: (page: null | OptionType) => void;
}

const SocialPageModal: React.FC<SocialPageModalProps> = ({ loading, open, options, onClose, onConfirm }) => {
    const [pageVal, setPageVal] = useState(null)

    return (
        <Dialog
            open={open}
            fullWidth={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Select page</DialogTitle>
            <DialogContent>
                <SelectDropdown
                    value={pageVal}
                    placeholder="Select"
                    options={options}
                    onChange={(selectedOption: any) => setPageVal(selectedOption)}
                    error={checkNullOrEmpty(pageVal)}
                    errorMessage="Please select page"
                    required
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    onClick={onClose}
                    variant="outlined"
                    disabled={loading}
                    style={{
                        border: '1px #E73877 solid',
                        color: '#E73877'
                    }}
                >
                    <span>Cancel</span>
                </LoadingButton>
                <LoadingButton
                    size="small"
                    onClick={() => onConfirm(pageVal)}
                    loading={loading}
                    variant="outlined"
                    disabled={checkNullOrEmpty(pageVal) || loading}
                // style={{
                //     color: '#FFF',
                //     backgroundColor: '#E73877'
                // }}
                >
                    <span>Add</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SocialPageModal;
