import { createTheme } from "@mantine/core";

export const stepsArr = [
    { label: "Organization Type" },
    { label: "Name" },
    { label: "Address" },
    { label: "Contact Info" },
    // { label: "Security" },
];


export const theme = createTheme({
});


