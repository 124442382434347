import axios from 'axios';
import { ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import demo1 from '../../../assets/images/explore/category/games/img1.png';
import demo2 from '../../../assets/images/explore/category/games/img2.png';
import demo3 from '../../../assets/images/explore/category/games/img3.png';
import starIcon from '../../../assets/images/explore/category/games/star.svg';
import ProductListCard from '../../../components/cards/ProductListCard';
import { getCookies } from '../../../utils/utils';
import { games } from '../TopTrending';
import AdditionalInfo from './Common/AdditionalInfo';
import AvailableAt from './Common/AvailableAt';
import DescriptionSection from './Common/DescriptionSection';
import FeatureSection from './Common/FeatureSection';
import InfoSection from './Common/InfoSection';
import MainBannerWithHeader from './Common/MainBannerWithHeader';
import RatingSection, { RatingSectionProps } from './Common/RatingSection';
import Screenshots from './Common/Screenshots';
import CategorySection from '../CategorySection';
import { appsData, topTrendingData } from '../../home/HomeExplore';

interface HeaderDataType {
    logo: string;
    title: string;
    rating: number;
    ratingCount: string;
    category: string;
    ageRating: string;
    desc: string
}

interface infoSectionDataType {
    title: string,
    data: [],
    linkPath: string
}

interface additionalInfoDataType {
    publisher: string,
    genres: string,
    category: string,
    productSize: string,
    productInfo: {
        Feature?: string;
    };
    ageRating: string,
    support: {
        phoneNumber?: string,
        website?: string,
        email: string
    },
    compatibility: {
        devices: string[],
        requirements: string[]
    }
}



export const LineDraw = () => (
    <svg className='mb-5' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
        <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
    </svg>
)

export const screenshotData = [
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
]


const GameDetailsScreen = () => {
    const [selectedOption, setSelectedOption] = useState('Subscription');

    const [headerData, setHeaderData]: any = useState<HeaderDataType>({
        logo: "",
        title: "",
        rating: 0,
        ratingCount: "",
        category: "",
        ageRating: "",
        desc: '',
    });
    const [infoSectionData, setinfoSectionData]: any = useState<infoSectionDataType>({
        data: [],
        linkPath: "",
        title: ""
    });

    const [additionalInfoData, setadditionalInfoData] = useState<additionalInfoDataType>({
        publisher: "",
        genres: "",
        category: "",
        productSize: "",
        productInfo: {
            Feature: ''

        },
        ageRating: "",
        support: {
            phoneNumber: "",
            website: "",
            email: ""
        },
        compatibility: {
            devices: [],
            requirements: []
        }
    })

    const [loading, setLoading] = useState(false);
    const [createdOn, setCreatedOn] = useState('');
    const [productType, setProductType] = useState('');
    const [tags, setTags] = useState([]);
    const [website, setWebsite] = useState('');
    const [bannerImg, setbannerImg] = useState('');
    const [creator, setCreator] = useState('');
    const [images, setImages]: any = useState([]);
    const [product, setProduct]: any = useState({});
    const [similarProducts, setSimilarProducts]: any[] = useState([]);
    const [addReviewFlag, setAddReviewFlag]: any = useState(false);
    const [mediaData, setmediaData]: any[] = useState([]);


    const { id }: { id?: string } = useParams();
    const productId = id

    console.log("productId", productId)

    const GetGameData = () => {

        setLoading(true);
        const token = getCookies('authToken');

        axios
            .get(`https://api.lusso.dev/api/v1/products/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                const product = response.data;
                console.log("🚀 ~ GetGameData ~ product:", product)
                let currentProductType = '';

                // Find the current product and set its type

                if (product.productId === productId) {
                    currentProductType = product.productType;

                    setHeaderData({
                        logo: product?.exploreImage,
                        title: product?.name || "",
                        rating: product?.rating || 4.5,
                        ratingCount: product?.ratingCount || '330k',
                        category: product?.category || '',
                        ageRating: product?.additionalInfo?.audience?.age[0],
                        desc: product?.description || ''
                    })

                    setProduct(product);
                    setmediaData(product?.mediaLinks.map((media: any) => media.url))
                    setbannerImg(product?.bannerImage)

                    setinfoSectionData({
                        title: "Why You'll Like this Game?",
                        // data: product?.productInfo?.Feature?.Features,
                        data: [],
                        linkPath: ""
                    })

                    setadditionalInfoData({
                        publisher: "",
                        genres: product?.subCategory || "",
                        category: product?.category || "",
                        productSize: "",
                        productInfo: {
                            Feature
                                : product?.productInfo.Feature
                        },
                        ageRating: product?.additionalInfo?.audience?.age[0],
                        support: {
                            phoneNumber: product?.additionalInfo?.supportInfo["PHONE_NUMBER"],
                            website: product?.additionalInfo?.supportInfo["WEBSITE"],
                            email: product?.additionalInfo?.supportInfo["EMAIL"]
                        },
                        compatibility: {
                            devices: product?.additionalInfo?.productComability?.map((dev: string) => dev),
                            requirements: []
                        }
                    })

                    setCreatedOn(product?.createdOn);
                    setTags(product?.tags);
                    setWebsite(product?.websiteLink);
                    setCreator(product?.createdBy);
                    setProductType(currentProductType);
                    // setWishlisted(true);
                }

                axios
                    .get(`https://api.lusso.dev/api/v1/similarProducts?productId=${productId}&productType=${currentProductType}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(response => {
                        setSimilarProducts(response.data.products);
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });
    };

    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);

    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: "App is one of the best",
        reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: "Ben Cutting",
        reviewDate: "8/27/2024"
    };



    useEffect(() => {
        GetGameData()
    }, [productId]);


    // render
    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />

            <div className='content-new'>
                <div className="lg:max-w-[80%] mx-auto py-3 md:px-4 md:py-8">
                    {/* <div className="explore-cat-banner">

                        <video
                            // ref={videoRef}
                            // className="w-full h-full"
                            className="explore-cat-banner w-full"
                            // style={{ display: "none" }}
                            // onClick={handleVideoClick}
                            // onEnded={handleVideoEnded}
                            autoPlay
                            loop
                            muted
                        >
                            <source
                                src="https://play-games.googleusercontent.com/vp/mp4/1280x720/R3x88pl2rZI.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <Header headerData={headerData} /> */}

                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            headerData={headerData}
                            ratingData={ratingData}
                            screenshotData={mediaData}
                            infoSectionData={infoSectionData}
                            additionalInfoData={additionalInfoData}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Header = ({ headerData }: any) => (
    <div className="flex items-center mb-8 gap-5">
        <div className='w-[200px] h-[200px] rounded-2xl'>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} />
        </div>
        <div className='flex flex-col justify-between gap-3'>
            <h1 className="text-4xl font-bold">{headerData?.title}</h1>
            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        <img src={starIcon} alt='star' />
                    </div>
                    <span> | {headerData?.ratingCount} ratings | {headerData?.category}</span>
                </p>
            </div>
            <div className="flex flex-col gap-3">
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Category</p>
                    <p className='text-white'>{headerData.category}</p>
                </div>
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                    <p className='text-white'>{headerData.ageRating}</p>
                </div>
            </div>
        </div>
    </div>
);

const MainContent = (props: any) => (
    <div className="w-full md:w-2/3">
        <Screenshots
            screenshotData={props?.screenshotData}
            title={'Screenshots'} />

        <AvailableAt
            selectedOption={props?.selectedOption}
            setSelectedOption={props?.setSelectedOption} />

        <DescriptionSection desc={props.headerData?.desc} lineLimit={5} />
        <FeatureSection
            productInfo={props?.additionalInfoData.productInfo.Feature}
        />
      
        <InfoSection
            data={props?.infoSectionData?.data}
            title={props?.infoSectionData?.title}
            linkPath={props?.infoSectionData?.linkPath}
        />
        <RatingSection ratingData={props?.ratingData} />

        <AdditionalInfo infoData={props?.additionalInfoData} />

        <div className='md:hidden my-10 px-3'>
            <CategorySection title="PEOPLE ALSO VIEW" cards={topTrendingData} category={'Games'} />
        </div>

        <div className='mb-5 px-3'>
            <CategorySection title="Top Rated" cards={appsData} category={'Games'} />
        </div>
    </div>
);


const Sidebar = () => (
    <div className="hidden w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10">
        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                PEOPLE ALSO VIEW
                <ChevronRight size={25} />
            </h2>        <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {games.map((game, index) => (
                    <ProductListCard key={index} game={game} bg={'rgba(121, 47, 255, 0.15)'} />
                ))}
            </div>
        </div>
        <div className='bg-[#161328] py-10 px-8'>
            <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                Top Rated
                <ChevronRight size={25} />
            </h2>
            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                {games.slice(6, 9).map((app, index) => (
                    <ProductListCard
                        key={index}
                        game={app}
                        bg={'#262242'}
                    />
                ))}
            </div>
        </div>
    </div>
);

export default GameDetailsScreen
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { ChevronRight } from 'lucide-react';
// import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import demo1 from '../../../assets/images/explore/category/games/img1.png';
// import demo2 from '../../../assets/images/explore/category/games/img2.png';
// import demo3 from '../../../assets/images/explore/category/games/img3.png';
// import ProductListCard from '../../../components/cards/ProductListCard';
// import CategorySection from '../CategorySection';
// import { appsData, topTrendingData } from '../ExploreAll';
// import { games } from '../TopTrending';
// import AdditionalInfo from './Common/AdditionalInfo';
// import AvailableAt from './Common/AvailableAt';
// import DescriptionSection from './Common/DescriptionSection';
// import InfoSection from './Common/InfoSection';
// import MainBannerWithHeader from './Common/MainBannerWithHeader';
// import RatingSection, { RatingSectionProps } from './Common/RatingSection';
// import Screenshots from './Common/Screenshots';

// interface HeaderDataType {
//     logo: string;
//     title: string;
//     rating: number;
//     ratingCount: string;
//     category: string;
//     ageRating: string;
//     desc: string
// }

// interface infoSectionDataType {
//     title: string,
//     data: [],
//     linkPath: string
// }

// interface additionalInfoDataType {
//     publisher: string,
//     genres: string,
//     category: string,
//     productSize: string,
//     ageRating: string,
//     support: {
//         phoneNumber?: string,
//         website?: string,
//         email: string
//     },
//     compatibility: {
//         devices: string[],
//         requirements: string[]
//     }
// }



// export const LineDraw = () => (
//     <svg className='mb-5 h-px bg-[#454253]' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
//         <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
//     </svg>
// )

// export const screenshotData = [
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
//     {
//         video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
//         img: demo1
//     },
//     demo2,
//     demo3,
// ]


// const GameDetailsScreen = () => {
//     const [selectedOption, setSelectedOption] = useState('Subscription');

//     const [headerData, setHeaderData]: any = useState<HeaderDataType>({
//         logo: "",
//         title: "",
//         rating: 0,
//         ratingCount: "",
//         category: "",
//         ageRating: "",
//         desc: '',
//     });
//     const [infoSectionData, setinfoSectionData]: any = useState<infoSectionDataType>({
//         data: [],
//         linkPath: "",
//         title: ""
//     });

//     const [additionalInfoData, setadditionalInfoData] = useState<additionalInfoDataType>({
//         publisher: "",
//         genres: "",
//         category: "",
//         productSize: "",
//         ageRating: "",
//         support: {
//             phoneNumber: "",
//             website: "",
//             email: ""
//         },
//         compatibility: {
//             devices: [],
//             requirements: []
//         }
//     })

//     const [loading, setLoading] = useState(false);
//     const [createdOn, setCreatedOn] = useState('');
//     const [productType, setProductType] = useState('');
//     const [tags, setTags] = useState([]);
//     const [website, setWebsite] = useState('');
//     const [bannerImg, setbannerImg] = useState('');
//     const [creator, setCreator] = useState('');
//     const [images, setImages]: any = useState([]);
//     const [product, setProduct]: any = useState({});
//     const [similarProducts, setSimilarProducts]: any[] = useState([]);
//     const [addReviewFlag, setAddReviewFlag]: any = useState(false);
//     const [mediaData, setmediaData]: any[] = useState([]);


//     const { id }: { id?: string } = useParams();
//     const productId = id

//     console.log("productId", productId)

//     const GetGameData = () => {

//         setLoading(true);
//         const token = Cookies.get('authToken');

//         axios
//             .get(`https://api.lusso.dev/api/v1/products/${productId}`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             })
//             .then(response => {
//                 const product = response.data;
//                 let currentProductType = '';

//                 // Find the current product and set its type

//                 if (product.productId === productId) {
//                     currentProductType = product.productType;

//                     setHeaderData({
//                         logo: product?.exploreImage,
//                         title: product?.name || "",
//                         rating: product?.rating || 4.5,
//                         ratingCount: product?.ratingCount || '330k',
//                         category: product?.category || '',
//                         ageRating: product?.additionalInfo?.audience?.age[0],
//                         desc: product?.description || ''
//                     })

//                     setProduct(product);
//                     setmediaData(product?.mediaLinks.map((media: any) => media.url))
//                     setbannerImg(product?.bannerImage)

//                     setinfoSectionData({
//                         title: "Why You'll Like this Game?",
//                         // data: product?.productInfo?.Feature?.Features,
//                         data: [],
//                         linkPath: ""
//                     })

//                     setadditionalInfoData({
//                         publisher: "",
//                         genres: product?.subCategory || "",
//                         category: product?.category || "",
//                         productSize: "",
//                         ageRating: product?.additionalInfo?.audience?.age[0],
//                         support: {
//                             phoneNumber: product?.additionalInfo?.supportInfo["PHONE_NUMBER"],
//                             website: product?.additionalInfo?.supportInfo["WEBSITE"],
//                             email: product?.additionalInfo?.supportInfo["EMAIL"]
//                         },
//                         compatibility: {
//                             devices: product?.additionalInfo?.productComability?.map((dev: string) => dev),
//                             requirements: []
//                         }
//                     })

//                     setCreatedOn(product?.createdOn);
//                     setTags(product?.tags);
//                     setWebsite(product?.websiteLink);
//                     setCreator(product?.createdBy);
//                     setProductType(currentProductType);
//                     // setWishlisted(true);
//                 }

//                 axios
//                     .get(`https://api.lusso.dev/api/v1/similarProducts?productId=${productId}&productType=${currentProductType}`, {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                         },
//                     })
//                     .then(response => {
//                         setSimilarProducts(response.data.products);
//                         setLoading(false);
//                     });
//             })
//             .catch(error => {
//                 console.log('error', error);
//                 setLoading(false);
//             });
//     };

//     const [starDistribution, setStarDistribution] = useState([
//         { stars: 5, percentage: 70 },
//         { stars: 4, percentage: 20 },
//         { stars: 3, percentage: 5 },
//         { stars: 2, percentage: 3 },
//         { stars: 1, percentage: 2 },
//     ]);

//     const ratingData: RatingSectionProps = {
//         starDistribution: starDistribution,
//         rating: 4.5,
//         reviewTitle: "App is one of the best",
//         reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
//         reviewerName: "Ben Cutting",
//         reviewDate: "8/27/2024"
//     };

//     // const additionalInfoData = {
//     //     publisher: "Netflix, Inc.",
//     //     genres: "Entertainment",
//     //     category: "Apps/Entertainment",
//     //     productSize: "50 GB",
//     //     ageRating: "PG-18",
//     //     support: {
//     //         phoneNumber: "+18667160414",
//     //         email: "iosappstore@netflix.com"
//     //     },
//     //     compatibility: {
//     //         devices: [
//     //             "iPhone",
//     //             "iPad",
//     //             "iPod touch"
//     //         ],
//     //         requirements: [
//     //             "Requires iOS 12.0 or later.",
//     //             "Requires iPadOS 12.0 or later."
//     //         ]
//     //     }
//     // };

//     // init

//     useEffect(() => {
//         GetGameData()
//     }, [productId]);


//     // render
//     return (
//         <div className="alignCenter bg-blurred-new text-white min-h-screen">
//             {/* <MainBanner /> */}
//             <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />

//             <div className='content-new'>
//                 <div className="lg:max-w-[80%] mx-auto py-3 md:px-4 md:py-8">
//                     {/* <Header headerData={headerData} /> */}
//                     <div className="flex flex-col md:flex-row md:space-x-8">
//                         <MainContent
//                             selectedOption={selectedOption}
//                             setSelectedOption={setSelectedOption}
//                             headerData={headerData}
//                             ratingData={ratingData}
//                             screenshotData={mediaData}
//                             infoSectionData={infoSectionData}
//                             additionalInfoData={additionalInfoData}
//                         />
//                         <Sidebar />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const MainContent = (props: any) => (
//     <div className="w-full md:w-2/3">
//         <Screenshots
//             screenshotData={props?.screenshotData}
//             title={'Screenshots'} />

//         <AvailableAt
//             selectedOption={props?.selectedOption}
//             setSelectedOption={props?.setSelectedOption} />

//         <DescriptionSection desc={props.headerData?.desc} lineLimit={5} />

//         <InfoSection
//             data={props?.infoSectionData?.data}
//             title={props?.infoSectionData?.title}
//             linkPath={props?.infoSectionData?.linkPath}
//         />

//         <AdditionalInfo infoData={props?.additionalInfoData} />

//         <RatingSection ratingData={props?.ratingData} />

//         <div className='md:hidden my-10 px-3'>
//             <CategorySection title="PEOPLE ALSO VIEW" cards={topTrendingData} category={'Courses'} />
//         </div>

//         <div className='mb-5 px-3'>
//             <CategorySection title="Top Rated" cards={appsData} category={'Courses'} />
//         </div>
//     </div>
// );


// const Sidebar = () => (
//     <div className="hidden w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10">
//         <div className='bg-[#161328] py-10 px-8'>
//             <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
//                 PEOPLE ALSO VIEW
//                 <ChevronRight size={25} />
//             </h2>
//             <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
//                 {games.slice(0, 5).map((app, index) => (
//                     <ProductListCard
//                         key={index}
//                         game={app}
//                         bg={'#262242'}
//                         isLarge={true}
//                     />
//                 ))}
//             </div>
//         </div>

//         <div className='bg-[#161328] py-10 px-8'>
//             <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
//                 Top Rated
//                 <ChevronRight size={25} />
//             </h2>
//             <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
//                 {games.slice(6, 9).map((app, index) => (
//                     <ProductListCard
//                         key={index}
//                         game={app}
//                         bg={'#262242'}
//                     />
//                 ))}
//             </div>
//         </div>

//     </div>
// );

// export default GameDetailsScreen