import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface VisitorDataType {
    day: string;
    Facebook: number;
    Instagram: number;
    Twitter: number;
}

interface PostViewChartProps {
    heading?: string; // Marked as optional
    data: VisitorDataType[];
}

const PostViewChart: React.FC<PostViewChartProps> = ({ heading, data }) => {
    return (
        <div className="card-bg-dev p-6 shadow-lg w-full rounded-2xl space-y-3 text-white">
            {heading && <h2>{heading}</h2>}  {/* Conditionally render heading if it exists */}
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid vertical={false}
                        horizontal={true}
                        stroke="#FFFFFF33"
                        strokeDasharray="none" />
                    <XAxis dataKey="day" axisLine={false} tickLine={false} tick={{ fill: '#fff' }} />
                    <YAxis type='number' axisLine={false} tickLine={false} tick={{ fill: '#fff' }} />
                    <Legend verticalAlign="top" align="right" layout="horizontal" style={{ marginBottom: '20px' }} />
                    <Line strokeWidth={4} type="monotone" dataKey="Facebook" stroke="#8884d8" activeDot={{ r: 8 }} dot={false} />
                    <Line strokeWidth={4} type="monotone" dataKey="Instagram" stroke="#82ca9d" dot={false} />
                    <Line strokeWidth={4} type="monotone" dataKey="Twitter" stroke="#ffc658" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};


export default PostViewChart;
