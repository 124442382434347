import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Rectangle,
    ResponsiveContainer,
    XAxis,
    YAxis
} from 'recharts';

const BarChartStat = ({ title = '', data = [] }: { title: string, data: any[] }) => {
    return (
        <div className="stats-section-bg p-4 card-bg-dev rounded-2xl text-white h-full">
            <ResponsiveContainer width="100%" height='100%'>
                <BarChart
                    layout="vertical"
                    width={500}
                    data={data}
                    barCategoryGap={20}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient id="femaleGrad" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0.45%" stopColor="#B00D98" />
                            <stop offset="98.74%" stopColor="#FF5EE5" />
                        </linearGradient>
                        <linearGradient id="maleGrad" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="14.16%" stopColor="#006EF8" />
                            <stop offset="69.49%" stopColor="#212CB1" />
                            <stop offset="85.41%" stopColor="#2B189C" />
                        </linearGradient>
                    </defs>
                    <text x={20} y={20} fontSize={20} fill="#ffff">
                        {title ?? ''}
                    </text>
                    <CartesianGrid
                        vertical={true}
                        horizontal={false}
                        stroke="#FFFFFF33"
                    />
                    <XAxis type="number" axisLine={false} tickLine={false} tick={{ fill: '#fff' }} />
                    <YAxis
                        dataKey="name"
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={20}
                        tick={{ fill: '#fff' }}
                    />
                    <Legend verticalAlign="top" align="right" layout="horizontal" style={{ marginBottom: '20px' }} />
                    <Bar
                        dataKey="Male"
                        fill="url(#maleGrad)"
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                    // barSize={20}
                    />
                    <Bar
                        dataKey="Female"
                        fill="url(#femaleGrad)"
                        activeBar={<Rectangle fill="gold" stroke="purple" />}
                    // barSize={20}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartStat;
