import "./dev.scss";

import { useState } from "react";
import commentIcon from "../../assets/images/dev/chat.svg";
import engageIcon from "../../assets/images/dev/eye.svg";
import likeIcon from "../../assets/images/dev/like.svg";
import reachImg1 from '../../assets/images/dev/reach-1.png';
import reachImg2 from '../../assets/images/dev/reach-2.png';
import reachImg3 from '../../assets/images/dev/reach-3.png';
import shareIcon from "../../assets/images/dev/share.svg";
import facebook from "../../assets/images/social/fb.svg";
import instagram from "../../assets/images/social/insta.svg";
import linkedin from "../../assets/images/social/ln.svg";
import twitter from "../../assets/images/social/twitter.svg";
import { ICON_ENUM } from "../../constants/icons.constant";
import BarChartStat from "./BarChartStats";
import MapStats from "./MapStats";
import PostViewChart from "./PostViewChart";
import SocialMediaCards from "./SocialMediaCards";
import BarTilesAndGraph from "./socialAnalytics/Components/BarTilesAndGraph";
import DonutStats from "./socialAnalytics/Components/DonutStats";

export interface VisitorDataType {
    day: string;
    Facebook: number;
    Instagram: number;
    Twitter: number;
}

const tilesStats = [
    {
        title: 'Facebook',
        headerIcon: ICON_ENUM?.FACEBOOK?.icon,
        borderColor: ICON_ENUM?.FACEBOOK?.borderColor,
        count: '1852',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Youtube',
        headerIcon: ICON_ENUM?.YOUTUBE?.icon,
        borderColor: ICON_ENUM?.YOUTUBE?.borderColor,
        count: '198',
        bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Instagram',
        headerIcon: ICON_ENUM?.INSTAGRAM?.icon,
        borderColor: ICON_ENUM?.INSTAGRAM?.borderColor,
        count: '214',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'X',
        headerIcon: ICON_ENUM?.X?.icon,
        borderColor: ICON_ENUM?.X?.borderColor,
        count: '167',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'LinkedIn',
        headerIcon: ICON_ENUM?.LINKEDIN?.icon,
        borderColor: ICON_ENUM?.LINKEDIN?.borderColor,
        count: '1852',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Tiktok',
        headerIcon: ICON_ENUM?.TIKTOK?.icon,
        borderColor: ICON_ENUM?.TIKTOK?.borderColor,
        count: '142',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Twitch',
        headerIcon: ICON_ENUM?.TWITCH?.icon,
        borderColor: ICON_ENUM?.TWITCH?.borderColor,
        count: '715',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Behance',
        headerIcon: ICON_ENUM?.BEHANCE?.icon,
        borderColor: ICON_ENUM?.BEHANCE?.borderColor,
        count: '98',
        bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
        text: '+8.2k increase/day',
    },
];

const lineChartDataSet = {
    configuration: {
        gradientStroke: {
            likes: [
                { offset: '8.66%', stopColor: '#1C36B7' },
                { offset: '90.78%', stopColor: '#1C98D3' },
            ],
            followers: [
                { offset: '14.6%', stopColor: '#FF0000' },
                { offset: '85.41%', stopColor: '#9B0000' },
            ],
            impressions: [
                { offset: '13.8%', stopColor: '#5342D6' },
                { offset: '18.4%', stopColor: '#7739C6' },
                { offset: '25.1%', stopColor: '#A52DB0' },
                { offset: '28.46%', stopColor: '#B729A8' },
                { offset: '37.01%', stopColor: '#CE257E' },
                { offset: '47.52%', stopColor: '#E82250' },
                { offset: '52.8%', stopColor: '#F2203E' },
                { offset: '65.79%', stopColor: '#F2203E' },
                { offset: '67.35%', stopColor: '#F32D40' },
                { offset: '75.25%', stopColor: '#F86C48' },
                { offset: '81.93%', stopColor: '#FB994E' },
                { offset: '87.06%', stopColor: '#FDB652' },
                { offset: '90.03%', stopColor: '#FEC053' },
            ],
        },
        lines: [
            {
                dataKey: 'likes',
                stroke: 'url(#likes)',
            },
            {
                dataKey: 'followers',
                stroke: 'url(#followers)',
            },
            {
                dataKey: 'impressions',
                stroke: 'url(#impressions)',
            },
            {
                dataKey: 'pageVisits',
            },
        ],
    },
    dataSet: [
        {
            date: 'Aug 8',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 18',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 28',
            likes: 198,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 8',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 18',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 28',
            likes: 205,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
    ],
};

const visitorData: VisitorDataType[] = [
    { day: 'M', Facebook: 40000, Instagram: 10000, Twitter: 20000 },
    { day: 'T', Facebook: 60000, Instagram: 30000, Twitter: 25000 },
    { day: 'W', Facebook: 45000, Instagram: 25000, Twitter: 22000 },
    { day: 'T', Facebook: 30000, Instagram: 20000, Twitter: 18000 },
    { day: 'F', Facebook: 45000, Instagram: 25000, Twitter: 22000 },
    { day: 'S', Facebook: 50000, Instagram: 30000, Twitter: 60000 },
    { day: 'S', Facebook: 40000, Instagram: 20000, Twitter: 25000 },
];
const DashBoard = () => {
    const users = [
        { image: reachImg1 },
        { image: reachImg2 },
        { image: reachImg3 },
    ];
    const postMetrics = [
        {
            icon: likeIcon,
            label: 'Likes',
            day: '1.8k day',
            month: '105.8k month',
        },
        {
            icon: commentIcon,
            label: 'Comments',
            day: '1.8k day',
            month: '105.8k month',
        },
        {
            icon: shareIcon,
            label: 'Share',
            day: '1.8k day',
            month: '105.8k month',
        },
        {
            icon: engageIcon,
            label: 'Engagement',
            day: '1.8k day',
            month: '105.8k month',
        },
    ];

    const recentPosts = [
        {
            platformIcon: facebook,
            title: 'Course',
            time: 'Today at 04:30 PM',
            reach: '1.8k day',
            engagement: '1.8k day',
            impressions: '548',
        },
        {
            platformIcon: instagram,
            title: 'Game',
            time: 'Today at 07:30 PM',
            reach: '1.8k day',
            engagement: '1.8k day',
            impressions: '15',
        },
        {
            platformIcon: twitter,
            title: 'Service',
            time: 'Today at 07:30 PM',
            reach: '1.8k day',
            engagement: '1.8k day',
            impressions: '13',
        },
        {
            platformIcon: linkedin,
            title: 'Service',
            time: 'Today at 07:30 PM',
            reach: '1.8k day',
            engagement: '1.8k day',
            impressions: '8',
        },
    ];

    const postMetricsLists = [
        {
            icon: facebook,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: instagram,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: facebook,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: instagram,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: facebook,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: instagram,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: facebook,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
        {
            icon: instagram,
            content: 'Transfer your digital dreams into reality with..',
            impressions: 548
        },
    ];

    const clickData = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 10, 0, 0, 0],
        [0, 0, 10, 100, 10, 0, 0],
        [0, 10, 100, 250, 100, 10, 0],
        [0, 0, 10, 100, 10, 0, 0],
        [0, 0, 0, 10, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
    ];

    const [metrics, setMetrics] = useState({
        impressions: 2010,
        clicks: 201,
        ctr: 27.5,
        cpc: 1.34,
    });

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col md:flex-row gap-5">
                <div className="flex flex-col w-full gap-5 text-white">
                    {/* Social Cards */}
                    <SocialMediaCards />

                    <PostViewChart heading="Post Views in Period" data={visitorData} />

                    <h2 className="text-2xl">Social analytics</h2>
                    <BarTilesAndGraph title='Followers' data={{ tiles: tilesStats, lineChartDataSet }} />
                    <MapStats
                        title="Followers by country"
                        data={{
                            totalUsers: 2431340,
                            activeUsers: 92980,
                            newUsers: 92980,
                            activeUsersPercentage: 27,
                            newUsersPercentage: 63,
                            locations: [
                                {
                                    flag: ICON_ENUM?.USA?.icon,
                                    name: 'United States',
                                    lat: 41.8781,
                                    lng: -87.6298,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                                {
                                    flag: ICON_ENUM?.ROMANIA?.icon,
                                    name: 'Romania',
                                    lat: 52.52,
                                    lng: 13.405,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                                {
                                    flag: ICON_ENUM?.CANADA?.icon,
                                    name: 'Canada',
                                    lat: 31.2304,
                                    lng: 121.4737,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                                {
                                    flag: ICON_ENUM?.INDIA?.icon,
                                    name: 'India',
                                    lat: -3.119,
                                    lng: -60.0217,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                                {
                                    flag: ICON_ENUM?.UK?.icon,
                                    name: 'United Kingdom',
                                    lat: -20.9176,
                                    lng: 142.7028,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                                {
                                    flag: ICON_ENUM?.ITALY?.icon,
                                    name: 'Italy',
                                    lat: -17.9176,
                                    lng: 102.7028,
                                    value: '55,750',
                                    percentage: '27.3',
                                },
                            ],
                        }}
                    />

                    <div className="flex flex-col lg:flex-row gap-5">
                        <div className="w-full lg:w-2/3">
                            <BarChartStat title='Followers by age' data={[
                                {
                                    name: '13-17',
                                    Male: 55,
                                    Female: 65,
                                },
                                {
                                    name: '18-24',
                                    Male: 80,
                                    Female: 70,
                                },
                                {
                                    name: '25-34',
                                    Male: 85,
                                    Female: 95,
                                },
                                {
                                    name: '35-44',
                                    Male: 55,
                                    Female: 45,
                                },
                                {
                                    name: '45++',
                                    Male: 55,
                                    Female: 38,
                                }
                            ]} />
                        </div>
                        <div className="w-full lg:w-1/3">
                            <DonutStats
                                title="Statistic by gender"
                                viewOption={false}
                                data={{
                                    gradient: {
                                        id: 'customGradient',
                                        list: [
                                            { offset: '0.45%', stopColor: '#B00D98' },
                                            { offset: '98.74%', stopColor: '#FF5EE5' },
                                        ],
                                    },
                                    dataSet: [
                                        { name: 'Link', value: 40, color: '#007DFE' },
                                        {
                                            name: 'Merge',
                                            value: 60,
                                            color: 'url(#customGradient)',
                                        },
                                    ],
                                }}
                            /></div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default DashBoard;