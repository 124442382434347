import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import primaryImageIcon from "../../assets/images/products/imageUpload.png";
import uploadIcon from "../../assets/images/products/uploadIcon.png";
import videoUploadIcon from "../../assets/images/products/videUpload.png";
import { useStateContext } from "../../context/ContextProvider";


const MediaUpload: React.FC = () => {
    const context = useStateContext()
    const [primaryImage, setPrimaryImage] = useState<File | null>(null);
    const [trailerVideo, setTrailerVideo] = useState<File | null>(null);
    const [otherImages, setOtherImages] = useState<File[]>([]);

    const handleSingleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setImage: React.Dispatch<React.SetStateAction<File | null>>,
        mediaType: string
    ) => {
        const file = e.target.files?.[0];
        if (file) {
            setImage(file);
            context?.setMediaLinks((prevFields) => [
                ...prevFields.filter(item => item.mediaType !== mediaType), // Remove existing media of the same type
                { mediaType, mediaSrc: file }
            ]);
        }
    };

    const handleMultipleFilesChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setImages: React.Dispatch<React.SetStateAction<File[]>>,
        mediaType: string
    ) => {
        const files = e.target.files;
        if (files) {
            const newFiles = Array.from(files);
            setImages((prevImages) => {
                const updatedImages = [...prevImages, ...newFiles];
                context?.setMediaLinks((prevFields) => [
                    ...prevFields.filter(item => item.mediaType !== mediaType), // Remove existing media of the same type
                    ...updatedImages.map(file => ({ mediaType, mediaSrc: file }))
                ]);
                return updatedImages;
            });
        }
    };

    const handleRemoveImage = (
        index: number | null,
        setImage: React.Dispatch<React.SetStateAction<File | null>> | React.Dispatch<React.SetStateAction<File[]>>,
        mediaType: string
    ) => {
        if (index === null) {
            // Handling single image case
            if (setImage instanceof Function) {
                (setImage as React.Dispatch<React.SetStateAction<File | null>>)(null);
            }
            context?.setMediaLinks((prevFields) =>
                prevFields.filter(item => item.mediaType !== mediaType)
            );
        } else {
            // Handling multiple images case
            if (setImage instanceof Function) {
                setOtherImages((prevImages) => {
                    const updatedImages = prevImages.filter((_, i) => i !== index);

                    context?.setMediaLinks((prevFields) => [
                        ...prevFields.filter(item => item.mediaType !== mediaType), // Remove existing media of the same type
                        ...updatedImages.map(file => ({
                            mediaType,
                            mediaSrc: file
                        }))
                    ]);

                    return updatedImages;
                });
            }
        }
    };


    return (
        <div className="space-y-4">
            {/* Primary Image */}
            <div className="flex flex-col justify-start items-start w-auto gap-4">
                <p className="text-white text-[18px] font-semibold">Primary Image</p>
                <label
                    htmlFor="primary-image-upload"
                    className="relative flex items-center justify-center cursor-pointer p-5 bg-gradient-to-r from-[#380c95] to-[#341a5d] rounded-lg"
                >
                    {primaryImage ? (
                        <div className="">
                            <img src={URL.createObjectURL(primaryImage)} alt="Primary" className="w-14 rounded" />
                            <ImCross className="absolute top-0 right-0 bg-white rounded-full p-1 text-red-700 cursor-pointer" onClick={() => handleRemoveImage(null, setPrimaryImage, 'banner')} />
                        </div>
                    ) : (
                        <img src={primaryImageIcon} alt="" className="w-14" />
                    )}
                    <input
                        id="primary-image-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleSingleFileChange(e, setPrimaryImage, 'banner')}
                    />
                </label>
            </div>

            {/* Trailer */}
            <div className="flex flex-col justify-start items-start w-auto gap-4">
                <p className="text-white text-[18px] font-semibold">Trailer</p>
                <div className="flex gap-6 items-end">
                    <label
                        htmlFor="trailer-video-upload"
                        className="relative flex items-center justify-center cursor-pointer px-5 py-[30px] bg-gradient-to-r from-[#380c95] to-[#341a5d] rounded-lg"
                    >
                        {trailerVideo ? (
                            <div>
                                <video src={URL.createObjectURL(trailerVideo)} className="w-14 rounded" />
                                <ImCross
                                    className="absolute top-0 right-0 bg-white rounded-full p-1 text-red-700 cursor-pointer"
                                    onClick={() => handleRemoveImage(null, setTrailerVideo, 'trailerVideo')}
                                />
                            </div>
                        ) : (
                            <img src={videoUploadIcon} alt="" className="w-14" />
                        )}
                        <input
                            id="trailer-video-upload"
                            type="file"
                            className="hidden"
                            accept="video/*"
                            onChange={(e) => handleSingleFileChange(e, setTrailerVideo, 'trailerVideo')}
                        />
                    </label>
                    <label
                        htmlFor="dropzone-file"
                        className="flex items-center justify-center cursor-pointer p-5 bg-gradient-to-r from-[#380c95] to-[#341a5d] rounded-lg"
                    >
                        <img src={primaryImageIcon} alt="" className="w-14" />
                        <input id="dropzone-file" type="file" className="hidden " accept="image/*" />
                    </label>
                    <div className="flex items-center justify-center p-4 bg-gradient-to-r from-[#380c95] to-[#341a5d] rounded-lg">
                        <img src={uploadIcon} alt="" className="w-5" />
                    </div>
                </div>
            </div>

            {/* Other Product Images */}
            <div className="flex flex-col justify-start items-start w-auto gap-4">
                <p className="text-white text-[18px] font-semibold">Other Product Images</p>
                <div className="flex gap-6 items-end">
                    <label
                        htmlFor="other-image-upload"
                        className="relative flex items-center justify-center cursor-pointer p-5 bg-gradient-to-r from-[#380c95] to-[#341a5d] rounded-lg"
                    >
                        <img src={primaryImageIcon} alt="" className="w-14" />
                        <input
                            id="other-image-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleMultipleFilesChange(e, setOtherImages, 'media')}
                        />
                    </label>
                </div>
                <div className="flex gap-4 flex-wrap">
                    {otherImages.map((image, index) => (
                        <div key={index} className="relative">
                            <img src={URL.createObjectURL(image)} alt={`Other ${index + 1}`} className="w-14 rounded" />
                            <ImCross
                                className="absolute top-0 right-0 bg-white rounded-full p-1 text-red-700 cursor-pointer"
                                onClick={() => handleRemoveImage(index, setOtherImages, 'media')}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MediaUpload;
