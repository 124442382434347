import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { scrollLeft, scrollRight } from '../../../../hooks/common.utils';
import { enableHorizontalScroll } from '../../../../lib/ScrollHelper';
import { LineDraw } from '../GameDetailsScreen';
import { Dialog, DialogContent, DialogTrigger } from "./UiDialouge";

function Screenshots({ screenshotData, title }: { screenshotData: string[] | any, title: string }) {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [error, setError] = useState(false);

    const scrollRef = useRef<HTMLDivElement | null | any>(null);

    useEffect(() => {
        const cleanup = enableHorizontalScroll(scrollRef.current);

        return () => {
            if (cleanup) cleanup();
        };
    }, []);

    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(true);

    const checkScrollPosition = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setShowLeft(scrollLeft > 0);
            setShowRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        const handleScroll = () => checkScrollPosition();

        const currentScrollRef = scrollRef.current;
        currentScrollRef.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    return (
        <div className="mb-3 bg-[#161328] rounded-2xl p-5 relative">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl md:text-3xl font-bold mb-4 capitalize">{title}</h2>
                <span className="md:hidden flex gap-2 bg-clip-text text-transparent bg-gradient-to-r from-[#985FFF] to-[#FF99EF]">
                    See all
                    <ChevronRight color='#985FFF' />
                </span>
            </div>

            <LineDraw />

            <div className="relative">
                {/* Left scroll control */}
                {showLeft && (
                    <button
                        onClick={() => scrollLeft(scrollRef)} // Add click event for left scroll
                        className="z-20 absolute -left-3 md:-left-5 top-1/2 transform -translate-y-1/2 bg-[#5721B9] h-9 w-9 rounded-full ps-1"
                        style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
                        <ChevronLeft color='#00F0FB' />
                    </button>
                )}

                {/* Right scroll control */}
                {showRight && (
                    <button
                        onClick={() => scrollRight(scrollRef)} // Add click event for right scroll
                        className="z-20 absolute -right-3 md:-right-5 top-1/2 transform -translate-y-1/2 bg-[#5721B9] h-9 w-9 rounded-full ps-1.5"
                        style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
                        <ChevronRight color='#00F0FB' />
                    </button>
                )}

                <div className="flex overflow-x-auto space-x-4 pb-2 scrollbar-none" ref={scrollRef}>
                    {screenshotData?.map((file: string | any, i: any) => (
                        <Dialog key={i}>
                            <DialogTrigger asChild>
                                {file.video ? (
                                    <div>
                                        <div className="relative w-[30rem] h-[15rem] cursor-pointer group">
                                            <img
                                                src={file.img}
                                                alt={`Screenshot ${i + 1}`}
                                                className="w-[30rem] h-[15rem] object-cover"
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center z-99
                                            bg-black bg-opacity-20 opacity-100 transition-opacity duration-300">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                                                    <path opacity="0.8" d="M26.4177 4.63672C22.089 4.63672 17.8574 5.92034 14.2582 8.32525C10.659 10.7302 7.8538 14.1483 6.19726 18.1476C4.54073 22.1468 4.10731 26.5474 4.9518 30.793C5.79629 35.0385 7.88077 38.9383 10.9416 41.9992C14.0025 45.0601 17.9023 47.1445 22.1479 47.989C26.3934 48.8335 30.794 48.4001 34.7933 46.7436C38.7925 45.087 42.2107 42.2818 44.6156 38.6826C47.0205 35.0834 48.3041 30.8519 48.3041 26.5231C48.3041 23.649 47.738 20.803 46.6381 18.1476C45.5382 15.4922 43.9261 13.0794 41.8937 11.0471C39.8614 9.01476 37.4486 7.40262 34.7933 6.30272C32.1379 5.20283 29.2918 4.63672 26.4177 4.63672ZM22.0404 36.372V16.6743L35.1722 26.5231L22.0404 36.372Z" fill="#F2F2F2" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <img
                                        src={file}
                                        alt={`Screenshot ${i + 1}`}
                                        className="w-[30rem] h-[15rem] object-cover cursor-pointer"
                                    />
                                )}
                            </DialogTrigger>
                            <DialogContent className="max-w-7xl">
                                {file.video ? (
                                    <video src={file.video} controls className="w-full max-h-[80vh] object-contain" />
                                ) : (
                                    <img src={file} alt={`Screenshot ${i + 1}`} className="w-full max-h-[80vh] object-contain" />
                                )}
                            </DialogContent>
                        </Dialog>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Screenshots;