export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
};

export const apiEndpoints = {
  chatBot: '/chat-bot',
  socialSignin: {
    endpoint: '/v1/analytics/brand/getSocialSigninPage/4065887?platformName=',
    method: API_METHOD.POST,
    payload: '',
  },
  linkedInCompanies: {
    endpoint: '/v1/analytics/brand/getLinkedInCompanies/',
    method: API_METHOD.POST,
    payload: '',
  },
  addLinkedinPage: {
    endpoint: '/v1/analytics/brand/addLinkedinPage/',
    method: API_METHOD.POST,
    payload: '',
  },
  createBrand: {
    endpoint: '/v1/analytics/brand/create-brand',
    method: API_METHOD.GET,
  },
  updateBrandName: {
    endpoint:
      '/v1/analytics/brand/update-brand-name-image',
    method: API_METHOD.POST,
    params: {
      path: { brandId: '' },
      query: { name: '', image: '' }
    },
    payload: '',
  },
};
