import { Text, Timeline } from "@mantine/core";
import StoryImage from "../../assets/images/storyImage.svg";
const StorySection = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 py-6 gap-7">
            <div className="flex flex-col justify-center items-start">
                <span className="text-start self-start font-bold text-4xl mb-6" style={{ color: "#FFFFFF" }}>
                    Our Story
                </span>
                <Timeline active={1} bulletSize={24} lineWidth={2} color="#00FFFF">
                    <Timeline.Item
                        title={
                            <span className="font-bold text-4xl" style={{ color: "#FFFFFF" }}>
                                2024
                            </span>
                        }
                        bullet={<div className="w-6 h-6 bg[#00F0FB] rounded-full" />}
                    >
                        <hr className="w-full border-t-2 border-[#FFFFFF] my-4" style={{ color: "#FFFFFF" }} />
                        <Text className="font-normal text-xl" style={{ color: "#FFFFFF" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt
                        </Text>
                    </Timeline.Item>

                    <Timeline.Item
                        title={
                            <span className="font-bold text-4xl" style={{ color: "#FFFFFF" }}>
                                2022
                            </span>
                        }
                        bullet={<div className="w-6 h-6 bg[#00F0FB] rounded-full" />}
                    >
                        <hr className="w-full border-t-2 border-[#FFFFFF] my-4" />
                        <Text className="font-normal text-xl" style={{ color: "#FFFFFF" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt
                        </Text>
                    </Timeline.Item>

                    <Timeline.Item
                        title={
                            <span className="font-bold text-4xl" style={{ color: "#FFFFFF" }}>
                                2020
                            </span>
                        }
                        bullet={<div className="w-6 h-6 bg[#00F0FB] rounded-full" />}
                    >
                        <hr className="w-full border-t-2 border-[#FFFFFF] my-4" />
                        <Text className="font-normal text-xl" style={{ color: "#FFFFFF" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt
                        </Text>
                    </Timeline.Item>
                </Timeline>
            </div>
            <div className="flex flex-col justify-center gap-2">
                <img src={StoryImage} />
            </div>
        </div>
    );
};

export default StorySection;
