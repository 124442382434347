import React from 'react';
import { LineDraw } from '../GameDetailsScreen';

interface TeamMember {
    name: string;
    surname: string;
    role: string;
    imageUrl: string;
    url?: string
}

interface TeamMembersGridProps {
    members: TeamMember[];
    title?: string
}

const TeamMembersGrid: React.FC<TeamMembersGridProps> = ({ members, title = null }) => {
    return (
        <div className="mb-3 bg-[#161328] p-5 rounded-2xl">
            <h2 className="text-xl md:text-3xl font-bold mb-4 capitalize">{title || 'My team'}</h2>
            <LineDraw />
            <div className="flex gap-6 overflow-x-auto scrollbar-none">
                {members.map((member, index) => (
                    <div key={index} className="flex flex-col items-start">
                        <div className="relative w-full pb-[100%] rounded-2xl overflow-hidden mb-4 group">
                            <img
                                src={member.imageUrl || member.url}
                                alt={`${member.name} ${member.surname}`}
                                className="absolute top-0 left-0 w-full h-full object-cover transition-transform 
                                duration-300 ease-in-out group-hover:scale-110"
                            />
                        </div>
                        <h3 className="text-white text-lg md:text-xl font-semibold w-[150px] mb-1">{member.name + ' ' + member.surname}</h3>
                        <p className="text-[#B1ADCD] text-md">{member.role}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamMembersGrid;