import TitleBar from '../../components/common/TitleBar';
import NewlyAddedCard from "./NewlyAddedCard";

type CardData = {
    imageSrc: string;
    title: string;
};

type CategoryProps = {
    title: string;
    cards: CardData[];
};

const NewlyAddedCategory: React.FC<CategoryProps> = ({ title, cards }) => {
    return (
        <div className="category">
            <TitleBar title={title} isLeft={true} />
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        gap: 6,
                        overflowX: 'scroll',
                        WebkitOverflowScrolling: 'touch',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none'
                    }}
                >
                    {cards.map((card, index) => (
                        <div key={index} className="me-4">
                            <NewlyAddedCard card={card} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewlyAddedCategory;
