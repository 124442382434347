import { useState } from 'react';
import './settings.scss';

const Settings = () => {

    const [oldPassword, setOldPassword]:any = useState('');
    const [newPassword, setNewPassword]:any = useState('');
    const [ConfirmPassword, setConfirmPassword]:any = useState('');
    const [check, setCheck]:any = useState(false);
    return (
        <div style={{padding:4}}>
            <div>
                <div >
                    <span className='header-shadow'>Settings</span>
                </div>
                <div>
                    <div className="horizontal-divider-light mt-2 mb-4"></div>
                </div>
            </div>
            <div className='flex flex-row justify-between items-start settings-container'>
                <div className='flex flex-1 flex-col gap-4'>
                    <div>
                        <span className='header-shadow-small'>Notifications</span>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='sub-header-shadow'>General Notifications</span>
                        <div className='flex flex-col gap-3'>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                    Receive notifications for important updates and announcements.
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                    Receive notifications for important updates and announcements.
                                </p>
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='sub-header-shadow'>Content Recommendations:</span>
                        <div className='flex flex-col gap-3'>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Receive personalized recommendations based on your viewing or browsing history.
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Get notified when new content is added to your favorite categories or genres.
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Get alerts for mentions of your username or handle in public posts.
                                </p>
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='sub-header-shadow'>Account Security:</span>
                        <div className='flex flex-col gap-3'>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Receive notifications for account activity alerts (e.g., suspicious login attempts).
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Receive alerts for successful login attempts from new devices or locations.
                                </p>
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='sub-header-shadow'>Billing and Subscription:</span>
                        <div className='flex flex-col gap-3'>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Receive notifications for upcoming subscription renewals and payment reminders.
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Get alerts for changes in subscription pricing or plan upgrades.
                                </p>
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='sub-header-shadow'>Feedback and Surveys:</span>
                        <div className='flex flex-col gap-3'>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Get alerted to participate in user research studies or usability testing.
                                </p>
                            </label>
                            <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                <input
                                    checked={check}
                                    onChange={(e) => setCheck(!check)}
                                    type="checkbox"
                                    className="hidden"
                                />
                                <span
                                    className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                    style={{borderRadius: 4}}
                                ></span>
                                <p className="text-[#FFF] font-normal text-[12px]">
                                Get notified when your feedback leads to changes or improvements on the platform.
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='flex flex-1 flex-col'>
                    <div className='flex flex-col'>
                        <div>
                            <span className='header-shadow-small'>Change Password</span>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <span className="text-white font-normal text-[12px]">
                                Current Password
                                </span>
                                <input
                                type="text"
                                autoComplete="off"
                                placeholder="Password"
                                className="settings-frm-input badge h-[50px]"
                                value={oldPassword || ''}
                                style={{
                                    borderRadius: 8,
                                    border: `1px solid #A768FD`,
                                    background: 'rgba(4, 4, 4, 0.20)',
                                    marginTop: 5,
                                }}
                                onChange={e => {
                                    setOldPassword(e.target.value);
                                }}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <span className="text-white font-normal text-[12px]">
                                New Password
                                </span>
                                <input
                                type="text"
                                autoComplete="off"
                                placeholder="New Password"
                                className="settings-frm-input badge h-[50px]"
                                value={oldPassword || ''}
                                style={{
                                    borderRadius: 8,
                                    border: `1px solid #A768FD`,
                                    background: 'rgba(4, 4, 4, 0.20)',
                                    marginTop: 5,
                                }}
                                onChange={e => {
                                    setOldPassword(e.target.value);
                                }}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <span className="text-white font-normal text-[12px]">
                                Confirm Password
                                </span>
                                <input
                                type="text"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                className="settings-frm-input badge h-[50px]"
                                value={oldPassword || ''}
                                style={{
                                    borderRadius: 8,
                                    border: `1px solid #A768FD`,
                                    background: 'rgba(4, 4, 4, 0.20)',
                                    marginTop: 5,
                                }}
                                onChange={e => {
                                    setOldPassword(e.target.value);
                                }}
                                />
                            </label>
                        </div>
                        <div>
                            <button
                                className="saveContinue mt-3"
                                style={{
                                borderRadius: 8,
                                border: '1px solid #A768FD',
                                background:
                                    'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                textTransform: 'capitalize',
                                }}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col mt-8'>
                        <div>
                            <span className='header-shadow-small'>Delete Account</span>
                        </div>
                        <div className='mt-3'>
                            <span className="text-white font-normal text-[12px]">
                            Permanently remove your account and all associated data from the platform. Please note that this action is irreversible and will result in the loss of all account-related content and information.
                            </span>
                        </div>
                        <div>
                            <button
                                className="saveContinue mt-3"
                                style={{
                                borderRadius: 8,
                                border: '1px solid #A768FD',
                                background:
                                    'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                textTransform: 'capitalize',
                                }}
                            >
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;