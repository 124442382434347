import { useNavigate } from 'react-router-dom';
import ACCESSLUSSO from '../../assets/images/ACCESSLUSSO.png';
import ChevronRightWhite from '../../assets/images/ChevronRight.png';
import greenTick from '../../assets/images/greenTick.png';
import ChevronRight from '../../assets/images/subscription-chev-right.png';
import Visa from '../../assets/images/Visa.png';

const Purchased = () => {
    const navigate = useNavigate();
    const payments = [
        {
            date: "10/12",
            paymentMethod: "****9519",
            type: "Standard (monthly)",
            amount: "$199.00",
        },
        {
            date: "10/12",
            paymentMethod: "****9519",
            type: "Standard (monthly)",
            amount: "$199.00",
        },
        {
            date: "10/12",
            paymentMethod: "****9519",
            type: "Standard (monthly)",
            amount: "$199.00",
        },
        {
            date: "10/12",
            paymentMethod: "****9519",
            type: "Standard (monthly)",
            amount: "$199.00",
        },
        {
            date: "10/12",
            paymentMethod: "****9519",
            type: "Standard (monthly)",
            amount: "$199.00",
        },

        // Add more objects if needed
    ];
    return (
        <>
            <div className='text-white font-bold text-[24px] mb-8'>
                <span className='bg-gradient-to-r from-[#985FFF] to-[#FF99EF] bg-clip-text text-transparent'>
                    Subscription
                </span>
            </div>


            <div className='flex space-x-4'>
                {/* Left Column */}
                <div>
                    <div className='mb-8'>
                        <div className='text-white font-semibold text-[18px] mb-4 flex justify-between items-center'>
                            <span>Current Plan</span>

                            {/* New label with chevron */}
                            <div className='pr-2 flex items-center space-x-2 cursor-pointer'>
                                <span className='text-white font-semibold text-[18px]'>
                                    Update Plan
                                </span>
                                <img
                                    src={ChevronRight} // Update with your actual path
                                    alt='Chevron Right'
                                    className='w-2 h-3'
                                />
                            </div>
                        </div>

                        <div className="w-[865px] h-[199px] border-gradient p-4 rounded-lg" style={{ borderRadius: '20px' }}>
                            <div className="w-[830px] h-[150px] mt-2 p-4 rounded-lg" style={{ borderRadius: '20px' }}>
                                <div className="w-[253px] h-[44px] flex items-center justify-center" style={{
                                    background: 'linear-gradient(115.33deg, #006EF8 20.95%, #212CB1 72.86%, #2B189C 88.07%)',
                                    borderRadius: '20px',  // Added borderRadius for the inner div
                                }}>
                                    <span className='text-white'>Plan active since: May 1, 2024</span>
                                </div>
                                <div className="w-[800px] h-[66px]  mt-8 flex items-center justify-between p-4 rounded-lg" style={{ borderRadius: '20px' }}>
                                    <img
                                        src={ACCESSLUSSO} // Update with your actual path
                                        alt='ACCESSLUSSO'
                                        className='w-[171px] h-[50px]'
                                    />
                                    <div className="text-right">
                                        <span className="text-2xl font-semibold" style={{ color: '#5B97FF' }}>$99.99</span>
                                        <span className="text-2xl " style={{ color: 'white' }}>/mon</span>
                                        <div className="text-sm" style={{ color: 'white' }}>Free 7-day trial</div>
                                    </div>
                                </div>


                            </div>

                        </div>


                    </div>

                    {/* Plan Benefits and Payment History Section */}
                    <div className='flex space-x-2'>
                        <div className='space-y-8'>
                            <div className='text-white text-[24px] mb-6'>
                                <span>Plan Benefits</span>
                            </div>
                            <div className="w-[290px] h-[545px] border-gradient p-4 rounded-lg">
                                <img
                                    src={ACCESSLUSSO} // Update with your actual path
                                    alt='ACCESSLUSSO'
                                    className='w-[171px] h-[50px] mt-2'
                                />
                                <hr className="mt-6" style={{ color: 'grey', borderColor: 'grey' }} />
                                <div className='mt-12 w-[260px] h-[220px]'>
                                    <div className="w-[300px] h-[70px] flex items-center p-2">
                                        <img
                                            src={greenTick} // Update with your actual path
                                            alt="green tick"
                                            className="w-[14px] h-[8px] mr-3 mb-6"
                                        />
                                        <span className="text-[sm] text-white">Includes 50 digital products and completely customizable</span>
                                    </div>
                                    <div className="w-[300px] h-[70px] flex items-center p-2">
                                        <img
                                            src={greenTick} // Update with your actual path
                                            alt="green tick"
                                            className="w-[14px] h-[8px] mr-3 mb-6"
                                        />
                                        <span className="text-[sm] text-white">Perfect for small projects and individual use.</span>
                                    </div>
                                    <div className="w-[300px] h-[70px] flex items-center p-2">
                                        <img
                                            src={greenTick} // Update with your actual path
                                            alt="green tick"
                                            className="w-[14px] h-[8px] mr-3"
                                        />
                                        <span className="text-[sm] text-white">Free updates for 3 months.</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='space-y-8'>
                            <div className='text-white text-[24px] mb-6'>
                                <span>Payment History</span>
                            </div>
                            <div className="w-[570px] h-[545px] border-gradient p-4 rounded-lg">

                                <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 400 }}>
                                    <div
                                        className='h-[40px] flex flex-row justify-between items-center px-6'
                                        style={{
                                            background: 'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)'
                                        }}
                                    >
                                        <span className='text-white flex-1 text-center '>Date</span>
                                        <span className='text-white flex-1 text-center '>Payment Method</span>
                                        <span className='text-white flex-1 text-center '>Payment Type</span>
                                        <span className='text-white flex-1 text-center '>Amount</span>
                                    </div>


                                    {payments.map((payment, index) => (
                                        <div key={index}>
                                            <div
                                                className='flex flex-row px-4 py-6 justify-between'
                                                style={{
                                                    background: 'linear-gradient(270deg, #2E246C 0%, #2E246C 56.9%, #271F56 100%)',
                                                    borderRadius: '5px', // Optional: for rounded corners
                                                }}
                                            >
                                                <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.date}</span>
                                                <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>
                                                    <div className="flex items-center justify-center">
                                                        <img
                                                            src={Visa} // Update with your actual path
                                                            alt="Visa"
                                                            className="w-[64px] h-[18px] mr-2"
                                                        />
                                                        <span className="text-white font-semibold">{payment.paymentMethod}</span>
                                                    </div>
                                                </span>
                                                <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center pl-6'>{payment.type}</span>
                                                <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.amount}</span>
                                            </div>
                                            <div style={{ border: '0.1px gray solid' }}></div>
                                        </div>
                                    ))}

                                </div>

                                <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
                                    <span className="text-white pl-4">View full history</span>
                                    <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Right Column */}
                <div>
                    <div className='text-white font-semibold text-[18px] mb-4'>
                        Payment Method
                    </div>
                    <div className="profile-card w-[330px] h-[396px] border-gradient p-4 rounded-lg">
                        <div className="flex items-center mt-6">
                            <img
                                src={Visa} // Update with your actual path
                                alt="Visa"
                                className="w-[64px] h-[18px] mr-3"
                            />
                            <span className="text-white font-semibold">****9519</span>
                        </div>
                        <div className="flex items-center mt-8">

                            <span className="text-[#666F8D] font-semibold">Name on card</span>
                            <span className="text-white font-semibold ml-3">Robert Downey</span>
                        </div>
                        <div className="flex items-center mt-8">

                            <span className="text-[#666F8D] font-semibold">Expiration date</span>
                            <span className="text-white font-semibold ml-3">5/27</span>
                        </div>
                        <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
                            <span className="text-white pl-4">Change Card</span>
                            <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
                        </div>
                    </div>





                    <div className='text-white text-[24px] mb-4 mt-4'>
                        Next Renewal
                    </div>
                    <div className="profile-card w-[330px] h-[376px] border-gradient p-4 rounded-lg">
                        <div className='text-white p-1 text-[36px]'>$199</div>
                        <div className="flex items-center mt-8">
                            <span className="text-[#666F8D] font-semibold">Plan type</span>
                            <span className="text-white font-semibold ml-3">Standard (monthly)</span>
                        </div>
                        <div className="flex items-center mt-8">

                            <span className="text-[#666F8D] font-semibold">Next bill date</span>
                            <span className="text-white font-semibold ml-3">Jun 15, 2024</span>
                        </div>
                        <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
                            <span className="text-white pl-4">Cancel Plan </span>
                            <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Purchased;

// import { useNavigate } from 'react-router-dom';
// import ACCESSLUSSO from '../../assets/images/ACCESSLUSSO.png';
// import ChevronRightWhite from '../../assets/images/ChevronRight.png';
// import greenTick from '../../assets/images/greenTick.png';
// import ChevronRight from '../../assets/images/subscription-chev-right.png';
// import Visa from '../../assets/images/Visa.png';

// const Purchased = () => {
//     const navigate = useNavigate();
//     const payments = [
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         // {
//         //     date: "10/12",
//         //     paymentMethod: "****9519",
//         //     type: "Standard (monthly)",
//         //     amount: "$199.00",
//         // },
//         // {
//         //     date: "10/12",
//         //     paymentMethod: "****9519",
//         //     type: "Standard (monthly)",
//         //     amount: "$199.00",
//         // },

//         // Add more objects if needed
//     ];
//     return (
//         <>
//             <div className='text-white font-bold text-[24px] mb-8'>
//                 <span className='bg-gradient-to-r from-[#985FFF] to-[#FF99EF] bg-clip-text text-transparent'>
//                     Subscription
//                 </span>
//             </div>


//             <div className='flex space-x-4'>
//                 {/* Left Column */}
//                 <div>
//                     <div className='mb-8'>
//                         <div className='text-white font-semibold text-[18px] mb-4 flex justify-between items-center'>
//                             <span>Current Plan</span>

//                             {/* New label with chevron */}
//                             <div className='pr-2 flex items-center space-x-2 cursor-pointer'>
//                                 <span className='text-white font-semibold text-[18px]'>
//                                     Update Plan
//                                 </span>
//                                 <img
//                                     src={ChevronRight} // Update with your actual path
//                                     alt='Chevron Right'
//                                     className='w-2 h-3'
//                                 />
//                             </div>
//                         </div>

//                         <div className="w-full max-w-[865px] h-[199px] border-gradient p-4 rounded-lg">
//                             <div className="w-full max-w-[830px] h-[150px] mt-2 p-4 rounded-lg">
//                                 <div className="w-full max-w-[253px] h-[44px] flex items-center justify-center"
//                                     style={{
//                                         background: 'linear-gradient(115.33deg, #006EF8 20.95%, #212CB1 72.86%, #2B189C 88.07%)',
//                                         borderRadius: '20px',
//                                     }}>
//                                     <span className='text-white'>Plan active since: May 1, 2024</span>
//                                 </div>
//                                 <div className="w-full max-w-[800px] h-[66px] mt-8 flex flex-wrap items-center justify-between p-4 rounded-lg">
//                                     <img
//                                         src={ACCESSLUSSO} // Update with your actual path
//                                         alt='ACCESSLUSSO'
//                                         className='w-[171px] h-[50px]'
//                                     />
//                                     <div className="text-right">
//                                         <span className="text-2xl font-semibold" style={{ color: '#5B97FF' }}>$99.99</span>
//                                         <span className="text-2xl" style={{ color: 'white' }}>/mon</span>
//                                         <div className="text-sm" style={{ color: 'white' }}>Free 7-day trial</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Plan Benefits and Payment History Section */}
//                     <div className='flex space-x-2'>
//                         <div className='space-y-8'>
//                             <div className='text-white text-[24px] mb-6'>
//                                 <span>Plan Benefits</span>
//                             </div>
//                             <div className="w-full max-w-[290px] h-[545px] border-gradient p-4 rounded-lg">
//                                 <img
//                                     src={ACCESSLUSSO} // Update with your actual path
//                                     alt='ACCESSLUSSO'
//                                     className='w-[171px] h-[50px] mt-2'
//                                 />
//                                 <hr className="mt-6" style={{ color: 'grey', borderColor: 'grey' }} />
//                                 <div className='mt-12 w-[260px] h-[220px]'>
//                                     <div className="w-full max-w-[300px] h-[70px] flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3 mb-6"
//                                         />
//                                         <span className="text-[sm] text-white">Includes 50 digital products and completely customizable</span>
//                                     </div>
//                                     <div className="w-full max-w-[300px] h-[70px]  flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3 mb-6"
//                                         />
//                                         <span className="text-[sm] text-white">Perfect for small projects and individual use.</span>
//                                     </div>
//                                     <div className="w-full max-w-[300px] h-[70px]  flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3"
//                                         />
//                                         <span className="text-[sm] text-white">Free updates for 3 months.</span>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>

//                         <div className='space-y-8'>
//                             <div className='text-white text-[24px] mb-6'>
//                                 <span>Payment History</span>
//                             </div>
//                             <div className="w-full max-w-[570px] h-[545px] border-gradient p-4 rounded-lg">

//                                 <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 400 }}>
//                                     <div
//                                         className='h-[40px] flex flex-row justify-between items-center px-6'
//                                         style={{
//                                             background: 'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)'
//                                         }}
//                                     >
//                                         <span className='text-white flex-1 text-center '>Date</span>
//                                         <span className='text-white flex-1 text-center '>Payment Method</span>
//                                         <span className='text-white flex-1 text-center '>Payment Type</span>
//                                         <span className='text-white flex-1 text-center '>Amount</span>
//                                     </div>


//                                     {payments.map((payment, index) => (
//                                         <div key={index}>
//                                             <div
//                                                 className='flex flex-row px-4 py-6 justify-between'
//                                                 style={{
//                                                     background: 'linear-gradient(270deg, #2E246C 0%, #2E246C 56.9%, #271F56 100%)',
//                                                     borderRadius: '5px', // Optional: for rounded corners
//                                                 }}
//                                             >
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.date}</span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>
//                                                     <div className="flex items-center justify-center">
//                                                         <img
//                                                             src={Visa} // Update with your actual path
//                                                             alt="Visa"
//                                                             className="w-full max-w-[64px] min-w-[32px] h-auto mr-2"
//                                                         />

//                                                         <span className="text-white font-semibold">{payment.paymentMethod}</span>
//                                                     </div>
//                                                 </span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center pl-6'>{payment.type}</span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.amount}</span>
//                                             </div>
//                                             <div style={{ border: '0.1px gray solid' }}></div>
//                                         </div>
//                                     ))}

//                                 </div>

//                                 <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                                     <span className="text-white pl-4">View full history</span>
//                                     <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>

//                 {/* Right Column */}
//                 <div>
//                     <div className='text-white font-semibold text-[18px] mb-4'>
//                         Payment Method
//                     </div>
//                     <div className="profile-card w-[330px] h-[396px] border-gradient p-4 rounded-lg">
//                         <div className="flex items-center mt-6">
//                             <img
//                                 src={Visa} // Update with your actual path
//                                 alt="Visa"
//                                 className="w-[64px] h-[18px] mr-3"
//                             />
//                             <span className="text-white font-semibold">****9519</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Name on card</span>
//                             <span className="text-white font-semibold ml-3">Robert Downey</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Expiration date</span>
//                             <span className="text-white font-semibold ml-3">5/27</span>
//                         </div>
//                         <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                             <span className="text-white pl-4">Change Card</span>
//                             <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                         </div>
//                     </div>





//                     <div className='text-white text-[24px] mb-4 mt-4'>
//                         Next Renewal
//                     </div>
//                     <div className="profile-card w-[330px] h-[376px] border-gradient p-4 rounded-lg">
//                         <div className='text-white p-1 text-[36px]'>$199</div>
//                         <div className="flex items-center mt-8">
//                             <span className="text-[#666F8D] font-semibold">Plan type</span>
//                             <span className="text-white font-semibold ml-3">Standard (monthly)</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Next bill date</span>
//                             <span className="text-white font-semibold ml-3">Jun 15, 2024</span>
//                         </div>
//                         <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                             <span className="text-white pl-4">Cancel Plan </span>
//                             <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>

//     );
// };

// export default Purchased;

// import { useNavigate } from 'react-router-dom';
// import CardIcon from '../../assets/images/icons/card-icon.svg';
// import CardTypeIcon from '../../assets/images/icons/card-type.svg';
// import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
// import TickIcon from '../../assets/images/tick-icon.svg';

// const Purchased = () => {
//     const navigate = useNavigate();
//     return (
//         <div className="container price-background-banner">
//             <div className="left"></div>
//             <div className="right">
//                 <div className='text-white font-bold text-[20px]'>
//                     <span>Your Plan Details</span>
//                 </div>
//                 <div>
//                     <div className="horizontal-divider-light mt-4 mb-4"></div>
//                 </div>
//                 <div className="flex flex-col gap-y-4">
//                     <div className="purchased-card px-4 py-4">
//                         <div >
//                             <span className="text-white px-3 py-2" style={{ background: '#3B3396', fontSize: '0.8rem', borderRadius: 16 }}>
//                                 CURRENT PLAN
//                             </span>
//                         </div>
//                         <div className="flex flex-row justify-between items-center current-plan-detail-row">
//                             <div className="flex flex-col py-4">
//                                 <span className="font-bold text-[#FF56E5]" style={{ fontSize: '1.5rem' }}>
//                                     Standard
//                                 </span>
//                                 <span className="font-normal text-[#FFF]" style={{ fontSize: '0.8rem' }}>
//                                     Standard monthly subscription plan active since: May1, 2024
//                                 </span>
//                             </div>
//                             <div className='current-plan-btn-row flex flex-row justify-center items-center gap-2'>
//                                 <button
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #FFF',
//                                         textTransform: 'capitalize',
//                                         paddingLeft: 12,
//                                         paddingRight: 12
//                                     }}
//                                 >
//                                     Cancel Plan
//                                 </button>
//                                 <button
//                                     onClick={() => {
//                                         navigate('/dev/pricing')
//                                     }}
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #A768FD',
//                                         background: 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
//                                         textTransform: 'capitalize',
//                                         paddingLeft: 12,
//                                         paddingRight: 12
//                                     }}
//                                 >
//                                     Upgrade
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="current-next-row flex flex-row gap-x-2">
//                         <div className="flex-1 purchased-card px-4 py-4">
//                             <div>
//                                 <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Current Plans Benefits
//                                 </span>
//                             </div>
//                             <div className='flex flex-row mt-4'>
//                                 <div className="flex-1 flex flex-col gap-y-2">
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div className="flex-1 flex flex-col gap-y-2">
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="flex-1 purchased-card px-4 py-4">
//                             <div className='flex flex-row justify-between items-center'>
//                                 <div>
//                                     <div>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
//                                             Current Plans Benefits
//                                         </span>
//                                     </div>
//                                     <div>
//                                         <span className="text-white font-normal" style={{ fontSize: '1.5rem' }}>
//                                             $ 199.00
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div style={{ backgroundColor: '#F927D9', paddingLeft: 12, paddingRight: 12, paddingBottom: 16, paddingTop: 16, borderRadius: '50%' }}>
//                                     <img src={CardIcon} alt='' />
//                                 </div>
//                             </div>
//                             <div className='flex flex-col justify-between mt-3'>
//                                 <div className='flex flex-row'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Plan type:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Standard (monthly)
//                                     </span>
//                                 </div>
//                                 <div className='flex flex-row mt-1'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Next bill date:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Jun 15, 2024
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="payment-method-row flex flex-row gap-x-2">
//                         <div className="purchased-card px-4 py-4 flex flex-col justify-between" style={{ width: '30%' }}>
//                             <div>
//                                 <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Payment Method
//                                 </span>
//                             </div>
//                             <div className='flex flex-row items-center justify-between'>
//                                 <div>
//                                     <img src={CardTypeIcon} alt='' />
//                                 </div>
//                                 <div>
//                                     <span className='text-white'>**** 9519</span>
//                                 </div>
//                             </div>
//                             <div className='flex flex-col justify-between mt-3'>
//                                 <div className='flex flex-row items-center'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Name on Card:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Robert Downey
//                                     </span>
//                                 </div>
//                                 <div className='flex flex-row mt-1 items-center'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Expiration date:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         5/27
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className='change-delete-btn-row flex flex-row items-center gap-1 mt-1'>
//                                 <button
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #FFF',
//                                         textTransform: 'capitalize'
//                                     }}
//                                 >
//                                     Change
//                                 </button>
//                                 <div style={{ backgroundColor: '#FFF', padding: 8, borderRadius: '50%' }}>
//                                     <img src={DeleteIcon} alt='' />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex-1 purchased-card">
//                             <div className='px-4 py-4'>
//                                 <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Payment Method
//                                 </span>
//                             </div>
//                             <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 300 }}>
//                                 <div className='flex flex-row px-4'>
//                                     <span className='flex-1 text-white'>Date</span>
//                                     <span className='flex-1 text-white'>Payment Type</span>
//                                     <span className='flex-1 text-white'>Amount</span>
//                                 </div>
//                                 <div style={{ border: '1px #FFF solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Purchased;