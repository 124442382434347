import { Star } from 'lucide-react';

const CourseHeader = ({ headerData }: any) => (
    <div className="flex items-center mb-8 gap-5 flex-col md:flex-row">
        <div>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} className='w-[200px] h-[200px] rounded-2xl'/>
        </div>
        <div className='flex flex-col items-center md:items-start justify-between gap-3'>
            <div className='flex flex-col items-center md:items-start'>
                <p className='hidden md:block text-md text-[#6DDCFF] font-semibold text-center md:text-start'>{headerData?.titleHeader}</p>
                <h1 className="text-xl md:text-4xl font-bold text-center md:text-start">{headerData?.title}</h1>
                <h2 className='text-md text-center md:text-start'>{headerData?.subTitle}</h2>
            </div>

            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        {/* <img src={starIcon} alt='star' /> */}
                        <Star fill='#FDB31F' color='#FDB31F' size={16} />
                    </div>
                    <span> | {headerData?.ratingCount} | {headerData?.category}</span>
                </p>
            </div>
            <div className="flex flex-col gap-3">
                <div className='flex flex-col items-center md:items-start'>
                    <p className='text-[#6DDCFF] font-semibold'>Created by</p>
                    <p className='text-white text-xl font-bold'>{headerData.author}</p>
                </div>
                {/* <div>
                    <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                    <p className='text-white'>{headerData.ageRating}</p>
                </div> */}
            </div>
        </div>
    </div>
);

export default CourseHeader