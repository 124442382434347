import { Star } from 'lucide-react';

const Header = ({ headerData }: any) => {
    const currentUrl = window.location.href;
    let type = (getTypeFromUrl(currentUrl))?.toLowerCase()

    function getTypeFromUrl(url: string) {
        const parts = url.split('/');
        const exploreIndex = parts.indexOf('explore');
        if (exploreIndex !== -1 && parts[exploreIndex + 1]) {
            return parts[exploreIndex + 1];
        }
        return null;
    }

    // render
    return (
        <div className="flex items-center mb-8 gap-5 flex-col md:flex-row">
            <div>
                <img src={headerData?.logo} alt={`${headerData?.title} logo`} className='w-[200px] h-[200px] rounded-2xl' />
            </div>
            <div className='flex flex-col items-center md:items-start justify-between gap-3'>
                <h1 className="text-4xl font-bold text-center md:text-start">{headerData?.title}</h1>
                <div className="flex items-center gap-2">
                    <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                        <span>{headerData?.rating}</span>
                        <div className='h-4 w-4 mx-2'>
                            <Star fill='#FDB31F' color='#FDB31F' size={16} />
                        </div>
                        <span> | {headerData?.ratingCount}  {headerData?.category}</span>
                    </p>
                </div>
                <div className="flex flex-col gap-3">
                    <div className='flex flex-col items-center md:items-start'>
                        <p className='text-[#6DDCFF] font-semibold'>{(type === 'games' || type === 'apps') ? 'Publisher' : type === 'movies' ? 'Genre' : 'Category'}</p>
                        <p className='text-white'>{headerData.category}</p>
                    </div>
                    <div className='flex flex-col items-center md:items-start'>
                        <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                        <p className='text-white'>{headerData.ageRating}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Header