import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import down_arrow_icon from '../../assets/images/icons/arrow-down.svg';
import filter_icon from '../../assets/images/icons/filter.svg';
import DataGrid from '../../components/dataGrid/DataGrid';
import Search from '../../components/search/Search';
import Badge from '../../components/ui/Badge';
import { ICON_ENUM } from '../../constants/icons.constant';
import './dev.scss';

const dataSet = [
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Active',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 100,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Pending',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Active',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
    {
        appName: 'Cherry',
        visitors: 3452,
        likes: 1042,
        avgRating: 45.6,
        progress: 50,
        boostPeriod: 40.3,
        lastUpdate: '27/06/2024',
        status: 'Inactive',
    },
];

const ProductList = () => {
    const navigate = useNavigate();

    const [gridData, setGridData] = useState<any[]>(dataSet ?? [])
    const columns = [
        {
            header: 'App Name',
            target: 'appName',
            sorting: true
        },
        {
            header: 'Visitors',
            target: 'visitors',
            sorting: true
        },
        {
            header: 'Likes',
            target: 'likes',
        },
        {
            header: 'Avg Rating',
            target: 'avgRating',
            sorting: true
        },
        {
            header: 'Progress',
            target: 'progress',
            sorting: true,
            cellRender: (value: string | number) => (
                <div className="flex justify-center items-center gap-1">
                    <progress
                        className="progress progress-success h-1 w-50"
                        value={value}
                        max="100"
                    />
                    {Number(value) === 100 ? (
                        <img
                            src={ICON_ENUM?.GREEN_TICK_CIRCLE?.icon}
                            width={10}
                            alt="completed"
                        />
                    ) : (
                        <span className="text-[#FFFFFF99] text-xs">{value + '%'}</span>
                    )}
                </div>
            ),
        },
        {
            header: 'Boost Period',
            target: 'boostPeriod',
            sorting: true
        },
        {
            header: 'Last Update',
            target: 'lastUpdate',
            sorting: true
        },
        {
            header: 'Status',
            target: 'status',
            sorting: true,
            cellRender: (value: string) => (
                <Badge value={value} bgColorEnumKey={value} />
            ),
        },
        {
            header: 'Action',
            cellRender: () => (
                <div className="flex justify-center">
                    <img src={ICON_ENUM?.THREE_DOTS?.icon} width={20} />
                </div>
            ),
        },
    ];

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
        >
            <div>
                <div className="flex justify-between text-white text-[20px] flex-wrap gap-3">
                    <span>Product</span>
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <button className="flex items-center gap-3 custom-primary-border rounded-lg py-2 px-3 bg-[#FFFFFF1A]">
                            <img src={filter_icon} width={15} />
                            <span className="text-sm">Filters</span>
                        </button>
                        <Search data={dataSet} onSearch={(data) => setGridData(data)} />
                        <button className="flex items-center btn bg-[#792FFF80] gap-2 py-1 px-2 rounded-lg" onClick={() => { navigate('/dev/addproduct') }}>
                            <span>+ Add Product</span>
                            <span
                                className="flex items-center px-2 h-full"
                                style={{
                                    borderLeft: `1px solid #FFFFFF99`,
                                }}
                            >
                                <img src={down_arrow_icon} alt="dn-icon" className="h-5 w-3" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-bg-dev rounded-xl text-white">
                <DataGrid columns={columns} data={gridData} recordPerPage={10} />
            </div>
        </div>
    );
};

export default ProductList;
