import React, { useState } from 'react';

interface FAQItemProps {
    question: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="border-b border-gray-700">
            <button
                className="flex justify-between items-center w-full md:py-5 py-1 text-left text-white"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{question}</span>
                <span className="text-2xl">{isOpen ? '-' : '+'}</span>
            </button>
            {isOpen && (
                <div className="pb-5 text-gray-400">
                    {/* Add answer content here */}
                    This is a placeholder answer for the FAQ item.
                </div>
            )}
        </div>
    );
};

const FAQ: React.FC = () => {
    const questions: string[] = [
        "How does your platform handle product promotion?",
        "Can I track my product's performance?",
        "How can I integrate my listings with other platforms?",
        "How can I contact customer support?",
    ];

    return (
        <div className="text-white h-full md:py-24 py-5 items-center">
            <h2 className="text-[18px] md:text-4xl font-bold md:mb-12 mb-4 md:mt-0 mt-1 text-center">FREQUENTLY ASKED QUESTIONS</h2>
            <div className="lg:max-w-3xl px-3 mx-auto">
                {questions.map((question, index) => (
                    <FAQItem key={index} question={question} />
                ))}
            </div>
        </div>
    );
};

export default FAQ;