
const CallbackEndpoint = (props: any) => {
    window.onload = () => {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId');
        const blogId = params.get('blogId');

        window.opener.postMessage({ userId, blogId }, process.env.REACT_APP_ORIGIN);
        window.close();
    };

    return <></>

}

export default CallbackEndpoint