import axios from 'axios';
import { toast } from 'react-toastify';
import { envValues } from '../constants/envs';
import { getCookies } from '../utils/utils';

const instance = axios.create({
  baseURL: envValues.apiBaseUrl,
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getCookies('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  },
  (error: any) => Promise.reject(error)
)

axiosInstance.interceptors.request.use(
  (response: any) => response,
  (error: any) => {

    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        toast.error('Session has been expired!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          // style: {
          //   background: '#2E246C',
          //   color: 'white',
          //   fontWeight: 700,
          //   borderRadius: '8px',
          //   padding: '16px',
          //   marginTop: 50
          // }
        });
        setTimeout(() => {
          window.location.href = '/login'
        }, 3000);
        return Promise.reject(new Error('Session has been expired!'))
      } else if (status === 500) {
        toast.error('Internal server error!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          // style: {
          //   background: '#2E246C',
          //   color: 'white',
          //   fontWeight: 700,
          //   borderRadius: '8px',
          //   padding: '16px',
          //   marginTop: 50
          // }
        });
        return Promise.reject(new Error('Internal server error!'))
      }
    }
    return Promise.reject(error)
  }
)

export default instance;
export { axiosInstance };

