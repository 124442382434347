import React from 'react';
import starIcon from '../../assets/images/home/star.svg';

interface Item {
    id: number;
    icon: string;
    title: string;
    genre: string;
}

interface ProductCardProps {
    item: Item;
    navigateDetails: (id: number) => void;
    width?: string;
    height?: string;
    homeTrending?: boolean;
}

const ProductCardNew: React.FC<ProductCardProps> = ({ item, navigateDetails, width = '', height = '', homeTrending = false }) => {
    let isDragging = false;

    const handleMouseDown = () => {
        isDragging = false;
    };

    const handleMouseMove = () => {
        isDragging = true;
    };

    const handleMouseUp = () => {
        if (!isDragging) {
            navigateDetails(item.id);
        }
    };
    return (
        <div className="relative rounded-xl group overflow-hidden border-2 border-transparent hover:border-white hover:p-1.5 transition-all duration-300"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}>
            <div
                className={`h-[${height || '299px'}] w-[${width}] relative overflow-hidden`}
                // onClick={() => navigateDetails(item.id)}
                style={{
                    backgroundImage: `url(${item.icon})`,
                    backgroundSize: 'cover',
                }}
            >
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-60 transition-opacity duration-300"></div>
                <div className="flex justify-between relative z-10 h-full p-3">
                    <div className="flex-1 w-full flex flex-col gap-y-2 absolute md:p-[15px] p-[10px] bottom-0 left-0 bg-[#0000008C]" style={{ top: "60%" }}>
                        <h3 className="text-white text-xs md:text-2xl md:font-semibold uppercase">
                            {item.title.length > 12 ? item.title.substring(0, 12) + '...' : item.title}
                        </h3>
                        <p className={`text-white font-normal ${width !== '250px' ? 'text-[10px]' : 'text-xs text-8'}`}>
                            {item.genre}
                        </p>
                        <p className="flex gap-2 items-center justify-start">
                            <span className={`text-[#00F0FB] ${width !== '250px' && 'text-[10px]'}`}>4.6</span>
                            <div>
                                <img src={starIcon} alt="star" className={`h-4 ${width !== '250px' ? 'w-[13px!important]' : 'w-auto'}`} />
                            </div>
                        </p>
                    </div>
                    <div className={`absolute ${homeTrending ? (width !== '250px' ? 'bottom-[8px] right-3' : 'bottom-[12px] right-8') : 'bottom-4 right-2'}`}>
                        <span className={`bg-[#3B2C94] px-4 py-1 text-white rounded-2xl ${width !== '250px' ? 'text-[9px]' : 'text-xs'}`}>TAGS</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCardNew;
