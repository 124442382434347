import React, { useState } from 'react';
import chatBotBG from '../../assets/images/explore/chatbot-bg.jpeg';
import roboBotCenter from '../../assets/images/explore/robo-bot-center.svg';
const ChatBot: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleChat = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="z-[999px]">
            {isOpen && (
                <div className="p-1 shadow-lg w-80 sm:w-96 mb-4 absolute right-4 rounded-2xl z-10" style={{ bottom: 110, minWidth: 350 }}>
                    {/* Header */}
                    <div className="flex items-center p-3" style={{ background: 'linear-gradient(180deg, #370C8C 0%, #3D1673 100%), #2E2B41' }}>
                        <svg
                            className='border-[#4800CB] border-2 rounded-full px-2 py-1'
                            style={{ background: 'linear-gradient(178deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)' }}
                            xmlns="http://www.w3.org/2000/svg" width="55" height="43" viewBox="0 0 44 33" fill="none">
                            <g clip-path="url(#clip0_2876_40546)">
                                <path d="M40.3304 11.4548L39.4281 11.3034V11.1521C39.4281 8.88162 38.2251 6.83822 36.2701 5.77867C34.6159 4.8705 32.0595 4.038 28.2248 3.50823C27.7736 1.9946 26.4954 0.859375 21.7584 0.859375C17.0966 0.859375 15.8183 1.91892 15.3672 3.50823C11.1565 3.96232 8.44968 4.8705 6.7203 5.77867C4.84054 6.83822 3.5623 8.9573 3.5623 11.1521V11.3791L3.26154 11.4548C1.68254 11.6818 0.554688 13.0441 0.554688 14.6334V21.5204C0.554688 23.1097 1.68254 24.472 3.26154 24.6991L3.5623 24.7747C3.5623 26.9695 4.76535 29.0129 6.7203 30.0724C9.20158 31.4347 13.6378 32.6456 21.4576 32.6456C29.2774 32.6456 33.7137 31.359 36.1949 30.0724C38.0747 29.0129 39.3529 27.0452 39.3529 24.8504L40.2552 24.6991C41.8342 24.472 42.9621 23.1097 42.9621 21.5204V14.6334C43.0373 13.0441 41.8342 11.6818 40.3304 11.4548ZM37.7739 24.8504C37.7739 26.3641 36.9468 27.802 35.5934 28.5588C33.6385 29.6184 29.5782 30.9049 21.7584 30.9049C13.9386 30.9049 9.95349 29.6184 7.99854 28.5588C6.64511 27.802 5.81801 26.4397 5.81801 24.8504V13.5739C5.81801 12.0602 6.64511 10.6223 7.99854 9.86548C9.95349 8.80594 14.0138 7.51935 21.8336 7.51935C29.6534 7.51935 33.6385 8.80594 35.6686 9.86548C37.022 10.6223 37.8491 11.9846 37.8491 13.5739V24.8504H37.7739Z" fill="white" />
                                <path d="M16.6481 19.0234C16.6481 20.3856 15.5955 19.7045 14.242 19.7045C12.8886 19.7045 11.8359 20.3856 11.8359 19.0234C11.8359 17.6611 12.8886 16.6016 14.242 16.6016C15.5955 16.6016 16.6481 17.6611 16.6481 19.0234Z" fill="white" />
                                <path d="M23.7146 23.1074C23.7146 23.6372 23.489 24.167 23.1131 24.4697C22.7371 24.7724 22.286 25.0751 21.7596 25.0751C20.707 25.0751 19.8047 24.167 19.8047 23.1074C19.8047 23.0317 19.8047 22.9561 19.8799 22.8804C19.9551 22.8047 20.0303 22.8047 20.1054 22.8047H23.4138C23.6394 22.8047 23.7146 22.9561 23.7146 23.1074Z" fill="white" />
                                <path d="M31.7575 19.0234C31.7575 20.3856 30.7048 19.7045 29.3514 19.7045C27.998 19.7045 26.9453 20.3856 26.9453 19.0234C26.9453 17.6611 27.998 16.6016 29.3514 16.6016C30.7048 16.6016 31.7575 17.6611 31.7575 19.0234Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2876_40546">
                                    <rect width="42.4826" height="31.8619" fill="white" transform="translate(0.554688 0.859375)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className='flex flex-col ms-2'>
                            <div className='flex gap-2 items-center'>
                                <h3 className="text-md font-bold text-white">Ask Lusso</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none">
                                    <path d="M22.6937 13.6828C22.6324 13.6523 22.5711 13.6218 22.5098 13.5913C21.8665 13.3474 21.2232 13.1035 20.6105 12.8901C20.1203 12.7072 19.6302 12.5547 19.14 12.3108C18.221 11.8535 17.7002 11.0913 17.3632 10.1461C17.0263 9.17052 16.628 8.19489 16.2604 7.21926C16.2298 7.1278 16.1072 7.00584 15.9847 7.00584C15.8621 6.97535 15.7396 7.06682 15.6783 7.21926C15.3107 8.1644 14.9737 9.07905 14.6061 10.0242C14.4223 10.451 14.2691 10.8779 14.0241 11.2742C13.7484 11.701 13.3195 12.0669 12.86 12.2498C11.9716 12.6157 11.0832 12.9511 10.1947 13.2864C9.85777 13.4084 9.52079 13.5304 9.18381 13.7133C9.0919 13.7438 9 13.8962 9 13.9877C9 14.0791 9.12254 14.1706 9.18381 14.2621C9.21444 14.2926 9.27571 14.323 9.33698 14.323C10.2867 14.6889 11.2363 15.0243 12.186 15.3597C13.2276 15.7255 14.0241 16.3658 14.453 17.4024C14.7287 18.0731 14.9737 18.7439 15.2188 19.3841C15.372 19.811 15.5558 20.2378 15.709 20.6951C15.7702 20.8476 15.8621 20.9695 16.0766 21C16.1685 20.878 16.291 20.7866 16.3217 20.6646C16.6893 19.75 17.0263 18.8048 17.3632 17.8902C17.7002 16.945 18.221 16.1523 19.1094 15.695C19.5689 15.4511 20.0591 15.2987 20.5492 15.1157C21.2538 14.8414 21.9891 14.5974 22.6937 14.2926C22.8162 14.2316 22.9387 14.1096 23 13.9877C22.9694 13.8962 22.7856 13.7438 22.6937 13.6828Z" fill="white" />
                                    <path d="M1.21472 7.16616C1.92025 7.43807 2.62577 7.70997 3.33129 7.95166C4.03681 8.22356 4.58896 8.64652 4.86503 9.3716C4.95706 9.58308 5.04908 9.79456 5.1411 10.0363C5.35583 10.5801 5.53988 11.1239 5.7546 11.6677C5.78528 11.7583 5.90798 11.8489 6 12C6.09203 11.8792 6.18405 11.8187 6.21472 11.7281C6.4908 11.0634 6.7362 10.3686 6.9816 9.70393C7.25767 8.91843 7.74847 8.34441 8.54601 8.0423C9.25153 7.77039 9.95706 7.5287 10.6626 7.22659C10.7853 7.16616 10.8773 7.04532 11 6.95468C10.908 6.86405 10.7853 6.77341 10.6933 6.71299C10.0491 6.4713 9.40491 6.22961 8.76074 5.98792C7.87117 5.6858 7.28834 5.11178 6.9816 4.20544C6.76687 3.54079 6.4908 2.87613 6.21472 2.24169C6.18405 2.15106 6.06135 2.09063 6 2C5.90798 2.09063 5.78528 2.15106 5.7546 2.2719C5.5092 2.87613 5.2638 3.48036 5.04908 4.1148C4.77301 4.83988 4.37423 5.44411 3.63804 5.77643C3.39264 5.89728 3.11656 5.98792 2.87117 6.07855C2.31902 6.29003 1.79755 6.4713 1.2454 6.68278C1.15337 6.71299 1.09202 6.86405 1 6.92447C1.06135 7.01511 1.1227 7.10574 1.21472 7.16616Z" fill="white" />
                                    <path d="M7.13878 18.2263C6.45306 17.9959 5.96327 17.535 5.73469 16.8107C5.53878 16.284 5.34286 15.7572 5.14694 15.2305C5.11429 15.1317 5.01633 15.0658 4.95102 15C4.88571 15.0658 4.78776 15.1317 4.7551 15.2305C4.59184 15.6584 4.42857 16.1193 4.26531 16.5473C4.03673 17.2387 3.67755 17.8313 2.95918 18.0947C2.69796 18.1934 2.43673 18.2922 2.14286 18.4239C1.81633 18.5556 1.45714 18.6872 1.13061 18.8189C1.06531 18.8848 1 18.9506 1 19.0165C1 19.0823 1.09796 19.1481 1.13061 19.1811C1.22857 19.2469 1.35918 19.2798 1.4898 19.3457C1.94694 19.5103 2.40408 19.6749 2.86122 19.8395C3.41633 20.037 3.80816 20.3992 4.03673 20.9588C4.29796 21.5844 4.49388 22.177 4.7551 22.8025C4.78776 22.9012 4.88571 22.9342 4.95102 23C5.01633 22.9342 5.11429 22.8683 5.14694 22.8025C5.3102 22.4403 5.44082 22.0782 5.57143 21.7161C5.70204 21.3868 5.83265 21.0247 5.99592 20.6955C6.2898 20.1029 6.87755 19.9054 7.46531 19.6749C7.85714 19.5103 8.24898 19.3786 8.64082 19.214C8.73878 19.1811 8.83673 19.0823 9 18.9835C8.86939 18.8848 8.80408 18.8189 8.70612 18.786C8.18367 18.6214 7.66122 18.4239 7.13878 18.2263Z" fill="white" />
                                </svg>
                            </div>
                            <span className='text-[#D2D2D2] text-xs'>Online</span>
                        </div>
                    </div>
                    {/* Body */}
                    <p className='min-h-[400px] items-center flex flex-col justify-center gap-y-10 text-white' style={{ background: `url(${chatBotBG})`, backgroundSize: 'cover' }}>
                        <svg className='absolute z-2 top-[150px]' xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 251 236" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M250.337 117.766C250.337 182.81 194.395 235.539 125.387 235.539C56.3796 235.539 0.4375 182.81 0.4375 117.766C0.4375 52.7214 56.3796 -0.0078125 125.387 -0.0078125C194.395 -0.0078125 250.337 52.7214 250.337 117.766Z" fill="url(#paint0_linear_2657_62181)" />
                            <defs>
                                <linearGradient id="paint0_linear_2657_62181" x1="125.387" y1="-0.0078125" x2="125.387" y2="235.539" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#4800CB" />
                                    <stop offset="1" stop-color="#FF77B0" stop-opacity="0.5" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className='w-[170px] z-10'>
                            <img src={roboBotCenter} alt='roboBotCenter' />
                        </div>

                        <p className='text-center'>Nice to meet you, how can I help you?</p>

                    </p>

                    {/* Bottom */}
                    <div className='w-full text-center -mt-16'>
                        <button className="w-[90%] h-[50px] text-white px-4 py-3 rounded-2xl text-xs font-medium" style={{ background: 'linear-gradient(178deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)' }}>
                            Let's Start Chatting
                        </button>
                    </div>
                </div>
            )}
            <button
                style={{ background: 'linear-gradient(178deg, #4300BD 6.78%, #792FFF 46.97%, #FF77B0 98.12%)' }}
                className={`text-white p-4 rounded-full shadow-lg transition-all duration-300 fixed right-4 bottom-5`}
                onClick={toggleChat}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="68" height="58" viewBox="0 0 78 58" fill="none">
                    <g clip-path="url(#clip0_2876_26891)">
                        <path d="M72.6667 19.3148L71.0279 19.0399V18.765C71.0279 14.6412 68.8428 10.9297 65.292 9.00526C62.2875 7.35573 57.6441 5.84366 50.6791 4.88144C49.8597 2.13222 47.5381 0.0703125 38.9342 0.0703125C30.467 0.0703125 28.1453 1.99476 27.3259 4.88144C19.6781 5.7062 14.7616 7.35573 11.6205 9.00526C8.20629 10.9297 5.88462 14.7786 5.88462 18.765V19.1774L5.33835 19.3148C2.4704 19.7272 0.421875 22.2015 0.421875 25.0882V37.5971C0.421875 40.4838 2.4704 42.9581 5.33835 43.3704L5.88462 43.5079C5.88462 47.4943 8.06972 51.2057 11.6205 53.1302C16.1273 55.6044 24.1848 57.8038 38.388 57.8038C52.5911 57.8038 60.6487 55.467 65.1554 53.1302C68.5696 51.2057 70.8913 47.6317 70.8913 43.6454L72.5301 43.3704C75.3981 42.9581 77.4466 40.4838 77.4466 37.5971V25.0882C77.5832 22.2015 75.3981 19.7272 72.6667 19.3148ZM68.0234 43.6454C68.0234 46.3946 66.5211 49.0063 64.0629 50.3809C60.5121 52.3054 53.1374 54.6422 38.9342 54.6422C24.7311 54.6422 17.493 52.3054 13.9422 50.3809C11.4839 49.0063 9.98168 46.532 9.98168 43.6454V23.1637C9.98168 20.4145 11.4839 17.8027 13.9422 16.4281C17.493 14.5037 24.8677 12.1669 39.0708 12.1669C53.2739 12.1669 60.5121 14.5037 64.1994 16.4281C66.6577 17.8027 68.1599 20.277 68.1599 23.1637V43.6454H68.0234Z" fill="white" />
                        <path d="M29.6466 33.0628C29.6466 35.5371 27.7347 34.3 25.2764 34.3C22.8182 34.3 20.9062 35.5371 20.9062 33.0628C20.9062 30.5885 22.8182 28.6641 25.2764 28.6641C27.7347 28.6641 29.6466 30.5885 29.6466 33.0628Z" fill="white" />
                        <path d="M42.4844 40.4873C42.4844 41.4496 42.0747 42.4118 41.3918 42.9616C40.709 43.5115 39.8896 44.0613 38.9336 44.0613C37.0216 44.0613 35.3828 42.4118 35.3828 40.4873C35.3828 40.3499 35.3828 40.2124 35.5194 40.075C35.656 39.9375 35.7925 39.9375 35.9291 39.9375H41.9381C42.3478 39.9375 42.4844 40.2124 42.4844 40.4873Z" fill="white" />
                        <path d="M57.0998 33.0628C57.0998 35.5371 55.1878 34.3 52.7296 34.3C50.2713 34.3 48.3594 35.5371 48.3594 33.0628C48.3594 30.5885 50.2713 28.6641 52.7296 28.6641C55.1878 28.6641 57.0998 30.5885 57.0998 33.0628Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2876_26891">
                            <rect width="77.1613" height="57.871" fill="white" transform="translate(0.421875 0.0703125)" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
    );
};

export default ChatBot;