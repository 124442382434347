import "./about.scss";
import EventSection from "./EventSection";
import HeroSection from "./HeroSection";
import ImpactSection from "./ImpactSection";
import ServiceSection from "./ServiceSection";
import StorySection from "./StorySection";

const AboutUs = () => {
    return (
        <div>
            <div className="w-full">
                <HeroSection />
                <div className="bg-blurred-new px-10">
                    <ServiceSection />
                </div>
            </div>
            <div className="w-full bg-blurred-new p-10">
                <StorySection />
                <ImpactSection />
                <EventSection />
            </div>
        </div>
    );
};

export default AboutUs;
