import { LineDraw } from "../GameDetailsScreen";
import RatingDisplay from "./RatingDisplay";

export interface RatingSectionProps {
    starDistribution: any;
    rating: number;
    reviewTitle: string;
    reviewText: string;
    reviewerName: string;
    reviewDate: string;
}

function RatingSection({ ratingData }: RatingSectionProps | any) {
    const {
        starDistribution,
        rating,
        reviewTitle,
        reviewText,
        reviewerName,
        reviewDate
    } = ratingData
    return (
        <div className="mb-3 bg-[#161328] rounded-2xl p-5">
            <h2 className="text-xl md:text-3xl font-bold mb-4">Ratings and Reviews</h2>
            <LineDraw />
            <div className="md:flex items-center mb-2">
                <div className="flex mb-1">
                    <RatingDisplay starDistribution={starDistribution} />
                </div>
            </div>

            <div className="rounded-lg bg-[#111924] p-5">
                <p className="font-bold text-md flex items-center">
                    <span>{rating?.toFixed(1)}</span>
                    <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                        <path d="M9 0L12.1158 4.7114L17.5595 6.21885L14.0416 10.6381L14.2901 16.2812L9 14.301L3.70993 16.2812L3.95845 10.6381L0.440492 6.21885L5.88415 4.7114L9 0Z" fill="#FD8E1F" />
                    </svg>
                </p>
                <p>
                    <span className="text-grey-300 font-normal ms-1 me-1"> | </span>
                    <span className="text-[#6DDCFF]"> {reviewTitle}</span>
                </p>
                <p className="text-lg my-2 text-white">
                    {reviewText}
                </p>
                <p className="text-lg text-white mt-2">{reviewerName}, {reviewDate}</p>
            </div>

            <div className="md:hidden pt-5">
                {starDistribution?.map((item: any) => (
                    <div key={item?.stars} className="flex items-center mb-1">
                        <span className="w-8 text-sm flex items-center">{item.stars}
                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                <path d="M8.65625 0L11.7721 4.7114L17.2158 6.21885L13.6978 10.6381L13.9463 16.2812L8.65625 14.301L3.36618 16.2812L3.6147 10.6381L0.0967417 6.21885L5.5404 4.7114L8.65625 0Z" fill="#FD8E1F" />
                            </svg>
                        </span>
                        <div className="flex-grow rounded-full h-4 ml-2" style={{ background: 'rgba(253, 142, 31, 0.40)' }}>
                            <div
                                className="bg-[#FD8E1F] h-4 rounded-full"
                                style={{ width: `${item.percentage}%` }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RatingSection
