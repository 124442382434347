import { useNavigate } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import ProductCardNew from '../home/ProductCardNew';

const TrendingProducts = (props: any) => {
    const navigate = useNavigate();
    const navigateDetails = (id: number) => navigate(`/dashboard/details/${id}`);
    const { data } = props;

    const isMobile = window?.innerWidth < 768; // Example mobile breakpoint
    console.log(isMobile, 'isMobile')
    // Split data into rows only if it's not a mobile view
    const rows = isMobile ? [data] : [data.slice(0, 5), data.slice(5, 10)];

    return (
        <>
            <TitleBar title={"Trending Now"} />
            <div className={`overflow-x-auto md:min-h-[600px] overflow-y-hidden flex md:mb-0 mb-[10px] md:gap-[1.4rem] gap-2 ${isMobile ? 'flex-nowrap' : 'flex-wrap'}`}> {/* Flex on mobile, wrap on larger screens */}
                {rows.map((row, rowIndex) => (
                    row.map((item: any) => (
                        <div key={item.id} className={`trending-product-item ${isMobile ? 'trending-product-mobile' : 'trending-product-web'}`}>
                            <ProductCardNew
                                item={item}
                                navigateDetails={navigateDetails}
                                width={isMobile ? '150px' : '250px'}
                                height={isMobile ? '200px' : '280px'}
                                homeTrending={true}
                            />
                        </div>
                    ))
                ))}
            </div>
        </>
    );


};

export default TrendingProducts;
