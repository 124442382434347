import { useEffect, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { aiCategories, appCategories, courseCategories, gameCategories, movieTabGeners, serviceCategories } from "../../data/productData";
import DropDown from "../dropdown/DropDown";

interface ProductCatSelectTabProps {
    setCurrentStep: (step: number) => void;
    currentStep: number;
    setSelectedCategory: (option: string) => void;
    selectedCategory: string;
}
const ProductCatSelectTab: React.FC<ProductCatSelectTabProps> = ({ setCurrentStep, currentStep, setSelectedCategory, selectedCategory }) => {

    // --------------------- Handle Submission of data --------------------
    const context = useStateContext()
    const [selectedSubCategory, setSelectedSubCategory] = useState("")

    const handleSelectCategory = (option: string) => {
        setSelectedCategory(option);
    };
    const handleSelectSubCategory = (option: string) => {
        setSelectedSubCategory(option);
    };

    const handleSubmission = () => {
        if (selectedSubCategory === "") {
            alert("Select Sub category First")
        } else {
            context?.setProductCategory(selectedCategory)
            context?.setProductSubCategory(selectedSubCategory)
            if (currentStep <= 5) {
                setCurrentStep(currentStep + 1)
            }
        }
    }

    // --------------------------------------------------------------------------------------------------------
    const [subCategories, setSubCategories] = useState<string[]>([]);
    useEffect(() => {
        const categories = selectedCategory === "App" ? appCategories :
            selectedCategory === "Game" ? gameCategories :
                selectedCategory === "Movie" ? movieTabGeners :
                    selectedCategory === "Service" ? serviceCategories :
                        selectedCategory === "Course" ? courseCategories :
                            selectedCategory === "AI Products" ? aiCategories :
                                ["No SubCategories Found"];

        setSubCategories(categories);

        // Set the first subcategory as the default selected value if available
        if (categories.length > 0 && categories[0] !== "No SubCategories Found") {
            setSelectedSubCategory(categories[0]);
        } else {
            setSelectedSubCategory("");
        }
    }, [selectedCategory]);


    return (
        <div className='flex flex-col gap-10'>
            <div className='flex flex-col gap-10'>
                <div className='flex flex-col gap-6'>
                    <p className='font-semibold text-[#00FFFF] md-lt:text-[20px] lg:text-[26px]'>Product Category &  Sub Category</p>
                    <div className='flex flex-col w-full gap-24'>
                        <div className='flex flex-col gap-3'>
                            <DropDown label="Product Category"
                                options={["App", "Game", "Movie", "Service", "Course", "AI Products"]}
                                onSelect={handleSelectCategory}
                                width="w-full"
                                // height="h-12"
                                padding="py-3 px-6"
                                customStyles="z-50"
                                // dropdownStyles="bg-gradient-to-r from-purple-700 to-indigo-700"
                                optionStyles="text-gray-300" />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <DropDown label="Product’s Sub  Category"
                                options={subCategories}
                                onSelect={handleSelectSubCategory}
                                width="w-full"
                                // height="h-12"
                                padding="py-3 px-6"
                                isSubcategory={true}
                                // customStyles="bg-gradient-to-r from-purple-600 to-indigo-600"
                                // dropdownStyles="bg-gradient-to-r from-purple-700 to-indigo-700"
                                optionStyles="text-gray-300" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-[1px] border-white border-opacity-20 w-full rounded-full' />
            <div className='flex items-center gap-10 justify-start'>
                <button onClick={handleSubmission} style={{
                    background:
                        'linear-gradient(180deg, #4B03CE 0%, #F572B6 80%)'
                }} className='py-3 lg:px-0 md-lt:px-3 md:w-[40%] md-lt:w-[50%] lg:w-[30%] text-white md-lt:font-medium lg:font-bold md-lt:text-[12px] lg:text-[16px] rounded-full'>
                    Save & Continue
                </button>
            </div>
        </div>
    )
}

export default ProductCatSelectTab