import Cookies from "js-cookie";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Applepay from '../../assets/images/apple-pay.svg';
import BitCoin from '../../assets/images/bit-coin.svg';
import DebitCard from '../../assets/images/debit-card.svg';
import GoogleWallet from '../../assets/images/google-wallet.svg';
import TickIcon from '../../assets/images/tick-icon.svg';
import { apiEndpoints } from "../../constants/api-endpoints";
import makeApiCall from "../../lib/apiCall";
import { getCookies, setCookies } from "../../utils/utils";
import './dev.scss';


const PricingScreen = () => {
    const navigate = useNavigate();
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setcvv] = useState('');

    const makePayment = () => {
        let user = getCookies('authUser')
        Cookies.set('subscription', 'yes', { expires: 7 });
        makeApiCall(apiEndpoints.createBrand).then(brandId => {
            let newContent = { ...apiEndpoints.updateBrandName }
            newContent.params.path.brandId = brandId;
            newContent.params.query.name = user?.username ?? '';
            makeApiCall(newContent)
            setCookies('authUser', { ...user, analyticsId: brandId })
            navigate('/dev/dashboard');
        }).catch(error => console.error('create brand error: ', error))

    }

    const paymentTypeCard = (icon: string, name: string) => {
        return (
            <div className='flex-1 flex flex-row' style={{ padding: 12, background: '#00000033', border: '1px solid #403076', borderRadius: 8 }}>
                <div className='mr-1'>
                    <img src={icon} alt='' />
                </div>
                <div>
                    <span className='text-white'>{name}</span>
                </div>
            </div>
        )
    }
    const planCard = (name: string, subname: string, price: string, planType: string, features: string[], index: number) => {
        return (
            <div className='planCard flex flex-col relative'>
                <div className='text-white uppercase' style={{ letterSpacing: 8, fontSize: '1rem', fontWeight: 500 }}>
                    <span>{name}</span>
                </div>
                <div className='text-white uppercase' style={{ letterSpacing: 8, fontSize: '1rem', fontWeight: 100 }}>
                    <span>{subname}</span>
                </div>
                <div className='text-[#FFFFFF80] uppercase pb-2 pt-2' style={{ fontSize: '0.7rem', fontWeight: 100 }}>
                    <span>{planType}</span>
                </div>
                <div className="absolute top-0 right-0 bg-gray-900 text-white px-3 py-3 rounded-tr-lg" style={{ background: (index === 1) ? 'linear-gradient(180deg, #460F88 0%, #9B56FE 100.32%)' : index === 2 ? 'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)' : index === 3 ? 'linear-gradient(181.2deg, #B00D98 0.45%, #FF5EE5 98.74%)' : '', borderRadius: 8 }}>
                    <div>
                        <span className='text-[#00F0FB]' style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                            $299
                        </span>
                    </div>
                    <div className='text-[#FFFFFF80] uppercase' style={{ fontSize: '0.7rem', fontWeight: 100 }}>
                        <span>/ Month</span>
                    </div>
                </div>
                <div>
                    {
                        features.map((feature, index) => (
                            <div key={index} className='flex flex-row justify-start items-center gap-3'>
                                <div>
                                    <img src={TickIcon} alt='tick' />
                                </div>
                                <div>
                                    <span className='text-white' style={{ fontSize: '0.9rem', fontWeight: 300 }}>{feature}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    };

    return (
        <div className="container price-background-banner">
            <div className="left"></div>
            <div className="right">
                <div className='text-white font-bold text-[20px]'>
                    <span>Pricing Details</span>
                </div>
                <div>
                    <div className="horizontal-divider-light mt-4 mb-4"></div>
                </div>
                <div className='flex flex-row gap-24'>
                    <div className="flex flex-1 flex-col gap-y-6">
                        {
                            planCard('Access', 'Lusso', '199', 'Basic Plan', [
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur'
                            ],
                                1
                            )
                        }
                        {
                            planCard('Elite', 'Lusso', '299', 'Basic Plan', [
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur'
                            ],
                                2
                            )
                        }
                        {
                            planCard('Infinity', 'Lusso', '399', 'Basic Plan', [
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur',
                                'Lorem ipsum dolor sit amet consectetur'
                            ],
                                3
                            )
                        }
                    </div>
                    <div className="flex flex-1 flex-col gap-y-6 justify-between">
                        <div className='flex flex-row justify-between items-center selectedPlan'>
                            <div >
                                <div className='text-white uppercase' style={{ letterSpacing: 8, fontSize: '1rem', fontWeight: 500 }}>
                                    <span>Access</span>
                                </div>
                                <div className='text-white uppercase' style={{ letterSpacing: 8, fontSize: '1rem', fontWeight: 100 }}>
                                    <span>Lusso</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className='text-[#00F0FB]' style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                                        $299
                                        <span className='text-[#FFFFFF]' style={{ fontSize: '1rem', fontWeight: 400 }}>/mon</span>
                                    </span>
                                </div>
                                <div>
                                    <span className='text-[#FFFFFF]' style={{ fontSize: '0.7rem', fontWeight: 100 }}>
                                        Free 7-day trial
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>

                            <div className='text-white font-normal text-[16px]'>
                                <span>Payment Method</span>
                            </div>
                            <div className='w-full flex flex-col gap-2'>
                                <div className='w-full flex-1 flex flex-row justify-between items-center gap-2'>
                                    {
                                        paymentTypeCard(DebitCard, 'Debit/ Credit')
                                    }
                                    {
                                        paymentTypeCard(Applepay, 'Apple Pay')
                                    }
                                </div>
                                <div className='w-full flex-1 flex flex-row justify-between items-center gap-2'>
                                    {
                                        paymentTypeCard(GoogleWallet, 'Google Wallet')
                                    }
                                    {
                                        paymentTypeCard(BitCoin, 'Bit Coin')
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col p-4' style={{ border: '1px solid #A768FD33', borderRadius: 8 }}>
                            <div className='text-white font-normal text-[16px]'>
                                <span>Enter Card Details</span>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter card number"
                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                    value={cardNumber || ""}
                                    style={{
                                        borderRadius: 50,
                                        border: '1px solid #A768FD',
                                        background: 'rgba(4, 4, 4, 0.20)',
                                        marginTop: 10
                                    }}
                                    onChange={(e) => {
                                        setCardNumber(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Card holder name"
                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                    value={cardHolderName || ""}
                                    style={{
                                        borderRadius: 50,
                                        border: '1px solid #A768FD',
                                        background: 'rgba(4, 4, 4, 0.20)',
                                        marginTop: 10
                                    }}
                                    onChange={(e) => {
                                        setCardHolderName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className='flex flex-row gap-3'>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Exp"
                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                    value={expiry || ""}
                                    style={{
                                        borderRadius: 50,
                                        border: '1px solid #A768FD',
                                        background: 'rgba(4, 4, 4, 0.20)',
                                        marginTop: 10
                                    }}
                                    onChange={(e) => {
                                        setExpiry(e.target.value);
                                    }}
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="CVV"
                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                    value={cvv || ""}
                                    style={{
                                        borderRadius: 50,
                                        border: '1px solid #A768FD',
                                        background: 'rgba(4, 4, 4, 0.20)',
                                        marginTop: 10
                                    }}
                                    onChange={(e) => {
                                        setcvv(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-3'>
                            <button
                                onClick={() => { navigate('/dev/no-subscription') }}
                                className="saveContinue mt-10"
                                style={{
                                    borderRadius: 50,
                                    border: '1px solid #FFF',
                                    textTransform: 'capitalize',
                                    paddingLeft: 18,
                                    paddingRight: 18
                                }}
                            >
                                Skip
                            </button>
                            <button
                                onClick={makePayment}
                                className="saveContinue mt-10"
                                style={{
                                    borderRadius: 50,
                                    border: '1px solid #A768FD',
                                    background: 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                    textTransform: 'capitalize',
                                    flex: 1
                                }}
                            >
                                Complete Payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingScreen;