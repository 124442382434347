import CircularProgressBar from '../../components/common/CircularProgressBar';
import WorldMap from '../../components/common/WorldMap';

interface LocationType {
    name: string;
    lat: number;
    lng: number;
    value: string;
    percentage?: string;
    flag?: any;
}
interface StatisticsData {
    totalUsers: number;
    activeUsers: number;
    newUsers: number;
    activeUsersPercentage: number;
    newUsersPercentage: number;
    locations?: LocationType[];
}
interface MapStatsProps {
    title?: string;
    data?: StatisticsData;
}

function MapStats({
    title = '',
    data = {
        totalUsers: 0,
        activeUsers: 0,
        newUsers: 0,
        activeUsersPercentage: 0,
        newUsersPercentage: 0,
    },
}: MapStatsProps) {
    const GeneralStats = () => {
        return (
            <div className="text-white">
                <h2 className="text-xl font-bold mb-5">{title ?? ''}</h2>
                <p className="text-md font-semibold mb-1">Total users</p>
                <p className="text-4xl font-bold mb-4">
                    {data?.totalUsers?.toLocaleString()}
                </p>

                {data?.locations && data?.locations?.length !== 0 && (
                    <table className="table mb-4 my-5">
                        <tbody>
                            {data?.locations?.map((location, index) => (
                                <tr key={location?.name + '_' + index}>
                                    <td>
                                        {location?.flag && (
                                            <img src={location?.flag ?? ''} width={30} alt="flag" />
                                        )}
                                    </td>
                                    <td className="text-start">
                                        <p>{location?.name ?? ''}</p>
                                    </td>
                                    <td>
                                        <p>{location?.value ?? ''}</p>
                                    </td>
                                    <td>
                                        <p>{location?.percentage ?? ''}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-5 pt-5">
                    <div className="flex gap-3 items-center">
                        <div className="w-[60px]">
                            <CircularProgressBar percentage={data?.activeUsersPercentage} />
                        </div>
                        <div className="text-left">
                            <div className="text-lg text-white font-semibold">
                                {data?.activeUsers?.toLocaleString()}
                            </div>
                            <div className="text-md text-white font-bold min-w-[100px]">
                                Active users
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3 items-center">
                        <div className="w-[60px]">
                            <CircularProgressBar percentage={data?.newUsersPercentage} />
                        </div>
                        <div className="text-left">
                            <div className="text-lg text-white font-semibold">
                                {data?.newUsers?.toLocaleString()}
                            </div>
                            <div className="text-md text-white font-bold min-w-[100px]">
                                New users
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // render
    return (
        <div className="card-bg-dev rounded-2xl flex flex-col lg:flex-row gap-5 p-6">
            <div className="lg:w-1/4">
                {/* General stats */}
                <GeneralStats />
            </div>

            <div className="lg:w-3/4 flex itemx-center">
                {/* Maps */}
                <WorldMap locations={data?.locations ?? []} />
            </div>
        </div>
    );
}

export default MapStats;
