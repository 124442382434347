import image1 from '../../assets/images/home/success-section/1.png'
import image2 from '../../assets/images/home/success-section/2.png'
import image3 from '../../assets/images/home/success-section/3.png'
import image4 from '../../assets/images/home/success-section/4.png'

interface SuccessCardType {
    index: number
    image: any
    hasVideo: boolean
}
const SuccessCard = ({ index, image, hasVideo }: SuccessCardType) => {
    return (
        <div
            className={`relative group overflow-hidden 
                        ${index === 0 && 'md:w-7/12 md:pe-5'} 
                        ${index === 1 && 'md:w-5/12'} 
                        ${index === 2 && 'md:w-5/12 md:pe-5'} 
                        ${index === 3 && 'md:w-7/12'} 
                        md:pt-10 pt-2 border-[5px] border-transparent rounded-[5px`}
        >
            <img src={image} alt={'image'}
                className={`md:rounded-none rounded-[10px] w-full md:h-full md:object-cover object-contain
                            ${index === 0 && 'md:rounded-tl-2xl'} 
                            ${index === 1 && 'md:rounded-tr-2xl'}
                            h-42`} />
            {hasVideo && (
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
                    <div className="bg-purple-600 rounded-full p-3 md:p-4 transition-transform duration-300 group-hover:scale-110">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 md:h-8 md:w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                </div>
            )}
            <div className="absolute bottom-0 h-[100px] w-full bg-black/60 opacity-0 transition-opacity duration-300 group-hover:opacity-100 flex items-center justify-center">
                <p className="max-w-[70%] text-white text-center px-4 text-sm md:text-base transform translate-y-4 transition-transform duration-300 group-hover:translate-y-0">
                    Short description about the success story. This will appear on hover.
                </p>
            </div>
        </div>
    );
};

const SuccessSection = () => {
    const successStories = [
        { image: image1, hasVideo: true },
        { image: image2, hasVideo: false },
        { image: image3, hasVideo: false },
        { image: image4, hasVideo: true },
    ];

    return (
        <div className="md:py-10 py-5 md:px-4 px-0 sm:px-6 lg:px-8">
            <h2 className="text-[18px] md:text-5xl font-bold text-center text-white">
                WHAT SUCCESS ON LUSSO <br /> LOOKS LIKE
            </h2>
            <div className="flex flex-wrap md:mt-[50px]">
                {successStories.map((story, index) => (
                    <SuccessCard key={index} index={index} image={story.image} hasVideo={story.hasVideo} />
                ))}
            </div>
        </div>
    );
};

export default SuccessSection;