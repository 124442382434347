import { ChevronRight, Star } from 'lucide-react';
import FloatingButton from '../Common/FloatingButton';

const ServiceHeader = ({ headerData, handleContactAction }: any) => (
    <div className="flex items-center mb-8 gap-5 flex-col md:flex-row">
        <div className='flex flex-col md:flex-row items-center md:hidden'>
            <div>
                <img src={headerData?.logo} alt={`${headerData?.title} logo`} className='w-[200px] h-[200px] rounded-2xl' />
            </div>
            <FloatingButton
                name="Message Miller"
                title="Customer Support"
                avgResponseTime="1 Hour"
                imageUrl={headerData?.contactImg}
                onClick={handleContactAction}
            />
        </div>

        <div className='hidden md:block'>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} className='w-[200px] h-[200px] rounded-2xl' />
        </div>

        <div className='flex flex-col items-center md:items-start justify-between gap-3'>
            <div className='flex flex-col items-center md:items-start'>
                <p className='hidden md:block text-md text-[#6DDCFF] font-semibold text-center md:text-start'>{headerData?.titleHeader}</p>
                <h1 className="text-xl md:text-4xl font-bold text-center md:text-start">{headerData?.title}</h1>
                <h2 className='text-md text-center md:text-start'>{headerData?.subTitle}</h2>
            </div>


            <div className="flex flex-col gap-3">
                <div className='flex flex-col items-center md:items-start'>
                    <p className='text-[#6DDCFF] font-semibold'>Created by</p>
                    <p className='text-white text-xl font-bold'>{headerData.author}</p>
                </div>
            </div>

            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        {/* <img src={starIcon} alt='star' /> */}
                        <Star fill='#FDB31F' color='#FDB31F' size={16} />
                    </div>
                    <span> | <span className='text-[#6DDCFF]'>{headerData?.ratingCount}</span> | <span className='text-[#6DDCFF]'>{headerData?.category}</span></span>
                </p>
            </div>

            <div>
                <button className='bg-[#5721B9] text-white font-medium text-md flex gap-1 py-2 px-4 rounded-3xl'>
                    Book appointment
                    <ChevronRight color='white' />
                </button>
            </div>
        </div>
    </div>
);

export default ServiceHeader