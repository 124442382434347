import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IconEye from '../../assets/icons/eye';
import Logo from '../../assets/images/LussoAiLogo.svg';
import MobileLogo from '../../assets/images/LussoLogovert.svg';
import BecomeCreatorButton from '../../components/common/BecomeCreatorBtn';
import { getCookies, setCookies } from '../../utils/utils';
import './auth.scss';

interface JwtPayload {
  role: string;
  // Add other properties if needed
}

const AccountLogin = () => {
  const navigate = useNavigate();
  const [userName, setUserName]: any = useState();
  const [password, setPassword]: any = useState();
  const [invalidField, setInvalidField]: any = useState('');
  const [invalidError, setInvalidError]: any = useState('');
  const [isLoading, setIsLoading]: any = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogin = () => {
    if (!validateFields()) return;
    // if(userName === 'admin.lussolabs@gmail.com' && password === 'AZaz09$$') {
    //   navigate("/dev/dashboard");
    // }
    setIsLoading(true);
    let apiRequest = {
      email: userName,
      password: password,
    };
    axios
      .post('https://api.lusso.dev/api/v1/login', apiRequest)
      .then(response => {
        console.log('response', response);
        let authToken = response.data?.token;
        setCookies('authToken', authToken);
        if (response.data.isNewUser) {
          navigate('/verify');
        } else {
          let token = getCookies('authToken');
          console.log('$$$$$$$$$$$$$$$$$tokenin', token);
          if (token) {
            const payload = jwtDecode<JwtPayload>(token);
            setCookies('authUser', payload);

            if (payload.role === 'user') {
              navigate('/explore');
            } else if (payload.role === 'developer') {
              let isSubscribed = Cookies.get('subscription');
              if (isSubscribed === 'yes') {
                navigate('/dev/dashboard');
              } else {
                navigate('/subscribe');
              }
            }
          }
        }
      })
      .catch(error => {
        console.log('error', error);
        setIsLoading(false);
        setInvalidField('credserror');
      });
    // if (userName === "" || userName === null || userName === undefined) {
    //   alert("Please enter a User Name");
    // } else if (password === "" || password === null || password === undefined) {
    //   alert("Please enter a Password");
    // } else if (userName === "lusso" && password === "lusso123") {
    //   localStorage.setItem("isAuthenticated", JSON.stringify(true));
    //   navigate("/home");
    // } else {
    //   alert("Invalid Username or Password. Please try again.");
    // }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission
    handleLogin();
  };

  const isWebView = window.innerWidth >= 768;
  const validateFields = () => {
    if (
      !/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(
        userName,
      )
    ) {
      setInvalidField('credserror');
      return false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password,
      )
    ) {
      setInvalidField('credserror');
      return false;
    }
    return true;
  };
  useEffect(() => {
    localStorage.removeItem('isAuthenticated');
  }, []);
  return (
    <div className="w-full">
      <div className="w-full m-auto clearfix font-Jakarta-sans h-100 vh-100 flex items-center justify-center login-container login-banner">
        <div
          className="noMobile flex flex-1 items-end justify-end"
          style={{ height: '100vh' }}
        >
          {/* <img src={LoginBannerSlide} alt="Banner" /> */}
          <div
            className="flex flex-1 justify-center uppercase font-bold"
            style={{
              letterSpacing: 12,
              fontSize: '2.5rem',
              paddingBottom: '30px',
              paddingRight: '50px',
              position: 'relative',
            }}
          >
            <span className="text-white" style={{ fontSize: '2rem' }}> {/* Increased font size */}
              <span style={{ fontSize: '2rem' }}>EXPLORING</span> <br />
              <span style={{ fontSize: '2rem' }}>THE WORLD OF</span> <br />
              <span style={{ fontSize: '3rem' }} className="text-[#0FF] font-bold">
                LUSSO
              </span> <br />
              <span style={{ fontSize: '3rem' }}>UNIVERSE</span>
            </span>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-between login-container-right">
          <div
            className="auth-top-container w-full flex flex-row justify-between items-center"
            style={{
              marginBottom: isWebView ? '80px' : '',
            }}
          >
            <a href="/" style={{ textDecoration: 'none' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: isWebView ? 'center' : '',
                  marginBottom: isWebView ? '0px' : '16px',
                }}
              >
                <img
                  src={isMobile ? MobileLogo : Logo}
                  alt="Logo"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </a>

            <div className="auth-become-creator">
              {/* <button
                onClick={() => {
                  navigate('/auth/devonboard');
                }}
                className="creator-btn"
                style={{
                  borderRadius: 50,
                  border: '1px solid #792FFF',
                  textTransform: 'none',
                }}
              >
                Become A Creator &nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8 5l7 7-7 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

              </button> */}
              <BecomeCreatorButton />
            </div>
          </div>


          <div>
            {/* <img className="mb-12" style={{height:70}} src={Logo} alt="Logo" /> */}
            <div className="auth-create-acc-title w-full flex flex-col justify-center items-start mt-5">
              <h2 className="text-[#00FFFF] text-[20px] font-bold  text-left">
                Hello
              </h2>
              <h2 className="text-white text-[20px]  text-left" style={{ letterSpacing: '0.3em' }}>
                Welcome<span style={{ marginLeft: '0.5rem' }}>back!</span>
              </h2>

              <p className="text-white/70 text-sm font-light text-left mb-8">
                Please enter your details to Log In
              </p>

            </div>
            <div className="w-full flex flex-col items-center gap-6">
              <button
                type="submit"
                className="ac-google-login-btn gap-2"
                style={{
                  borderRadius: 50,
                  border: '1px solid #A768FD',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13.5" cy="13.5" r="13" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.7172 5.14446C16.7172 5.93682 16.3672 6.72917 15.8733 7.30089C15.3483 7.93281 14.4427 8.40421 13.7223 8.4043C13.64 8.4043 13.5576 8.3942 13.5062 8.3842C13.4959 8.34409 13.4753 8.22367 13.4753 8.10335C13.4753 7.30089 13.8973 6.50853 14.3501 6.00703C14.9264 5.3551 15.8835 4.8636 16.6863 4.8335C16.7069 4.92381 16.7172 5.03413 16.7172 5.14446ZM19.5484 10.1357C19.5683 10.1229 19.5821 10.1141 19.5885 10.1092C18.5182 8.61485 16.892 8.57464 16.4391 8.57455C15.7472 8.57455 15.1278 8.81382 14.6071 9.01498C14.2295 9.16086 13.9038 9.28669 13.6399 9.28669C13.3488 9.28669 13.0154 9.1554 12.6434 9.00888C12.1738 8.8239 11.6425 8.61465 11.0567 8.61465C9.08085 8.61465 7.07397 10.2095 7.07397 13.2185C7.07397 15.094 7.81495 17.0699 8.7309 18.3438C9.52328 19.427 10.2128 20.3097 11.2008 20.3097C11.6694 20.3097 12.0129 20.1676 12.3742 20.0181C12.7747 19.8524 13.197 19.6778 13.8355 19.6778C14.4802 19.6778 14.8656 19.8418 15.2374 19.9999C15.5837 20.1473 15.9181 20.2896 16.4392 20.2896C17.5198 20.2896 18.23 19.3368 18.9092 18.3839C19.6708 17.3007 19.9898 16.2375 20.0001 16.1874C19.9383 16.1674 17.8698 15.3549 17.8698 13.058C17.8698 11.2093 19.2789 10.308 19.5484 10.1357Z"
                    fill="black"
                  />
                </svg>
                Sign in with Apple
              </button>

              <button
                type="submit"
                className="ac-google-login-btn gap-2"
                style={{
                  borderRadius: 50,
                  border: '1px solid #A768FD',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_75_662)">
                    <path
                      d="M23.766 12.2765C23.766 11.4608 23.6999 10.6406 23.5588 9.83813H12.24V14.4591H18.7217C18.4528 15.9495 17.5885 17.2679 16.323 18.1056V21.104H20.19C22.4608 19.014 23.766 15.9274 23.766 12.2765Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.50253 14.3002C4.99987 12.8099 4.99987 11.196 5.50253 9.70569V6.61475H1.51649C-0.18551 10.0055 -0.18551 14.0004 1.51649 17.3912L5.50253 14.3002Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_75_662">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Sign in with Google
              </button>

            </div>
            <div className="flex flex-1 items-center justify-between">
              <div className="horizontal-divider mb-8 mt-8"></div>
              <div>
                <span
                  style={{ color: '#E1E1E1' }}
                  className="text-[#E1E1E1] text-[14px] mb-8 mt-8 text-center"
                >
                  OR
                </span>
              </div>
              <div className="horizontal-divider mb-8 mt-8"></div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-y-6">
                <div>
                  <label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter your email"
                      className="ac-frm-input rounded-pill badge h-[50px]"
                      value={userName || ''}
                      style={{
                        borderRadius: 50,
                        border: `1px solid ${invalidField === 'credserror' ? '#F04438' : 'rgb(137 108 255 / 80%)'}`,
                        background: 'rgba(46, 36, 108, 0.1)',
                        color: '#fff',
                      }}
                      onChange={e => {
                        setInvalidField('');
                        setUserName(e.target.value);
                      }}
                    />
                  </label>
                  <div>
                    {invalidField === 'credserror' && (
                      <span className="errorField">Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                <div className="relative">
                  <label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="off"
                      placeholder="Enter your password"
                      className="ac-frm-input h-[50px] pl-12 pr-12"
                      value={password || ''}
                      style={{
                        borderRadius: 50,
                        border: `1px solid ${invalidField === 'credserror' ? '#F04438' : 'rgb(137 108 255 / 80%)'}`, // Border color with 80% opacity
                        background: 'rgba(46, 36, 108, 0.1)',
                        color: '#fff',
                      }}
                      onChange={e => {
                        setInvalidField('');
                        setPassword(e.target.value);
                      }}
                    />
                  </label>
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    style={{ zIndex: 10 }}
                  >
                    <IconEye color={showPassword ? "#A768FD" : "#888"} />
                  </span>
                  <div className="absolute bottom-[-20px] w-full"> {/* Position error message below the input */}
                    {invalidField === 'credserror' && (
                      <span className="text-red-500 text-xs">Please enter a valid password</span>
                    )}
                  </div>
                </div>

              </div>




              <div className="w-full text-right mt-1.5">
                <Link
                  to="/forgot"
                  style={{ color: '#5E91FF', fontWeight: 'bold' }}
                  className="text-sm"
                >
                  Forgot Password
                </Link>
              </div>


              <button
                type="submit"
                className="ac-login-btn mt-6"
                style={{
                  borderRadius: 50,
                  border: '1px solid #A768FD',
                  background: !isLoading
                    ? 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)'
                    : '',
                  textTransform: 'capitalize',
                  paddingLeft: 50,
                  paddingRight: 50,
                  position: 'relative',
                }}
                disabled={isLoading}
              >
                {isLoading && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                  >
                    {/* Add your loader component here */}
                    <div className="loader"></div>
                  </div>
                )}
                {!isLoading ? 'Login' : ''}
              </button>
            </form>
            <div className="text-center text-base font-normal mt-16">
              <span className="text-white/50">
                Not yet part of the Lusso Community??
              </span>{' '}
              <Link
                to="/signup"
                className="text-[#5E91FF] font-bold hover:underline"
              >
                Sign up
              </Link>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountLogin;
