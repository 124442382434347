import { useState } from 'react';
import Badge from '../../components/ui/Badge';
import Button from '../../components/ui/Button';
import Dropdown from '../../components/ui/Dropdown';
import Radio from '../../components/ui/Radio';
import { ICON_ENUM } from '../../constants/icons.constant';
import { SortByOptions } from '../../utils/utils';

function FilterExplore() {
    const [filterModal, setFilterModal] = useState(false);
    return (
        <>
            {/* Advanced Filter Section */}
            <div className="flex items-center justify-end">
                <button
                    className="flex items-center gap-3 border-[1px] rounded py-2 px-5 bg-[#FFFFFF0F]"
                    onClick={() => setFilterModal(!filterModal)}
                >
                    <img src={ICON_ENUM.FILTER.icon} className="h-3.5" alt="filters" />
                    <span className="hidden md:flex text-white items-center">
                        Filters
                    </span>
                </button>
                <dialog className="modal" open={filterModal}>
                    <div className="modal-box bg-blurred-new rounded-none text-white py-16 h-full">
                        <form method="dialog">
                            <div className="flex items-center gap-3">
                                <img
                                    src={ICON_ENUM.FILTER.icon}
                                    className="h-5"
                                    alt="filters"
                                />
                                <h1 className="text-2xl">FILTER BY</h1>
                            </div>
                            <button
                                className="btn btn-sm btn-circle btn-ghost absolute right-5 top-16"
                                onClick={() => setFilterModal(false)}
                            >
                                <img src={ICON_ENUM.CROSS.icon} className="h-5" alt="filters" />
                            </button>
                        </form>
                        <div className="space-y-5 my-5">
                            <div className="flex flex-col gap-5 pb-5 border-b-[1px]">
                                <h3 className="text-lg">Active filters</h3>
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Badge rounded="full" bgColorEnumKey="FILTER_BADGE">
                                        <div className="flex items-center gap-2">
                                            <span>Courses</span>
                                            <img
                                                src={ICON_ENUM.FILL_CIRCLE_X.icon}
                                                className="h-3"
                                                alt="cancel"
                                            />
                                        </div>
                                    </Badge>
                                    <Badge rounded="full" bgColorEnumKey="FILTER_BADGE">
                                        <div className="flex items-center gap-2">
                                            <span>Movie & TV</span>
                                            <img
                                                src={ICON_ENUM.FILL_CIRCLE_X.icon}
                                                className="h-3"
                                                alt="cancel"
                                            />
                                        </div>
                                    </Badge>
                                </div>
                            </div>
                            <Dropdown
                                label="Sort By"
                                borderColor="#ffff"
                                borderRadius={10}
                                options={SortByOptions}
                            />
                            <Dropdown
                                label="Category"
                                borderColor="#ffff"
                                borderRadius={10}
                                options={SortByOptions}
                            />
                            <Radio
                                label="Content Type"
                                options={[
                                    { label: 'Free', value: 'free' },
                                    { label: 'Paid', value: 'paid' },
                                ]}
                            />
                        </div>
                        <div className="flex justify-between gap-5 mt-10">
                            <Button
                                label="Apply"
                                className="bg-gradient-vertical font-bold w-full"
                            // onClick={handleNavigation}
                            />
                            <Button
                                type='custom'
                                className="bg-gradient-vertical p-[1px] w-full"
                            // onClick={handleNavigation}
                            >
                                <div className="py-2 font-bold rounded-full text-white planCard-price-bg">Clear All</div>
                            </Button>
                        </div>
                    </div>
                </dialog>
                {/* <Dialog
                    open={filterModal}
                    onClose={() => setFilterModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure to delete?
                    </DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this item?
                            <br></br>This action cannot be undone.
                        </DialogContentText> */}
                {/* </DialogContent>
            <DialogActions>
                <div className="flex justify-between">
                    <Button
                        label="Apply"
                        onClick={() => console.log('...Filters applied')}
                    />
                    <Button
                        label="Clear All"
                        onClick={() => console.log('...Filters Clear All')}
                    /> */}
                {/* <Button
                                style={{
                                    border: '1px #E73877 solid',
                                    color: '#E73877',
                                }}
                                // onClick={onClose}
                                color="primary"
                            >
                                Clear All
                            </Button> */}
                {/* </div>
            </DialogActions>
        </Dialog > */}
            </div>
        </>
    );
}

export default FilterExplore;

// {/* Filter by Popularity/Date/Rating */}
// <div className="flex flex-col mr-6 mb-4">
//     <label className="text-white text-lg font-semibold mb-2">Sort By</label>
//     <select className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none">
//         <option value="popularity">Popularity</option>
//         <option value="date">Release Date</option>
//         <option value="rating">Rating</option>
//     </select>
// </div>

// {/* Filter by Price Range */}
// <div className="flex flex-col mr-6 mb-4">
//     <label className="text-white text-lg font-semibold mb-2">Price Range</label>
//     <div className="flex items-center space-x-2">
//         <input
//             type="number"
//             placeholder="Min"
//             className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none w-24"
//         />
//         <span className="text-white">-</span>
//         <input
//             type="number"
//             placeholder="Max"
//             className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none w-24"
//         />
//     </div>
// </div>

// {/* Filter by Category */}
// <div className="flex flex-col mr-6 mb-4">
//     <label className="text-white text-lg font-semibold mb-2">Category</label>
//     <select className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none">
//         <option value="all">All Categories</option>
//         <option value="development">Development</option>
//         <option value="design">Design</option>
//         <option value="marketing">Marketing</option>
//         {/* Add more categories as needed */}
//     </select>
// </div>

// {/* Toggle for Free/Paid Content */}
// <div className="flex flex-col mr-6 mb-4">
//     <label className="text-white text-lg font-semibold mb-2">Content Type</label>
//     <div className="flex items-center space-x-4">
//         <label className="flex items-center text-white">
//             <input type="checkbox" className="form-checkbox h-5 w-5 text-yellow-400" />
//             <span className="ml-2">Free</span>
//         </label>
//         <label className="flex items-center text-white">
//             <input type="checkbox" className="form-checkbox h-5 w-5 text-yellow-400" />
//             <span className="ml-2">Paid</span>
//         </label>
//     </div>
// </div>

// {/* Apply and Clear Buttons */}
// <div className="flex items-center space-x-4 ml-auto">
//     <button className="bg-green-400 text-black py-2 px-6 rounded-md font-bold hover:bg-yellow-500 transition duration-300">
//         Apply Filters
//     </button>
//     <button className="bg-white text-purple-700 py-2 px-6 rounded-md font-bold hover:bg-gray-200 transition duration-300">
//         Clear Filters
//     </button>
// </div>
