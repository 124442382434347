import { useEffect, useRef, useState } from 'react';
import SocialCard from '../../components/cards/SocialCard';

const SocialMediaCards = () => {
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const scrollRef = useRef<any>(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    const checkScrollPosition = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        checkScrollPosition();
        window.addEventListener('resize', checkScrollPosition);
        return () => window.removeEventListener('resize', checkScrollPosition);
    }, []);

    return (
        <div className="relative">
            {showLeftArrow && (
                <button
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 card-bg-dev p-2 rounded-full shadow-lg"
                    onClick={scrollLeft}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6" /></svg>
                </button>
            )}

            <div
                className="flex space-x-3 overflow-x-auto scrollbar-hide"
                ref={scrollRef}
                onScroll={checkScrollPosition}
            >
                {/* Likes Card */}
                <SocialCard
                    heading="Likes"
                    value="201"
                    increase="8012/day"
                />

                {/* Shares Card */}
                <SocialCard
                    heading="Shares"
                    value="102"
                    increase="512/day"
                />

                {/* Visitors Card */}
                <SocialCard
                    heading="Visitors"
                    value="15,250"
                    increase="2,150/day"
                />

                {/* Redirects Card */}
                <SocialCard
                    heading="Redirects"
                    value="1,620"
                    increase="350/day"
                />

                {/* Time Spent Card */}
                <SocialCard
                    heading="Time Spent"
                    value="3h 20m"
                    increase="30m/day"
                />
            </div>

            {showRightArrow && (
                <button
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 card-bg-dev p-2 rounded-full shadow-lg"
                    onClick={scrollRight}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                </button>
            )}
        </div>
    );
};

export default SocialMediaCards;
