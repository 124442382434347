import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserAvator from '../common/UserAvator';
import NotificationPart from './NotificationPart';

const NotificationProfile = ({ data }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();
    const currentPath = location.pathname;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        document.cookie = "authToken=; path=/;";
        if (currentPath === '/') {
            window.location.reload()
        } else {
            navigate('/')
        }
    }
    // render
    const isKidsPage = currentPath.includes('/kids');
    return (
        <div className='hidden md:block flex items-center'>
            <div className="flex items-center space-x-4">
                {/* Notification Icon */}
                {!isKidsPage && <NotificationPart />}

                {/* Profile Section */}
                {/* <div className="flex items-center bg-[#2c2051] rounded-full pl-2 pr-4 py-2">
                    <div className="">
                        <img
                            src={defaultImg}
                            alt="User"
                            className="h-7 rounded-full"
                        />
                    </div>
                    <span className="ml-3 text-white font-medium capitalize">{data?.username}</span>
                </div> */}

                <div className="hidden md:flex relative">
                    <div
                        className="flex items-center bg-[#2c2051] rounded-full pl-2 pr-4 py-2 cursor-pointer"
                        onClick={toggleDropdown}
                    >
                        <UserAvator className='w-[25px] md:w-auto rounded-full h-7' />
                        <span className="ml-3 text-white font-medium capitalize">
                            {data?.username}
                        </span>
                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <path d="M5 7L9.5 11.5L14 7" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    {isOpen && (
                        <div className="absolute right-0 mt-1 w-40 shadow-lg z-10 bg-[#2C2051] rounded-xl">
                            <ul className="p-1.5">
                                <li
                                    className="flex px-4 py-1.5 text-white capitalize items-center gap-3 hover:bg-[#4567AC] 
                                    rounded-xl cursor-pointer"
                                // onClick={}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                                        <path d="M5 23.2734V16.2734M5 12.2734V5.27344M13 23.2734V14.2734M13 10.2734V5.27344M21 23.2734V18.2734M21 14.2734V5.27344M2 16.2734H8M10 10.2734H16M18 18.2734H24" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Settings
                                </li>
                                <li
                                    className="flex px-4 py-1.5 text-white capitalize items-center gap-3 hover:bg-[#4567AC] 
                                    rounded-xl cursor-pointer"
                                    onClick={handleLogout}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 27 28" fill="none">
                                        <path d="M16.7003 5.04688H20.0585C21.295 5.04688 22.2974 6.04923 22.2974 7.28571V20.7187C22.2974 21.9552 21.295 22.9575 20.0585 22.9575H16.7003M8.86438 9.52454L4.38672 14.0022M4.38672 14.0022L8.86438 18.4799M4.38672 14.0022H17.8197" stroke="#E4E4E4" stroke-width="1.11942" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Logout
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationProfile;
