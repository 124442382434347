import CompletedBadge from "../../assets/images/completed-badge.png";
const Success = () => {
    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-6 items-center justify-center pt-8">
                <img className="w-44" src={CompletedBadge} alt="" />
                <p className="font-semibold md-lt:text-[20px] lg:text-[26px] text-[#00ffff]">Product Under Review</p>
                <p className="text-white md-lt:text-center">Your product is under review state </p>
            </div>
            <div className='border-[1px] border-white border-opacity-20 w-full rounded-full' />
            <div className='flex items-center gap-10 justify-center'>
                <button onClick={() => {
                    // if (currentStep <= 5) {
                    //     setCurrentStep(currentStep + 1)
                    // }
                }} style={{
                    background: 'linear-gradient(180deg, #4B03CE 0%, #F572B6 80%)'
                }} className='py-3 md-lt:w-[50%] md:w-[30%] lg:w-[20%] text-white font-bold md-lt:text-[10px] lg:text-[16px] rounded-full'>
                    Continue
                </button>
                <button onClick={() => {
                    // setCurrentStep(currentStep - 1)
                }}
                    className="relative py-3 md-lt:w-[50%] md:w-[30%] lg:w-[20%] text-white font-bold md-lt:text-[10px] lg:text-[16px] rounded-full border-2 bg-transparent gradient-border border-t-[#4B03CE] border-b-[#F572B6] border-l-[#4B03CE] border-r-[#F572B6]"
                >
                    Preview
                </button>
                <button onClick={() => {
                    // setCurrentStep(currentStep - 1)
                }}
                    className="relative py-3 md-lt:w-[50%] md:w-[30%] lg:w-[20%] text-white font-bold md-lt:text-[10px] lg:text-[16px] rounded-full border-2 bg-transparent gradient-border border-t-[#4B03CE] border-b-[#F572B6] border-l-[#4B03CE] border-r-[#F572B6]"
                >
                    Edit
                </button>

            </div>
        </div>
    )
}

export default Success