import ServiceImg from "../../assets/images/aboutServiceImage.svg"

const ServiceSection = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-0">
            <div className="flex justify-center items-center">
                <img src={ServiceImg} />
            </div>
            <div
                className="flex flex-col justify-center gap-7 md:gap-4">
                <span className="font-bold text-3xl md:text-6xl" style={{ color: "#FFFFFF" }}>What We do</span>
                <p className="text-base md:text-xl font-normal text-balance" style={{ color: "#FFFFFF" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptateSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,</p>
            </div>
        </div >
    )
}

export default ServiceSection